
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      error: "",
      email: "",
      password: "",
    };
  },

  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch("adminLogin", {
          email: this.email.toLowerCase().trim(),
          password: this.password,
        });
        this.$router.push("/admin/dashboard");
      } catch (error) {
        this.error = error.message;
      }
    },
  },
});
