
import Vue from "vue";

import BaseTable from "@/components/Tables/base/Table.vue";

export default Vue.extend({
  components: { BaseTable },

  props: {
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
    showButtons: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      fields: [
        { key: "dateCreated", label: "Created Date" },
        { key: "communicationHistory[0].method", label: "Method" },
        { key: "communicationHistory[0].email", label: "Email" },
        { key: "communicationHistory[0].cellPhone", label: "Cell Phone" },
        { key: "communicationHistory[0].summary", label: "Summary" },
        { key: "id", label: "Action" },
      ],
    };
  },
});
