export default [
    {
        value: null,
        text: "Select Document Type",
    },
    {
        value: "driversLicense",
        text: "Driver's License or ID",
    },
    {
        value: "passport",
        text: "Passport",
    },
    {
        value: "paystub",
        text: "Pay Stub",
    },
    {
        value: "proofOfResidence",
        text: "Proof of Residence",
    },
    {
        value: "other",
        text: "Others",
    }
]