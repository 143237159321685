
import Vue from "vue";

import BaseTableSection from "./base/TableSection.vue";
import AdminTemplate from "./templates/manage-users/AdminTemplate.vue";
import { adminDashboardRequests } from "../../api/admin-dashboard";
import { TableRequestEvent } from "../../types/tables";
import VendorTemplate from "@/components/Tables/templates/manage-vendors/VendorTemplate.vue";

const DEFAULT_REQUEST_PARAMS = { page: 1, perPage: 25 };
export default Vue.extend({
  components: {
    BaseTableSection,
    VendorTemplate,
  },

  data() {
    return {
      defaultParams: DEFAULT_REQUEST_PARAMS,
      currentStatus: "",
      rows: [],
      totalRows: 0,
    };
  },
  methods: {
    async fetchTable(event: TableRequestEvent) {
      this.currentStatus = event.status as string;
      const { data } = await adminDashboardRequests.getVendors({ ...event });
      const { rows, totalRows } = data;
      this.rows = rows;
      this.totalRows = totalRows;
    },
  },
});
