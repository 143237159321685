var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"side-nav-container"},[_vm._m(0),_c('ul',{staticClass:"side-nav-links"},[_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/dashboard"}},[_c('i',{staticClass:"material-icons"},[_vm._v("dashboard")]),_c('span',[_vm._v("Dashboard")])])],1),_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/opportunities"}},[_c('i',{staticClass:"material-icons"},[_vm._v("article")]),_c('span',[_vm._v("Application status")])])],1),_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/manual-review"}},[_c('i',{staticClass:"material-icons"},[_vm._v("article")]),_c('span',[_vm._v("Manual Review")])])],1),(_vm.userData.role === _vm.adminRoles.SuperAdmin)?_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/approvals"}},[_c('i',{staticClass:"material-icons"},[_vm._v("article")]),_c('span',[_vm._v("Admin Approvals")])])],1):_vm._e(),_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/needs-review"}},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_c('span',[_vm._v("Delinquent Loans")])])],1),(
        _vm.userData.role === _vm.adminRoles.ManagerLA ||
        _vm.userData.role === _vm.adminRoles.SuperAdmin
      )?_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/action-items"}},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_c('span',[_vm._v("Action Items")])])],1):_vm._e(),_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/lending-center"}},[_c('i',{staticClass:"material-icons"},[_vm._v("folder")]),_c('span',[_vm._v("Lending Center")])])],1),(
        _vm.userData.role === _vm.adminRoles.ManagerLA ||
        _vm.userData.role === _vm.adminRoles.SuperAdmin
      )?_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/manage-admin-users"}},[_c('i',{staticClass:"material-icons"},[_vm._v("group")]),_c('span',[_vm._v("Manage Admin")])])],1):_vm._e(),_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/manage-users"}},[_c('i',{staticClass:"material-icons"},[_vm._v("group")]),_c('span',[_vm._v("Manage Borrowers")])])],1),(_vm.userData.role === _vm.adminRoles.SuperAdmin)?_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/manage-lead-users"}},[_c('i',{staticClass:"material-icons"},[_vm._v("group")]),_c('span',[_vm._v("Manage Leads")])])],1):_vm._e(),(_vm.userData.role === _vm.adminRoles.SuperAdmin)?_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/manage-vendors"}},[_c('i',{staticClass:"material-icons"},[_vm._v("group")]),_c('span',[_vm._v("Manage Vendors")])])],1):_vm._e(),(
        _vm.userData.role === _vm.adminRoles.ManagerLA ||
        _vm.userData.role === _vm.adminRoles.SuperAdmin
      )?_c('li',[(_vm.userData.role === _vm.adminRoles.SuperAdmin)?_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/collections"}},[_c('i',{staticClass:"material-icons"},[_vm._v("history")]),_c('span',[_vm._v("Collections")])]):_vm._e()],1):_vm._e(),(
        _vm.userData.role === _vm.adminRoles.ManagerLA ||
        _vm.userData.role === _vm.adminRoles.SuperAdmin
      )?_c('li',[(_vm.userData.role === _vm.adminRoles.SuperAdmin)?_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/audit-log"}},[_c('i',{staticClass:"material-icons"},[_vm._v("history")]),_c('span',[_vm._v("Audit Log")])]):_vm._e()],1):_vm._e(),(
        _vm.userData.role === _vm.adminRoles.ManagerLA ||
        _vm.userData.role === _vm.adminRoles.SuperAdmin
      )?_c('li',[_c('router-link',{staticClass:"side-nav-link",attrs:{"active-class":"selected","to":"/admin/manage-loan-settings"}},[_c('i',{staticClass:"material-icons"},[_vm._v("settings")]),_c('span',[_vm._v("Loan Settings")])])],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"side-nav-header"},[_c('img',{staticClass:"side-nav-logo",attrs:{"src":require("../../../../public/assets/images/img/logo.svg"),"alt":"Company Logo"}})])
}]

export { render, staticRenderFns }