
import Vue from "vue";

import CommentsTable from "@/admin-dashboard/loan-details/comments/components/CommentsDetailsTable.vue";
import showErrorToast from "@/helpers/errorToast";
import { adminDashboardRequests } from "@/api/admin-dashboard";

export default Vue.extend({
  components: {
    CommentsTable,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      comment: null as null | string,
      isSubmitting: false,
      subject: null as null | string,
      id: null as null | string,
    };
  },

  methods: {
    async editEvent(data:any){
      this.comment = data.comment;
      this.subject = data.subject;
      this.id = data.id;
    },
    async deleteEvent(data:any){
      const result = await this.$swal({
        title: `Are you sure you want to delete comment?`,
        showCancelButton: true,
        icon: "question",
        reverseButtons: false,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
      });
      if (result.isConfirmed) {
        console.log(data.id);
          try {
            this.isSubmitting = true;
            await adminDashboardRequests.deleteComment(data.id);
            this.isSubmitting = false;
            await (this.$refs.commentsTable as any).fetchTable({
              status: "",
              page: 1,
              perPage: 25,
            });
          } catch (error) {
            this.isSubmitting = false;
            let errorMessage = "";

            if (error.response?.data?.error) {
              errorMessage = error.response.data.error;
            } else if (error.response?.data?.message) {
              errorMessage = error.response.data.message;
            } else {
              errorMessage = error.message;
            }

            showErrorToast(this, "error", errorMessage);
        }
        
      }
    },
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    cleanState() {
      this.subject = null;
      this.comment = null;
      this.id = null;
      this.$nextTick(() => {
        (this.$refs.observer as any).reset();
      });
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;
        const requestBody = {
          subject: this.subject || "",
          comment: this.comment || "",
          id: this.id || ""
        };

        if(this.id) {
          await adminDashboardRequests.editComment(
            this.screenTrackingId,
            requestBody
          );
        } else {
          await adminDashboardRequests.addComment(
            this.screenTrackingId,
            requestBody
          );
        }
        

        // reload comments table
        await (this.$refs.commentsTable as any).fetchTable({
          status: "",
          page: 1,
          perPage: 25,
        });

        this.cleanState();
        this.isSubmitting = false;
      } catch (error) {
        this.isSubmitting = false;
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
});
