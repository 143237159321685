
import Vue from "vue";

import BaseTable from "@/components/Tables/base/Table.vue";

export default Vue.extend({
  components: { BaseTable },

  props: {
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
    showButtons: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      fields: [
        { key: "subject", label: "Subject" },
        { key: "comment", label: "Comments" },
        { key: "createdBy", label: "Created By" },
        { key: "dateCreated", label: "Created Date" },
        { key: "id", label: "Action" },
      ],
    };
  },
});
