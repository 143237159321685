
import { adminDashboardRequests } from "@/api/admin-dashboard";
import Vue from "vue";
import MakePaymentButton from "@/components/buttons/MakePaymentButton.vue";
// import AmendPaymentButton from "@/components/buttons/AmendPaymentButton.vue";
import ChangePaymentAmount from "@/components/buttons/ChangePaymentAmount.vue";
import { errorHandler } from "@/api/error-handler";
import { getUserData } from "@/admin-dashboard/authentication/helpers";
import { getAdminRoles } from "@/admin-dashboard/helpers";
import {
  updateCustomerDetails,
  closeLoanDetails,
  partialReturnDetails,
  moveLoanCollections,
} from "@/user-application/authentication/api";
import moment from "moment";
import { number } from "card-validator";
import ChangeLoanStartDate from "@/components/buttons/ChangeLoanStartDate.vue";
import SpecialCreditButton from "@/components/buttons/SpecialCreditButton.vue";
export default Vue.extend({
  components: {
    SpecialCreditButton,
    ChangeLoanStartDate,
    MakePaymentButton,
    ChangePaymentAmount,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      adminRoles: getAdminRoles(),
      isInRepayment: false,
      isPaid: false,
      isClosed: false,
      paymentManagement: [] as any,
      ledger: [] as any,
      userData: getUserData(),
      applicationData: [] as any,
      email: null as null | string,
      autopayStatus: false,
      loanPeriod: 0,
      collectionCheck: null as null | string,
      promoStatus: false,
      promoCheck: false,
      promopayStatus: false,
      promopayCheck: false,
      amount: "" as number | string,
      amountPromo: "" as number | string,
      defaultCardSelectModal: false,
      userCardsList: [] as any,
      selectedDefaultCard: null as null | string,
    };
  },

  async created() {
    try {
      const { data } = await adminDashboardRequests.getApplication(
        this.screenTrackingId,
      );
      this.email = data.email;
      const { data: LoanSettings } =
        await adminDashboardRequests.getLoanSettings();
      this.loanPeriod = LoanSettings.delinquencyPeriod;
      if (
        this.paymentManagement.currentPaymentAmount ==
        this.paymentManagement.promoPaymentAmount
      ) {
        this.promoStatus = true;
        this.promoCheck = true;
      } else {
        this.promoStatus = false;
        this.promoCheck = false;
      }
      this.promopayStatus = false;
      this.promopayCheck = false;
    } catch (error) {
      await this.$swal({
        title: "Alert",
        text: "Error loading loan settings",
        icon: "error",
      });
    }
  },

  computed: {
    amountToNumber(): number {
      if (typeof this.amount === "string") {
        const newAmount = parseFloat(this.amount.replace(/[$,]/g, ""));
        return newAmount;
      }

      return this.amount;
    },
    paymentScheduleLeft(): any[] | undefined {
      if (this.paymentManagement && this.paymentManagement.paymentSchedule) {
        const response = this.paymentManagement.paymentSchedule.filter(
          (scheduleItem: any) => scheduleItem.status === "opened",
        );
        return response;
      }
      return undefined;
    },
    promoPaymentLeft(): any[] | undefined {
      if (
        this.paymentManagement &&
        this.paymentManagement.initialPaymentSchedule
      ) {
        const response = this.paymentManagement.initialPaymentSchedule.filter(
          (scheduleItem: any) => scheduleItem.status === "opened",
        );
        let i = 0;
        const response1: any[] = [];
        let startPrincipal = 0;
        let endPrincipal = 0;
        const responsePay = this.paymentManagement.paymentSchedule.filter(
          (scheduleItem: any) => scheduleItem.status === "paid",
        );
        let paidPrincipal = 0;
        const payDate = this.paymentManagement.nextPaymentSchedule;
        if (responsePay.length > 0) {
          let j = 0;
          for (const payV in responsePay) {
            paidPrincipal = responsePay[j].principal + paidPrincipal;
            j = j + 1;
          }
        }
        const DateObj = new Date(payDate);
        const dates = DateObj.getDate();
        let months = DateObj.getMonth() + 1;
        let years = DateObj.getFullYear();
        for (const x in response) {
          const resp: any = response[i];
          if (i == 0) {
            startPrincipal = resp.startPrincipal - paidPrincipal;
            resp.startPrincipal = startPrincipal;
            resp.interest = 0;
            resp.amount = this.paymentManagement.promoPaymentAmount - resp.fees;
            resp.principal = resp.amount;
            resp.endPrincipal = resp.startPrincipal - resp.amount;
            endPrincipal = resp.endPrincipal;
          } else {
            resp.startPrincipal = endPrincipal;
            startPrincipal = resp.startPrincipal;
            resp.amount = this.paymentManagement.promoPaymentAmount - resp.fees;
            resp.interest = 0;
            resp.principal = resp.amount;
            resp.endPrincipal = endPrincipal - resp.amount;
            endPrincipal = resp.endPrincipal;
          }
          resp.date = `${months}/${dates}/${years}`;
          if (months == 12) {
            months = 1;
            years = years + 1;
          } else {
            months = months + 1;
          }
          if (startPrincipal > this.paymentManagement.promoPaymentAmount) {
            response1.push(resp);
          } else if (startPrincipal > 0) {
            resp.amount = resp.startPrincipal - resp.fees;
            resp.interest = 0;
            resp.principal = resp.startPrincipal;
            resp.endPrincipal = 0;
            response1.push(resp);
          }
          i = i + 1;
        }
        return response1;
      }
      return undefined;
    },
    payments(): undefined | any[] {
      if (this.paymentManagement && this.paymentManagement.paymentSchedule) {
        const response = this.paymentManagement.paymentSchedule.filter(
          (payment: any) =>
            payment.status === "paid" || payment.status === "failed",
        );

        return response;
      }
      return undefined;
    },
  },

  methods: {
    async onDefaultCardSelected() {
      if (this.selectedDefaultCard == null) {
        await this.$swal({
          title: "Alert",
          text: "Please select a card or account",
          icon: "error",
        });
        return;
      }
      try {
        await adminDashboardRequests.setDefaultCardForAutopay(
          this.screenTrackingId,
          this.selectedDefaultCard,
        );
        this.defaultCardSelectModal = false;
        await this.$swal({
          title: "Success",
          text: "Card or account set as default successfully",
          icon: "success",
        });
      } catch (error) {
        this.rollbackDefaultPaymentMethod();
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async onclickStatusEdit(pmData: any) {
      let inputOptions = {
        fraud: "Fraud",
      };
      if (pmData.status.includes("in-repayment")) {
        inputOptions = {
          ...inputOptions,
          ...{ paid: "Paid", canceled: "Canceled" },
        };
      } else if (pmData.status === "paid") {
        delete inputOptions.fraud;
        inputOptions = {
          ...inputOptions,
          ...{ "in-repayment": "In repayment" },
        };
      }
      const result = await this.$swal({
        title: "Assign new status",
        input: "select",
        inputOptions,
        inputPlaceholder: "Select new status",
        showCancelButton: true,
      });
      if (
        result.value &&
        result.value !== "" &&
        result.value !== pmData.status
      ) {
        await adminDashboardRequests.changePmStatus(
          this.screenTrackingId,
          pmData._id,
          result.value,
        );
        await this.$swal({
          title: "Success",
          text: "Status updated successfully",
          icon: "success",
        });
        this.reloadPage();
      }
    },
    async forgiveSingleLateFee(payment: any) {
      const splitDate = payment.date
        .toString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/")
        .split(/\//);
      const finalDate = [splitDate[1], splitDate[0], splitDate[2]].join("/");

      try {
        const result = await this.$swal({
          title: `Do you want to forgive Late fee with amount of $${Number(
            payment.amount,
          ).toFixed(2)} on date ${finalDate}?`,
          showCancelButton: true,
          icon: "info",
          reverseButtons: false,
          confirmButtonText: `Yes`,
          cancelButtonText: `Cancel`,
        });
        if (result.isConfirmed) {
          await adminDashboardRequests.forgiveSingleLateFee(
            this.screenTrackingId,
            payment,
          );
          await this.$swal({
            title: "Alert",
            text: "Success!",
            icon: "success",
          });
        }
        this.reloadPage();
      } catch (error) {
        await this.$swal({
          title: "Alert",
          text: "Could not forgive Late fee",
          icon: "error",
        });
      }
    },
    async forgiveSingleNsfFee(payment: any) {
      const splitDate = payment.date
        .toString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/")
        .split(/\//);
      const finalDate = [splitDate[1], splitDate[0], splitDate[2]].join("/");

      try {
        const result = await this.$swal({
          title: `Do you want to forgive NSF fee with amount of $${payment.amount} on date ${finalDate}?`,
          showCancelButton: true,
          icon: "info",
          reverseButtons: false,
          confirmButtonText: `Yes`,
          cancelButtonText: `Cancel`,
        });
        if (result.isConfirmed) {
          await adminDashboardRequests.forgiveSingleNsfFee(
            this.screenTrackingId,
            payment,
          );
          await this.$swal({
            title: "Alert",
            text: "Success!",
            icon: "success",
          });
        }
        this.reloadPage();
      } catch (error) {
        await this.$swal({
          title: "Alert",
          text: "Could not forgive NSF fee",
          icon: "error",
        });
      }
    },
    async turnOnPromo() {
      try {
        if (this.paymentManagement) {
          const requestData = {
            paymentManagementId: this.paymentManagement._id,
          };
          let changeAmount = this.amountToNumber;

          if (this.promoStatus == false) {
            this.promoStatus = true;
            this.promoCheck = true;
            this.amount = this.paymentManagement.promoPaymentAmount;
            changeAmount = this.amountToNumber;
          } else {
            this.promoStatus = false;
            this.promoCheck = false;
            this.amount = this.paymentManagement.minimumPaymentAmount;
            changeAmount = this.amountToNumber;
          }
          await adminDashboardRequests.changePromoAmount(
            this.screenTrackingId,
            {
              amount: changeAmount,
            },
          );
          await this.$swal({
            title: "Success!",
            text: `Promo Status changed to ${this.promoStatus}`,
            icon: "success",
          });
          this.reloadPage();
        } else {
          this.$emit("updateTabIndex", 3);
          await this.$swal({
            title: "Alert",
            text: "Something went wrong. Try again later",
            icon: "info",
          });
        }
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async turnOnPromopay() {
      try {
        if (this.promopayStatus == false) {
          this.promopayStatus = true;
          this.promopayCheck = true;
        } else {
          this.promopayStatus = false;
          this.promopayCheck = false;
        }
      } catch (error) {
        // const errorMessage = await errorHandler(error, this.$router);
        // if (errorMessage) {
        //   await this.$swal({
        //     title: "Error",
        //     text: `${errorMessage}`,
        //     icon: "error",
        //   });
        // }
      }
    },
    async reloadPage() {
      try {
        const { data } = await adminDashboardRequests.getPaymentManagement(
          this.screenTrackingId,
        );
        const pm = data.response;
        const ledger = data.ledger;
        if (
          pm?.status === "in-repayment prime" ||
          pm?.status === "in-repayment" ||
          pm?.status === "in-repayment non-prime" ||
          pm?.status === "in-repayment delinquent1" ||
          pm?.status === "in-repayment delinquent2" ||
          pm?.status === "in-repayment delinquent3" ||
          pm?.status === "in-repayment delinquent4"
        ) {
          this.isInRepayment = true;
        } else if (pm?.status === "paid") {
          this.isInRepayment = false;
          this.isPaid = true;
        }

        this.paymentManagement = pm;
        if (!this.paymentManagement.defaultPaymentMethod) {
          this.paymentManagement.defaultPaymentMethod = "ACH";
        }
        this.ledger = ledger;

        const resApplication = await adminDashboardRequests.getApplication(
          this.screenTrackingId,
        );
        this.applicationData = resApplication.data;
      } catch (error) {
        if (error.response.status === 404) {
          return;
        }

        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }
      }
    },

    async partialReturn(email: string) {
      this.$swal
        .fire({
          title: "Partial Return",
          text: "Enter the amount to be returned",
          input: "number",
          showCancelButton: true,
          confirmButtonColor: "green",
        })
        .then((result) => {
          if (result.value) {
            const ss = parseInt(result.value);
            partialReturnDetails(email || "", ss);
            this.$swal.fire("Result:" + result.value);
          }
        });
    },

    async closeLoan(email: string) {
      this.$swal
        .fire({
          title: "Do you want to close your existing loan with Patria",
          showCancelButton: true,
          icon: "info",
          reverseButtons: false,
          confirmButtonText: `Yes`,
          cancelButtonText: `Cancel`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            try {
              closeLoanDetails(email || "");
              this.$swal({
                title: "Alert",
                text: "Email sent",
                icon: "success",
              });
            } catch (error) {
              this.$swal({
                title: "Alert",
                text: "Could not send close loan email",
                icon: "error",
              });
            }
          } else if (result.isDenied) {
            return false;
          }
        });
    },

    async forgivePayment() {
      try {
        const result = await this.$swal({
          title: "Do you want to waive next payment from the schedule?",
          showCancelButton: true,
          icon: "info",
          reverseButtons: false,
          confirmButtonText: `Yes`,
          cancelButtonText: `Cancel`,
        });
        if (result.isConfirmed) {
          await adminDashboardRequests.forgivePayment(this.screenTrackingId);
          await this.$swal({
            title: "Alert",
            text: "Success!",
            icon: "success",
          });
        }
        this.reloadPage();
      } catch (error) {
        await this.$swal({
          title: "Alert",
          text: "Could not forgive payment",
          icon: "error",
        });
      }
    },

    async forgiveLateFee() {
      try {
        const result = await this.$swal({
          title: "Do you want to forgive all late fees for this loan?",
          showCancelButton: true,
          icon: "info",
          reverseButtons: false,
          confirmButtonText: `Yes`,
          cancelButtonText: `Cancel`,
        });
        if (result.isConfirmed) {
          await adminDashboardRequests.forgiveLatefee(this.screenTrackingId);
          await this.$swal({
            title: "Alert",
            text: "Success!",
            icon: "success",
          });
        }
        this.reloadPage();
      } catch (error) {
        await this.$swal({
          title: "Alert",
          text: "Could not forgive late fee",
          icon: "error",
        });
      }
    },

    async forgiveNsfFee() {
      try {
        const result = await this.$swal({
          title: "Do you want to forgive all NSF fee for this loan?",
          showCancelButton: true,
          icon: "info",
          reverseButtons: false,
          confirmButtonText: `Yes`,
          cancelButtonText: `Cancel`,
        });
        if (result.isConfirmed) {
          await adminDashboardRequests.forgiveNsfFee(this.screenTrackingId);
          await this.$swal({
            title: "Alert",
            text: "Success!",
            icon: "success",
          });
        }
        this.reloadPage();
      } catch (error) {
        await this.$swal({
          title: "Alert",
          text: "Could not forgive NSF fee",
          icon: "error",
        });
      }
    },

    async deferPayment() {
      try {
        const result = await this.$swal({
          title:
            "Do you want to defer the next available payment for this loan?",
          showCancelButton: true,
          icon: "info",
          reverseButtons: false,
          confirmButtonText: `Yes`,
          cancelButtonText: `Cancel`,
        });
        if (result.isConfirmed) {
          await adminDashboardRequests.deferPayment(this.screenTrackingId);
          await this.$swal({
            title: "Alert",
            text: "Success!",
            icon: "success",
          });
        }
        this.reloadPage();
      } catch (error) {
        await this.$swal({
          title: "Alert",
          text: "Could not defer payment",
          icon: "error",
        });
      }
    },

    rollbackDefaultPaymentMethod() {
      this.paymentManagement.defaultPaymentMethod =
        this.paymentManagement.defaultPaymentMethod === "CARD" ? "ACH" : "CARD";
    },

    async toggleDefaultPaymentMethod(event: any) {
      const result = await this.$swal({
        title: "Do you want to switch the default payment method?",
        showCancelButton: true,
        icon: "info",
        reverseButtons: false,
        confirmButtonText: `Yes`,
        cancelButtonText: `Cancel`,
      });
      if (result.isConfirmed) {
        try {
          const result: any =
            await adminDashboardRequests.toggleDefaultPaymentMethod(
              this.paymentManagement._id,
              this.paymentManagement.defaultPaymentMethod,
            );
          if (result.data.cards) {
            this.userCardsList = result.data.cards;
            this.defaultCardSelectModal = true;
            return;
          }
          await this.$swal({
            title: "Success",
            text: "Default payment method switched successfully",
            icon: "success",
          });
        } catch (error) {
          this.rollbackDefaultPaymentMethod();
          const errorMessage = await errorHandler(error, this.$router);
          if (errorMessage) {
            await this.$swal({
              title: "Error",
              text: `${errorMessage}`,
              icon: "error",
            });
          }
        }
      } else {
        this.rollbackDefaultPaymentMethod();
      }
    },

    async toggleAutopay() {
      try {
        if (this.paymentManagement) {
          await adminDashboardRequests.toggleAutopay(
            this.paymentManagement._id,
          );
          await this.$swal({
            title: "Success!",
            text: `AutoPay is ${
              this.paymentManagement.canRunAutomaticPayment
                ? "Enabled"
                : "Disabled"
            }!`,
            icon: "success",
          });
          this.$emit("reloadPage");
        }
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },

    async determineCollectionTier(
      days: number,
      dPeriod: number,
    ): Promise<string> {
      if (days >= dPeriod) {
        return "Unassigned";
      } else {
        return "";
      }
    },

    async checkCollections() {
      if (this.paymentManagement && this.paymentManagement.paymentSchedule) {
        const response = this.paymentManagement.paymentSchedule.filter(
          (scheduleItem: any) => scheduleItem.status === "opened",
        );
        const today = moment().startOf("day").toDate();
        const { data: LoanSettings } =
          await adminDashboardRequests.getLoanSettings();
        this.loanPeriod = LoanSettings.delinquencyPeriod;
        const collectionStatus = await this.determineCollectionTier(
          moment(today).diff(response[0].date, "day"),
          this.loanPeriod,
        );
        this.collectionCheck = collectionStatus;
        if (
          this.paymentManagement.collectionAssignStatus &&
          this.paymentManagement.collectionAssignStatus != ""
        ) {
          this.collectionCheck = "";
        }
      } else {
        this.collectionCheck = "";
      }
    },

    async movetocollections(emails: string, inout: string) {
      if (emails) {
        try {
          let titleString = "Do you want to move this loan to collections";
          let alertString = "Loan moved to collections";
          let type = "Lending";
          if (inout == "out") {
            titleString = "Do you want to move this loan out of collections";
            alertString = "Loan moved out of collections";
            type = "Collections";
          }
          this.$swal
            .fire({
              title: titleString,
              showCancelButton: true,
              icon: "info",
              reverseButtons: false,
              confirmButtonText: `Yes`,
              cancelButtonText: `Cancel`,
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                try {
                  const myArrayFromLocalStorage =
                    localStorage.getItem("collectionData")!;
                  const myArray = JSON.parse(myArrayFromLocalStorage);
                  const moveResult = await moveLoanCollections(emails, type);
                  this.$swal({
                    title: "Alert",
                    text: `${alertString}`,
                    icon: "info",
                    confirmButtonText: `Ok`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      this.$router.go(0);
                    }
                  });
                } catch (error) {
                  this.$swal({
                    title: "Alert",
                    text: "Loan cannot be moved",
                    icon: "info",
                    confirmButtonText: `Ok`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      this.$router.go(0);
                    }
                  });
                }
              } else if (result.isDismissed) {
                //this.$router.go(0)
                return false;
              }
            });
        } catch (error) {
          this.$swal({
            title: "Alert",
            text: "Loan cannot be moved to collections",
            icon: "info",
          });
        }
      }
    },
    async onclickAprEdit() {
      const inputOptions = {
        199: 199,
        249: 249,
        299: 299,
        349: 349,
        399: 399,
        459: 459,
        499: 499,
        549: 549,
        599: 599,
        649: 649,
        699: 699,
        724: 724,
      };
      const result = await this.$swal({
        title: "Assign new status",
        input: "select",
        inputOptions,
        inputPlaceholder: "Select new APR",
        showCancelButton: true,
      });
      console.log({ result });
      if (!result.isConfirmed) {
        return;
      }
      await adminDashboardRequests.changeApr(
        this.screenTrackingId,
        Number(result.value),
      );
      await this.$swal({
        title: "Success",
        text: "APR updated successfully",
        icon: "success",
      });
      this.reloadPage();
    },
  },

  async mounted() {
    try {
      const { data } = await adminDashboardRequests.getPaymentManagement(
        this.screenTrackingId,
      );
      const pm = data.response;
      const ledger = data.ledger;
      if (
        pm?.status === "in-repayment prime" ||
        pm?.status === "in-repayment" ||
        pm?.status === "in-repayment non-prime" ||
        pm?.status === "in-repayment delinquent1" ||
        pm?.status === "in-repayment delinquent2" ||
        pm?.status === "in-repayment delinquent3" ||
        pm?.status === "in-repayment delinquent4"
      ) {
        this.isInRepayment = true;
      } else if (pm?.status === "paid") {
        this.isInRepayment = false;
        this.isPaid = true;
      } else if (pm?.status === "closed") {
        this.isInRepayment = false;
        this.isPaid = false;
        this.isClosed = true;
      }
      this.paymentManagement = pm;
      this.ledger = ledger;
      this.checkCollections();

      if (!this.paymentManagement.defaultPaymentMethod) {
        this.paymentManagement.defaultPaymentMethod = "ACH";
      }

      const resApplication = await adminDashboardRequests.getApplication(
        this.screenTrackingId,
      );
      this.applicationData = resApplication.data;
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }

      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },
});
