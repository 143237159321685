
import Vue from "vue";

import BaseTable from "../../base/Table.vue";

export default Vue.extend({
  name: "IncompleteTemplate",
  components: { BaseTable },
  data() {
    return {
      fields: [
        "name",
        { key: "status", label: "Loan Status" },
        { key: "origin", label: "Loan Origin" },
        { key: "approvedUpTo", label: "Approved Up To Amount" },
        { key: "selectedAmount", label: "Current Balance" },
        { key: "dateCreated", label: "Date Created" },
        { key: "phone", label: "Phone Number" },
        { key: "email", label: "Email Address" },
        { key: "userReference", label: "Ref Id" },
        { key: "progress", label: "Progress" },
      ],
    };
  },
  watch: {
    rows(val) {
      if (val.length) {
        this.$store.commit("loanTableEntries/setEntries", {
          idKeyName: "screenTrackingId",
          entries: this.rows,
        });
      }
    },
  },
  methods: {},
  props: {
    title: {
      required: false,
      default: null,
      type: String,
    },
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    }
  },
});
