
import Vue from 'vue';
import { adminDashboardRequests } from '@/api/admin-dashboard';
import { errorHandler } from '@/api/error-handler';
import { getAdminRoles } from '@/admin-dashboard/helpers';
import { getUserData } from '@/admin-dashboard/authentication/helpers';

export default Vue.extend({
  props: {
    screenTrackingId: String,
    modal: {
      type: Boolean,
      default: false,
    },
    onSuccess: Function,
    onCancel: Function,
  },
  data() {
    return {
      proofOfIncomeDoc: null as null | File,
      fileBase64: null as null | string,
      fileName: null as null | string,
      isLoading: false,
      userData: getUserData(),
      adminRoles: getAdminRoles(),
    };
  },

  computed: {},
  methods: {
    getValidationState({ validated, valid = null }: any) {
      return validated ? valid : null;
    },
    onFileSelected(event: any) {
      const selectedImage =
          event.dataTransfer?.files[0] || event.target?.files[0];
      this.fileName =
          event.dataTransfer?.files[0].name || event.target?.files[0].name;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileBase64 = (event.target?.result as string).split(
            'base64,'
        )[1];
      };
      reader.readAsDataURL(selectedImage);
    },
    async uploadDocument() {
      try {
        this.isLoading = true;
        const requestBody: any = {
          documentType: 'otherDoc',
          otherDocTitle: 'Proof Of Income Document',
        };
        this.$set(requestBody, requestBody.documentType, this.fileBase64);
        await adminDashboardRequests.uploadDocument(
            this.screenTrackingId,
            requestBody
        );
        this.isLoading = false;
        this.onSuccess();
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        this.isLoading = false;
        if (errorMessage) {
          await this.$swal({
            title: 'Error',
            text: `${errorMessage}`,
            icon: 'error',
          });
        }
      }
    },
  },
});
