
import Vue from "vue";

import BaseTable from "../../base/Table.vue";

export default Vue.extend({
  name: "IncompleteTemplate",
  components: { BaseTable },

  data() {
    return {
      collectionData: [{}],
      fields: [
        "name",
        { key: "origin", label: "Loan Origin" },
        { key: "status", label: "Loan Status" },
        { key: "approvedUpTo", label: "Approved Up To Amount" },
        { key: "selectedAmount", label: "Current Balance" },
        { key: "dateCreated", label: "Date Created" },
        { key: "phone", label: "Phone Number" },
        { key: "email", label: "Email Address" },
        { key: "delinquencyDays", label: "Delinquent Days" },
        { key: "collectionPromiseDate", label: "Promise to pay date" },
        { key: "assignedTo", label: "Assigned To" },
        { key: "location", label: "Location" },

        // { key: "check", label:  "Assign" },
      ],
    };
  },

  watch: {
    rows(val) {
      if (val.length) {
        this.$store.commit("loanTableEntries/setEntries", {
          idKeyName: "screenTrackingId",
          entries: this.rows,
        });
      }
    },
  },

  // methods: {
  //    onCheckboxInput(e: any , checkboxName: string , titleLabel: string , id: string , status: string) {

  //     if(this.collectionData != null && e.target.checked == false){
  //       let index = 0;
  //       for(const x in this.collectionData){
  //         if(index != 0){
  //         if(checkboxName === this.collectionData[index]){
  //           this.collectionData.splice(index);
  //         }
  //         }
  //         index = index + 1;
  //       }
  //       localStorage.setItem('collectionData', JSON.stringify(this.collectionData));

  //     }
  //     if(e.target.checked == true){
  //       this.collectionData.push(checkboxName);
  //       localStorage.setItem('collectionData', JSON.stringify(this.collectionData));
  //     }
  //     console.log('PSK LABEL \n\n\n\n' + JSON.stringify(this.collectionData));
  //     console.log('PSK LABEL \n\n\n\n' + e.target.checked);

  //   },
  // },

  props: {
    title: {
      required: false,
      default: null,
      type: String,
    },
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
  },
});
