
import Vue from "vue";
import ClickOutside from "vue-click-outside";

export default Vue.extend({
  data() {
    return {
      showDropdown: false,
      adminName: null,
    };
  },

  methods: {
    async signout() {
      localStorage.removeItem("adminToken");
      localStorage.setItem("app-logout", 'logout' + Math.random());
      await this.$router.replace("/admin/login");
    },
  },

  directives: { ClickOutside },
  mounted() {
    const { userName } = JSON.parse(localStorage.getItem("adminToken")!);
    this.adminName = userName;
    window.addEventListener('storage',  async function(event){
      if (event.key == "app-logout") {
        this.location.reload();
      }
    }, false);
  },
});
