
import Vue from "vue";

import Layout from "../layouts/admin/Layout.vue";
import Card from "@/components/primitives/Card.vue";
import UserInfo from "@/components/details/UserInfo.vue";
import DocumentCenter from "@/components/details/DocumentCenter.vue";
import CreditReport from "@/components/details/TransunionCreditReport.vue";
import Accounts from "@/components/details/Accounts.vue";
import CollectionsInfo from "@/components/details/CollectionsInfo.vue";
import CommentsTab from "@/admin-dashboard/loan-details/comments/components/CommentsTab.vue";
import PaymentSchedule from "@/components/details/PaymentSchedule.vue";
import Log from "@/admin-dashboard/audit-log/components/LogActivityTab.vue";
import RulesDetails from "@/admin-dashboard/rules-details/components/RuleDetailsLog.vue";
import { getUserData } from "@/admin-dashboard/authentication/helpers";
import { getAdminRoles } from "@/admin-dashboard/helpers";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import CommunicationActivityTab from "@/admin-dashboard/audit-log/components/CommunicationActivityTab.vue";
import DenyReasonsModal from "@/components/modals/DenyReasonsModal.vue";
import showErrorToast from "@/helpers/errorToast";
import { EventBus } from "@/helpers/eventBus";

export default Vue.extend({
  components: {
    DenyReasonsModal,
    Layout,
    Card,
    UserInfo,
    DocumentCenter,
    Accounts,
    CreditReport,
    PaymentSchedule,
    CollectionsInfo,
    CommentsTab,
    Log,
    RulesDetails,
    CommunicationActivityTab,
  },

  data() {
    return {
      adminRoles: getAdminRoles(),
      userData: getUserData(),
      screenTrackingId: this.$route.params.screenTrackingId,
      paymentManagement: [] as any,
      userName: "" as string,
      userEmail: "" as string,
      isManualReview: false,
      paymentStatus: null,
      applicationData: null,
      duplicatesList: [],
    };
  },
  methods: {
    reloadPage() {
      this.$router.back();
    },
    async getApplicationData() {
      const { data } = await adminDashboardRequests.getApplication(
        this.$route.params.screenTrackingId,
      );
      this.applicationData = data;
      this.userName = data.name;
      this.userEmail = data.email;
    },
    async changeApplicationStatus(status: string, fundThroughAch = false) {
      const action = status === "approved" ? "approve" : "deny";
      const result = await this.$swal({
        title: `Are you sure you want to ${action} this application?`,
        showCancelButton: true,
        icon: "question",
        reverseButtons: false,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
      });
      if (result.isConfirmed) {
        try {
          if (status === "approved") {
            const payload = {
              screentrackingId: this.screenTrackingId,
              status,
              fundThroughAch,
            };
            const response =
              await adminDashboardRequests.updateManualReviewed(payload);
            if (response?.data?.error) {
              throw new Error(response?.data?.message);
            }
            showErrorToast(this, "success", response?.data?.message);
            this.$router.back();
          } else {
            EventBus.$emit("isDenyReasonsModal", true);
          }
        } catch (error) {
          showErrorToast(this, "error", error?.message);
        }
      }
    },
    async cancelApplication() {
      const result = await this.$swal({
        title: `Are you sure you want to cancel this application?`,
        showCancelButton: true,
        icon: "question",
        reverseButtons: false,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
      });
      if (result.isConfirmed) {
        await adminDashboardRequests.cancelApplication(this.screenTrackingId);
        showErrorToast(this, "success", "Application canceled");
        this.$router.back();
      }
    },
    async bypassFlink() {
      const result = await this.$swal({
        title: `Are you sure you want to override this application?`,
        showCancelButton: true,
        icon: "question",
        reverseButtons: false,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
      });
      if (result.isConfirmed) {
        const { data } = await adminDashboardRequests.bypassFlink(
          this.screenTrackingId,
        );
        if (!data.error) {
          showErrorToast(this, "success", data.message);
          await this.getApplicationData();
        }
      }
    },
  },
  async mounted() {
    const { data } = await adminDashboardRequests.getPaymentManagement(
      this.$route.params.screenTrackingId,
    );
    this.paymentManagement = data.response;
    this.paymentStatus = data.response.status;
    this.duplicatesList = data.duplicateBankNumbersScreenTrackingList;
    const { status } = this.$route?.query;
    if (status === "MR") {
      this.isManualReview = true;
    }
  },
  async created() {
    await this.getApplicationData();
  },
});
