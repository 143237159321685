
import Vue from "vue";

import Layout from "@/views/layouts/admin/Layout.vue";
import Counter from "@/components/Counter.vue";
import DashboardTable from "@/components/Tables/DashboardTable.vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  components: { Layout, DashboardTable, Counter },

  data() {
    return {
      countByStatus: [] as [string, unknown][],
      nameForStatus: {
        opportunities: "Application status - Approved, Pending",
        denied: "Denied Applications",
        expired: "Expired Applications",
        readyToFund: "Ready to Fund Applications",
        authPending: "Authentication Pending",
        delinquent: "Delinquent Accounts",
        inRepayment: "Loan In Repayment",
        canceled: "Canceled applications",
      },
      linkForStatus: {
        opportunities: "/admin/opportunities",
        denied: "/admin/opportunities",
        expired: "/admin/opportunities",
        readyToFund: "/admin/opportunities",
        authPending: "/admin/opportunities",
        delinquent: "/admin/needs-review",
        inRepayment: "/admin/lending-center",
        canceled: "/admin/opportunities",
      },
    };
  },

  async mounted() {
    try {
      const { data } = await adminDashboardRequests.getTotalRowsByStatus();
      const totalsObj = data;

      this.countByStatus = Object.entries(totalsObj).filter(([status]) =>
        [
          "opportunities",
          "denied",
          "expired",
          "readyToFund",
          "authPending",
          "delinquent",
          "inRepayment",
          "canceled",
        ].includes(status)
      );
    } catch (error) {
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        showErrorToast(this, "error", errorMessage);
      }
    }
  },
});
