var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Layout',[_c('h2',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Loan Details - "),_c('span',{staticStyle:{"font-weight":"normal","font-size":"25px"}},[_vm._v(_vm._s(_vm.userName))])]),_c('card',[(_vm.paymentStatus === 'manual-review')?_c('div',{staticStyle:{"padding-bottom":"8px"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.paymentManagement.screenTracking.underwritingDecision.reason)+" ")]),(
          _vm.duplicatesList.length && _vm.userData.role === _vm.adminRoles.SuperAdmin
        )?_c('div',[_c('h4',{staticStyle:{"display":"inline","margin-right":"5px"}},[_vm._v("Duplicates:")]),_vm._l((_vm.duplicatesList),function(item,index){return _c('router-link',{key:index,attrs:{"to":`/admin/loan-details/${item.screenTracking}`,"target":"_blank"}},[_c('h4',{staticStyle:{"display":"inline"}},[_vm._v(_vm._s(item.fullName))]),(
              index !== _vm.duplicatesList.length - 1 && _vm.duplicatesList.length > 1
            )?[_vm._v(", ")]:_vm._e()],2)})],2):_vm._e()]):_vm._e(),(
        _vm.paymentStatus === 'manual-review' &&
        this.applicationData.previousDeniedApplications.length
      )?_c('div',[_c('h3',[_vm._v("Previous Denied Applications:")]),_vm._l((this.applicationData
          .previousDeniedApplications),function(item,index){return _c('h4',{key:index},[_c('router-link',{attrs:{"to":`/admin/loan-details/${item.screenTracking}`,"target":"_blank"}},[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('span',[_vm._v(" - "+_vm._s(item.denyReason))])],1)})],2):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","margin":"8px 0px"}},[_c('AssignCollections'),(
          _vm.paymentStatus === 'manual-review' &&
          _vm.userData.role === _vm.adminRoles.SuperAdmin
        )?_c('button',{staticStyle:{"background-color":"teal","color":"white"},on:{"click":function($event){return _vm.changeApplicationStatus('approved')}}},[_vm._v(" Approve ")]):_vm._e(),(
          _vm.paymentStatus === 'manual-review' &&
          _vm.userData.role === _vm.adminRoles.SuperAdmin
        )?_c('button',{staticStyle:{"margin-left":"10px","background-color":"red","color":"white"},on:{"click":function($event){return _vm.changeApplicationStatus('denied')}}},[_vm._v(" Denied ")]):_vm._e(),(_vm.applicationData && _vm.applicationData.isFlinkStuck)?_c('button',{staticStyle:{"margin-left":"10px","background-color":"teal","color":"white"},on:{"click":function($event){return _vm.bypassFlink()}}},[_vm._v(" Bypass Flink ")]):_vm._e(),(['pending', 'manual-review'].includes(_vm.paymentStatus))?_c('button',{staticStyle:{"margin-left":"10px","background-color":"#ea4c89","color":"white"},on:{"click":function($event){return _vm.cancelApplication()}}},[_vm._v(" Cancel ")]):_vm._e(),(
          _vm.userData.role === _vm.adminRoles.SuperAdmin &&
          _vm.applicationData.fundThroughAch
        )?_c('button',{staticStyle:{"margin-left":"10px","background-color":"teal","color":"white"},on:{"click":function($event){return _vm.changeApplicationStatus('approved', true)}}},[_vm._v(" Fund through ACH ")]):_vm._e()],1),_c('b-tabs',[_c('b-tab',{attrs:{"title":"User Info","lazy":""}},[_c('UserInfo',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1),_c('b-tab',{attrs:{"title":"Document Center","lazy":""}},[_c('DocumentCenter',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1),(_vm.userData.role === _vm.adminRoles.SuperAdmin)?_c('b-tab',{attrs:{"title":"Credit Reports","lazy":""}},[_c('CreditReport',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1):_vm._e(),_c('b-tab',{attrs:{"title":"Payment Schedule","lazy":""}},[_c('PaymentSchedule',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1),(
          this.paymentManagement.collectionAssignStatus &&
          this.paymentManagement.collectionAssignStatus != ''
        )?_c('b-tab',{attrs:{"title":"Collections","lazy":""}},[_c('CollectionsInfo',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1):_vm._e(),_c('b-tab',{attrs:{"title":"Accounts","lazy":""}},[_c('Accounts',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1),_c('b-tab',{attrs:{"title":"Comments","lazy":""}},[_c('CommentsTab',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1),(_vm.userData.role === _vm.adminRoles.SuperAdmin)?_c('b-tab',{attrs:{"title":"Log","lazy":""}},[_c('Log',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1):_vm._e(),(_vm.userData.role === _vm.adminRoles.SuperAdmin)?_c('b-tab',{attrs:{"title":"Rules Details ","lazy":""}},[_c('RulesDetails',{attrs:{"screenTrackingId":_vm.screenTrackingId}})],1):_vm._e(),(
          _vm.userData.role === _vm.adminRoles.SuperAdmin ||
          _vm.userData.role === _vm.adminRoles.UserServicing
        )?_c('b-tab',{attrs:{"title":"Communication History","lazy":""}},[_c('CommunicationActivityTab',{attrs:{"screenTrackingId":_vm.screenTrackingId,"userEmail":_vm.userEmail,"phoneNumber":_vm.userData}})],1):_vm._e()],1)],1),_c('DenyReasonsModal',{attrs:{"screenTrackingId":_vm.screenTrackingId},on:{"reloadPage":function($event){return _vm.reloadPage()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }