
import Vue from "vue";

import Layout from "@/user-application/layout/pages/Layout.vue";
import UserInfo from "./UserInfo.vue";
import LoanInfo from "./LoanInfo.vue";
import Payment from "./PaymentSchedule.vue";
import Accounts from "./Accounts.vue";
import DocumentCenter from "./DocumentCenter.vue";
import Card from "@/components/primitives/Card.vue";
import { getApplicationData } from "@/user-application/application/api";
import { getDashboardData } from "@/user-application/dashboard/api";
import showErrorToast from "@/helpers/errorToast";
import { adminDashboardRequests } from "@/api/admin-dashboard";

export default Vue.extend({
  components: {
    Layout,
    Card,
    UserInfo,
    LoanInfo,
    Payment,
    Accounts,
    DocumentCenter,
  },

  data() {
    return {
      tabIndex: 0,
      addCardFlag: false,
      screenTrackingId: null as null | string,
      firstName: null as null | string,
      paymentManagementData: null as null | Record<string, any>,
      applicationDataResponse: null as null | [],
      dashboardDataResponse: null as null | [],
      documentsDataResponse: null as null | [],
      isScreenCompleted: false,
    };
  },

  methods: {
    updateTabIndex(index: number) {
      this.addCardFlag = true;
      this.tabIndex = index;
    },
    onContinueApplication() {
      const userToken = String(localStorage.getItem("userToken"));
      const { token: JWT } = JSON.parse(userToken);
      const url = `${process.env.VUE_APP_LOS_URL}/login/magic?userToken=${JWT}`;
      window.open(url, "_blank");
    },
  },

  async created() {
    try {
      const [applicationDataResponse, dashboardDataResponse] =
        await Promise.all([getApplicationData(), getDashboardData()]);

      this.applicationDataResponse = applicationDataResponse.data;
      this.dashboardDataResponse = dashboardDataResponse.data;
      const { firstName, screenTrackingId, isCompleted } =
        applicationDataResponse.data;
      this.isScreenCompleted = isCompleted;
      this.firstName = firstName;
      this.screenTrackingId = screenTrackingId;

      const { paymentManagementData } = dashboardDataResponse.data;
      this.paymentManagementData = paymentManagementData;
    } catch (error) {
      if (error.response?.status === 401) {
        await this.$router.push({ name: "userLogin" });
      } else {
        showErrorToast(this, "error", error.message);
      }
    }
  },
});
