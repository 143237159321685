
import Vue from "vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import Datepicker from "vuejs-datepicker";
import moment from 'moment';

export default Vue.extend({
  components: {
    Datepicker
  },
  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
    loanStartDate: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      date: "" as Date | string,
      error: "",
      isLoading: false,
      modal: false,
    };
  },
  methods: {
    onChangeLoanStartDate() {
      this.modal = true;
      this.date = moment(this.loanStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    },
    onCancel() {
      this.error = "";
      this.isLoading = false;
      this.modal = false;
    },
    async onSubmit() {
      if (this.date === moment(this.loanStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) {
        this.error = "Financing start date has not changed";
        return;
      }
      try {
        this.isLoading = true;
        const date = moment(this.date).format('DD/MM/YYYY');
        await adminDashboardRequests.changeLoanStartDate(
          this.screenTrackingId,
            date
        );
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: "Financing start date successfully changed.",
          icon: "success",
        });

        this.error = "";
        this.modal = false;
        this.$emit("reloadPage");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }

        this.error = "";
        this.isLoading = false;
        this.modal = false;
      }
    },
  },
});
