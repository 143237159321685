
import Vue from "vue";
import { mapMutations } from "vuex";

import Layout from "@/user-application/layout/pages/Layout.vue";
import { getApplicationData } from "@/user-application/application/api";
import showErrorToast from "@/helpers/errorToast";
import IOffer from "../types/IOffer";

export default Vue.extend({
  components: {
    Layout,
  },

  data() {
    return {
      address: null as null | string,
      allowCreditReportPull: false,
      annualIncome: null as null | number,
      approvedUpTo: null as null | number,
      city: null as null | string,
      email: null as null | string,
      firstName: null as null | string,
      lastName: null as null | string,
      phones: [] as {
        phone: string;
        type: "mobile" | "home" | "office";
      }[],
      referenceNumber: null as null | string,
      requestedAmount: null as null | number,
      selectedOffer: null as null | IOffer,
      ssn: null as null | string,
      state: null as null | string,
      zip: null as null | string,
    };
  },

  methods: {
    ...mapMutations(["setStep"]),
    async onSubmit() {
      await this.$router.push("/sign-contract");
    },
  },

  async created() {
    try {
      const { data } = await getApplicationData();
      const {
        address,
        annualIncome,
        approvedUpTo,
        city,
        email,
        firstName,
        isCompleted,
        lastName,
        lastStep,
        phones,
        referenceNumber,
        requestedAmount,
        selectedOffer,
        ssn,
        state,
        zip,
      } = data;

      if (isCompleted) {
        await this.$router.push({ name: "userDashboard" });
      } else if (!selectedOffer) {
        await this.$router.push({ name: "offers" });
      } else if (selectedOffer && lastStep !== "offers") {
        await this.$router.push({ name: lastStep });
      } else {
        this.setStep(3);

        this.address = address;
        this.annualIncome = annualIncome;
        this.approvedUpTo = approvedUpTo;
        this.city = city;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phones = phones;
        this.referenceNumber = referenceNumber;
        this.requestedAmount = requestedAmount;
        this.selectedOffer = selectedOffer;
        this.ssn = ssn;
        this.state = state;
        this.zip = zip;
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await this.$router.push({ name: "userLogin" });
      } else {
        showErrorToast(this, "error", error.message);
      }
    }
  },
});
