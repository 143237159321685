
import Vue from "vue";

import Datepicker from "vuejs-datepicker";
import moment from "moment";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import states from "@/helpers/states";

export default Vue.extend({
  components: {
    Datepicker,
  },

  props: {
    currentAddress: {
      required: false,
      type: String,
    },
    currentCity: {
      required: false,
      type: String,
    },
    currentLocation: {
      required: false,
      type: String,
    },
    currentManagementRegion: {
      required: false,
      type: String,
    },
    currentOpenDate: {
      required: false,
      type: String,
    },
    currentPhone: {
      required: false,
      type: String,
    },
    currentRegion: {
      required: false,
      type: String,
    },
    currentRegionalManager: {
      required: false,
      type: String,
    },
    currentState: {
      required: false,
      type: String,
    },
    currentZip: {
      required: false,
      type: String,
    },
    practiceManagementId: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      address: null as null | string,
      city: null as null | string,
      disabledDates: {
        to: moment()
          .subtract(100, "years")
          .startOf("day")
          .toDate(),
        from: moment()
          .startOf("day")
          .toDate(),
      },
      isLoading: false,
      location: null as null | string,
      managementRegion: null as null | string,
      openDate: moment()
        .startOf("day")
        .toISOString(),
      phone: null as null | string,
      region: null as null | string,
      regionalManager: null as null | string,
      selectedState: null as null | string,
      states: states as { state: string; stateCode: null | string }[],
      zip: null as null | string,
    };
  },

  computed: {
    parsedPhoneNumber(): string {
      const parsedNumber = this.phone?.replace(/[^0-9]/g, "") ?? "";
      return parsedNumber;
    },
    parsedStates(): { value: string | null; text: string }[] {
      return this.states.map((state) => {
        return {
          value: state.stateCode,
          text: state.state,
        };
      });
    },
  },

  methods: {
    getValidationState({
      dirty,
      validated,
      valid = null,
    }: {
      dirty: boolean;
      validated: boolean;
      valid: null | boolean;
    }) {
      return dirty || validated ? valid : null;
    },
    async savePractice() {
      try {
        const requestParams = {
          address: this.address || "",
          city: this.city || "",
          isDeleted: false,
          location: this.location || "",
          managementRegion: this.managementRegion || "",
          openDate: this.openDate,
          phone: this.parsedPhoneNumber,
          region: this.region || "",
          regionalManager: this.regionalManager || "",
          stateCode: this.selectedState || "",
          zip: this.zip || "",
        };
        await adminDashboardRequests.addPracticeManagement(requestParams);

        await this.$swal({
          title: "Success!",
          text: "Practice successfully added.",
          icon: "success",
        });
        this.$router.push("/admin/manage-practice");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async updatePractice(practiceManagementId: string) {
      try {
        const requestParams = {
          address: this.address || "",
          city: this.city || "",
          isDeleted: false,
          location: this.location || "",
          managementRegion: this.managementRegion || "",
          openDate: this.openDate,
          phone: this.parsedPhoneNumber,
          region: this.region || "",
          regionalManager: this.regionalManager || "",
          stateCode: this.selectedState || "",
          zip: this.zip || "",
        };
        await adminDashboardRequests.updatePracticeManagementById(
          practiceManagementId,
          requestParams
        );
        await this.$swal({
          title: "Success!",
          text: "Admin successfully added.",
          icon: "success",
        });
        this.$router.push("/admin/manage-practice");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async onSubmit() {
      if (this.practiceManagementId) {
        await this.updatePractice(this.practiceManagementId);
      } else {
        await this.savePractice();
      }
    },
  },

  async mounted() {
    const practiceManagementId = this.practiceManagementId;

    if (this.practiceManagementId) {
      this.isLoading = true;
      try {
        const { data } = await adminDashboardRequests.getPracticeManagementById(
          practiceManagementId
        );
        const {
          address,
          city,
          location,
          managementRegion,
          openDate,
          phone,
          region,
          regionalManager,
          stateCode,
          zip,
        } = data;
        this.address = address;
        this.city = city;
        this.location = location;
        this.managementRegion = managementRegion;
        this.openDate = openDate;
        this.phone = phone;
        this.region = region;
        this.regionalManager = regionalManager;
        this.selectedState = stateCode;
        this.zip = zip;

        this.isLoading = false;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }

        this.isLoading = false;
      }
    }
  },
});
