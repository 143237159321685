
import Vue from "vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import showErrorToast from "@/helpers/errorToast";
import { EventBus } from "@/helpers/eventBus";

type DenyReason = {
  value: string;
  text: string;
};

export default Vue.extend({
  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      denyReasons: [
        { value: "MI", text: "Min income" },
        { value: "CRB", text: "Current delinquency" },
        { value: "NED", text: "Did not provide requested information" },
        {
          value: "DDA",
          text: "Insufficient transactions in your checking account",
        },
        { value: "QRB", text: "Account age in days" },
        { value: "LST", text: "Loan stacking message" },
        { value: "SEM", text: "Self employed" },
        { value: "IBA", text: "Ineligible Bank Account Provided" },
        {
          value: "PPD",
          text: "Mobile Phone Provided is Invalid or Inoperable",
        },
        { value: "FRD", text: "Fraudulent Application" },
        { value: "SCR", text: "Credit score" },
      ] as DenyReason[],
      denyReason: "",
      error: "",
      isLoading: false,
      modal: false,
    };
  },
  methods: {
    onCancel() {
      this.error = "";
      this.isLoading = false;
      this.modal = false;
    },
    getValidationState({ validated, valid = null }: any) {
      return validated ? valid : null;
    },
    async onSubmit() {
      if (this.denyReason === "") {
        this.error = "Please select a denial reason";
        return;
      }
      try {
        this.isLoading = true;
        const reason = this.denyReasons.find(
          (r) => r.value === this.denyReason,
        );
        if (!reason) {
          this.error = "Please select a denial reason";
          return;
        }
        const payload = {
          screentrackingId: this.screenTrackingId,
          status: reason.value === "FRD" ? "fraud" : "denied",
          denyReason: `${reason.value} - ${reason.text}`,
        };
        const response =
          await adminDashboardRequests.updateManualReviewed(payload);
        if (response?.data?.error) {
          throw new Error(response?.data?.message);
        }
        showErrorToast(this, "success", response?.data?.message);
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: "Financing start date successfully changed.",
          icon: "success",
        });

        this.error = "";
        this.modal = false;
        this.$emit("reloadPage");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.error = "";
        this.isLoading = false;
        this.modal = false;
      }
    },
  },
  mounted() {
    EventBus.$on("isDenyReasonsModal", (isDenyReasonsModal: boolean) => {
      this.modal = isDenyReasonsModal;
    });
  },
});
