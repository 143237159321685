
import Vue from "vue";
import BaseTable from "../../base/Table.vue";

export default Vue.extend({
  name: "AdminTemplate",
  components: { BaseTable },
  data() {
    return {
      modal: false,
      selectedItem: {},
      fields: [
        { key: "name", label: "User Name" },
        { key: "leadType", label: "Source" },
        { key: "leadSource", label: "Lead Source" },
        { key: "status", label: "Status" },
        { key: "reason", label: "Deny Reason" },
        { key: "leadAge", label: "Lead Age" },
        { key: "application", label: "Application" },
        { key: "id", label: "Clarity Response" },
        { key: "vendor", label: "Vendor" },
      ],
    };
  },
  methods: {
    showClarityData(id: any) {
      const item = this.rows.find((item: any) => item.id === id) as any;
      this.selectedItem = item?.clarityData ? item.clarityData : "No data";
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
    parseClarityAction(data: any) {
      return data ? data?.action : "No data";
    },
    parseVendorData(vendor: any) {
      return vendor ? vendor?.name : "No Vendor";
    },
  },
  props: {
    title: {
      required: false,
      default: null,
      type: String,
    },
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
  },
});
