
import Vue from "vue";

import BaseTableSection from "./base/TableSection.vue";
import PracticeManagementTemplate from "./templates/practices/PracticeManagementTemplate.vue";
import { adminDashboardRequests } from "../../api/admin-dashboard";
import { TableRequestEvent } from "../../types/tables";

const DEFAULT_REQUEST_PARAMS = { page: 1, perPage: 25 };

export default Vue.extend({
  components: {
    BaseTableSection,
    PracticeManagementTemplate,
  },

  data() {
    return {
      defaultParams: DEFAULT_REQUEST_PARAMS,
      currentStatus: "",
      rows: [],
      totalRows: 0,
    };
  },

  methods: {
    async fetchTable(event: TableRequestEvent) {
      this.currentStatus = event.status as string;
      const { data } = await adminDashboardRequests.getAllPracticeManagements({
        ...event,
      });
      const { rows, totalRows } = data;

      this.rows = rows;
      this.totalRows = totalRows;
    },
  },
});
