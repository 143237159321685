
import Vue from "vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";

export default Vue.extend({
  components: {
  },

   props: {
    dateValue: {
      required: true,
      type: String,
    },
    timeValue: {
      required: true,
      type: String,
    },
    scheduleValue: {
      required: true,
      type: String,
    },
    amountValue: {
      required: true,
      type: Number,
    },
    idValue: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      modal: false,
      adminUsers: [] as {
        email: string;
        role: string;
        id: string;
      }[],
      selectedCard: "",
      isLoading: false,
    };
  },

  computed: {

  },

  methods: {
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    onCancel() {
      this.isLoading = false;
      this.modal = false;
    },

    async onAssign() {
      try{
        await adminDashboardRequests.changeStatusPromisetopay({
          paymentId: this.idValue,
          promiseScheduleDate: this.scheduleValue,
          promiseScheduleStatus: "Removed",
        });
        this.$swal({
          title: `Alert`,
          text: "Promise to Pay Removed",
          icon: "info",
          confirmButtonText: `Ok`,
        }).then(async (result) => {
          if(result.isConfirmed){
            this.modal = false;
            this.$router.go(0);
          }
        });
      }catch(error){
          this.$swal({
          title: `${error}`,
          text: "Promise to Pay Removal Unsuccessful",
          icon: "info",
          confirmButtonText: `Ok`,
        }).then(async (result) => {
          if(result.isConfirmed){
            this.modal = false;
          }
        });
      }
    },
    async onRemovePromise() {
      if(this.amountValue != null && this.dateValue != null && this.timeValue != null ){
          this.modal = true;
      }else{
          await this.$swal({
            title: "Alert",
            text: `An error has occured when removing this Promise to Pay.`,
            icon: "error",
          });
        }
      },
    async showPopup(sender: any[]) {
      await this.$swal({
        title: "Error",
        text: `${JSON.stringify(sender)}`,
        icon: "error",
      });
    },
  },
  async mounted() {
    const { data } = await adminDashboardRequests.getAdmin(
      
    );

    if (data && data.rows.length > 0) {
      this.adminUsers = data.rows.filter(
        (admin: any) => admin.role == 'Super Admin'
      );
      this.selectedCard = "";
    }
  },
});
