
import Vue from "vue";
import { mapMutations } from "vuex";

import { isUserAuthenticated } from "@/user-application/authentication/helpers";

export default Vue.extend({
  props: {
    login: {
      required: false,
      type: Boolean,
    },
  },

  data() {
    return {
      isAuthenticated: false,
    };
  },
  methods: {
    ...mapMutations(["setStep"]),
    async removeToken() {
      localStorage.removeItem("userToken");
      localStorage.setItem("appclient-logout", 'logout' + Math.random());
      this.setStep(1);
      await this.$router.push({ name: "userLogin" });
    },
  },

   mounted() {
    window.addEventListener('storage',  async function(event){
      if (event.key == "appclient-logout") {
        this.location.reload();
      }
    }, false);
  },

  created() {
    this.isAuthenticated = isUserAuthenticated();
  },
});
