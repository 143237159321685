
import Vue from "vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import { getAdminRoles } from "@/admin-dashboard/helpers";
import { getUserData } from "@/admin-dashboard/authentication/helpers";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const feeMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 7,
});

const dayMask = createNumberMask({
  prefix: "",
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 3,
});

export default Vue.extend({
  props: {
  },

  data() {
    return {
      feeMask,
      dayMask,
      lateFee: null as null | string,
      nsfFee: null as null | string,
      lateFeeGracePeriod: null as null | string,
      delinquencyPeriod: null as null | string,
      eventsUrl: null as null | string,
      eventsAuthToken: null as null | string,
      updateLateFee: null as null | string,
      updateNsfFee: null as null | string,
      updateLateFeeGracePeriod: null as null | string,
      updateDelinquencyPeriod: null as null | string,
      updateEventsUrl: null as null | string,
      updateEventsAuthToken: null as null | string,
      isLoading: false,
      modal: false,
      userData: getUserData(),
      adminRoles: getAdminRoles(),
    };
  },

  computed: {
  },
  methods: {
    hideModal(e: any) {
      this.modal = false;
    },
    async saveLoanSettings() {
      try {
        const requestParams = {
          lateFee: parseFloat(parseFloat((this.updateLateFee || '').replace(/[$,]/g, "")).toFixed(2)),
          nsfFee: parseFloat(parseFloat((this.updateNsfFee || '').replace(/[$,]/g, "")).toFixed(2)),
          lateFeeGracePeriod: parseInt(this.updateLateFeeGracePeriod || ''),
          delinquencyPeriod: parseInt(this.updateDelinquencyPeriod || ''),
          eventsUrl: this.updateEventsUrl,
          eventsAuthToken: this.updateEventsAuthToken,
        };

        this.isLoading = true;
        const {data: UpdatedLoanSettings} = await adminDashboardRequests.updateLoanSettings(requestParams);
        this.lateFee = UpdatedLoanSettings.lateFee;
        this.nsfFee = UpdatedLoanSettings.nsfFee;
        this.lateFeeGracePeriod = UpdatedLoanSettings.lateFeeGracePeriod;
        this.delinquencyPeriod = UpdatedLoanSettings.delinquencyPeriod;
        this.eventsUrl = UpdatedLoanSettings.eventsUrl;
        this.eventsAuthToken = UpdatedLoanSettings.eventsAuthToken;
        this.isLoading = false;
        this.modal = false;
        await this.$swal({
          title: "Success!",
          text: "Loan Settings Updated",
          icon: "success",
        });
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        this.isLoading = false;
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
  },
  async created() {
    try {
        const {
            data: LoanSettings,
        } = await adminDashboardRequests.getLoanSettings();

        this.lateFee = LoanSettings.lateFee;
        this.nsfFee = LoanSettings.nsfFee;
        this.lateFeeGracePeriod = LoanSettings.lateFeeGracePeriod;
        this.delinquencyPeriod = LoanSettings.delinquencyPeriod;
        this.eventsUrl = LoanSettings.eventsUrl;
        this.eventsAuthToken = LoanSettings.eventsAuthToken;
        this.updateEventsUrl = LoanSettings.eventsUrl;
        this.updateEventsAuthToken = LoanSettings.eventsAuthToken;
        this.updateLateFee = LoanSettings.lateFee;
        this.updateNsfFee = LoanSettings.nsfFee;
        this.updateLateFeeGracePeriod = LoanSettings.lateFeeGracePeriod;
        this.updateDelinquencyPeriod = LoanSettings.delinquencyPeriod;
    } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
            await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
            });
        }
    }
  },
});
