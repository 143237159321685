
import Vue from "vue";
import BaseTable from "../../base/Table.vue";

export default Vue.extend({
  name: "AdminTemplate",
  components: { BaseTable },
  data() {
    return {
      fields: [
        { key: "userName", label: "User Name" },
        { key: "email", label: "Email Address" },
        { key: "phone", label: "Phone Number" },
        { key: "role", label: "Role" },
        { key: "location", label: "Location" },
        { key: "createdDate", label: "Created Date " },
        { key: "id", label: "Action" },
      ],
    };
  },
  methods: {},
  props: {
    title: {
      required: false,
      default: null,
      type: String,
    },
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
  },
});
