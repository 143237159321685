
import Vue from "vue";

import BaseTable from "../../base/Table.vue";
import { updateCustomerDetails , closeLoanDetails , moveLoanCollections } from "@/user-application/authentication/api";
import { refundPaymentData, getPaymentSchedule } from "@/user-application/authentication/api";
import { adminDashboardRequests } from "@/api/admin-dashboard";
export default Vue.extend({
  name: "IncompleteTemplate",
  components: { BaseTable },

  data() {
    return {
      collectionData: [{}],
      userData: [],
      adminUsers: [] as {
        email: string;
        role: string;
        id: string;
      }[],
      fields: [
        "name",
        { key: "status", label: "Loan Status"},
        { key: "promisedDate", label: "Promised Date" },
        { key: "promisedAmount", label: "Promised Amount" },
        { key: "promisetopayStatus", label: "Promise to pay Status" },
        { key: "email", label: "Email Address" },
        { key: "assignedTo", label: "Assigned To" },
        { key: "check", label:  "Assign" },
        { key: "edit", label: "" },
      ],
    };
  },

  watch: {
    rows(val) {
      if (val.length) {
        this.$store.commit("loanTableEntries/setEntries", {
          idKeyName: "screenTrackingId",
          entries: this.rows,
        });
      }
    },
  },

   async mounted() {

    const { data } = await adminDashboardRequests.getAdmin(
      
    );

   
    
    if (data && data.rows.length > 0) {
      this.adminUsers = data.rows.filter(
        (admin: any) => admin.role == 'Super Admin'
      );
    }
  },

  methods: {
  async getUserinfos(id: string): Promise<string> {
    try {
      
      if(id && id != ''){
      this.adminUsers = this.adminUsers.filter(
        (admin: any) => admin.id === id
      );
      return "";
      }else{
      return 'Unassigned';
      }
    } catch (error) {
       return '';
    }
    },
    async closeLoan(email: string){
      this.$swal.fire({
        title: 'Would you like to send the closing loan link to customer',
        showCancelButton: true,
        icon: "info",
        reverseButtons: false,
        confirmButtonText: `Continue`,
        cancelButtonText: `Cancel`,
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          try{
          
          closeLoanDetails(email || "");
          this.$swal({
          title: "Alert",
          text: "Email sent",
          icon: "success",
        });
          }catch(error){
            this.$swal({
              title: "Alert",
              text: "Loan could not be closed.",
              icon: "info",
            });
          }
        } else if (result.isDenied) {
        return false;
         }
})
    },
    //check: function(e: any) {
    onCheckboxInput(e: any , checkboxName: string , titleLabel: string , id: string , status: string) {
      // if (this.errors[checkboxName]) {
      //   this.errors[checkboxName] = null;
      // }
      if(this.collectionData != null && e.target.checked == false){
        let index = 0;
        for(const x in this.collectionData){
          if(index != 0){
          if(checkboxName === this.collectionData[index]){
            this.collectionData.splice(index);
          }
          }
          index = index + 1;
        }
        localStorage.setItem('collectionData', JSON.stringify(this.collectionData));

      }
      if(e.target.checked == true){
        this.collectionData.push(checkboxName);
        localStorage.setItem('collectionData', JSON.stringify(this.collectionData));
      }
      //this.collectionsData = this.collectionData;
//       if(!titleLabel.includes("Collections")){
//          if(status != 'paid' && status != 'closed'){
//               try{
      
//       let titleString = 'Do you want to move this loan to collections';
//       if(titleLabel.includes("Collections")){
//         titleString = "Do you want to move this loan out of collections";
//       }
//       this.$swal.fire({
//         title: titleString,
//         showCancelButton: true,
//         icon: "info",
//         reverseButtons: false,
//         confirmButtonText: `Yes`,
//         cancelButtonText: `Cancel`,
//         }).then(async (result) => {
//         if (result.isConfirmed) {
          
//           try{
          
//           const moveResult = await moveLoanCollections(checkboxName, titleLabel);
//           console.log("Confirm moveResult" + JSON.stringify(moveResult));
//           this.$router.go(0);
//           }catch(error){
//             console.log("Error");
//             this.$swal({
//           title: "Alert",
//           text: "Loan cannot be moved",
//           icon: "info",
//           confirmButtonText: `Ok`,
//       }).then(async (result) => {
//         if(result.isConfirmed){
//           this.$router.go(0);
//         }
//       });
      
//           }
//         } else if (result.isDismissed) {

//         //this.$router.go(0) 
//         return false;
//          }
// })
//       }catch(error){

//         this.$swal({
//           title: "Alert",
//           text: "Loan cannot be moved to collections",
//           icon: "info",
//       });
//     }
//       }
//       else{
//            this.$swal({
//           title: "Alert",
//           text: "Loan cannot be moved to collections",
//           icon: "info",
//           confirmButtonText: `Ok`,
//       }).then(async (result) => {
//         if(result.isConfirmed){
//           this.$router.go(0);
//         }
//       });
//       }
//       }
     

    },

    async customerEdit(email : string) {
             this.$swal.fire({
        title: 'Would you like to send the update link to customer',
        showCancelButton: true,
        icon: "info",
        reverseButtons: false,
        confirmButtonText: `Continue`,
        cancelButtonText: `Cancel`,
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          try{
          
          updateCustomerDetails(email || "");
          this.$swal({
          title: "Alert",
          text: "Email sent",
          icon: "success",
        });
          }catch(error){
            this.$swal({
              title: "Alert",
              text: "Could not send email",
              icon: "error",
            });
          }
        } else if (result.isDenied) {
        return false;
         }
})
    },
  },

  props: {
    title: {
      required: false,
      default: null,
      type: String,
    },
    screenTitle: {
      required: false,
      default: null,
      type: String,
    },
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
     collectionsData: {
      required: false,
      default: () => [],
      type: Array,
    },
  },
});
