
import Vue from "vue";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import Loader from "@/components/primitives/Loader.vue";
import { errorHandler } from "@/api/error-handler";
import { getUserData } from "@/admin-dashboard/authentication/helpers";
import { getAdminRoles } from "@/admin-dashboard/helpers";
import UploadProofOfIncomeDocument from "@/views/admin/UploadProofOfIncomeDocument.vue";
import ManualFundButton from "@/components/buttons/ManualFundButton.vue";

export default Vue.extend({
  components: { UploadProofOfIncomeDocument, Loader, ManualFundButton },
  props: ["screenTrackingId"],
  data() {
    return {
      phoneTypesList: ["Cell", "Residence", "Work"],
      selectedPhoneType: "Cell",
      userData: null as any,
      userJWTData: getUserData(),
      adminRoles: getAdminRoles(),
      isUserEdit: {
        email: false,
        phoneNumber: false,
        username: false,
        address: false,
        assignedLoanAmount: false,
        requestedLoanAmount: false,
        annualIncome: false,
        maxLoanAmount: false,
        state: false,
      },
      paymentManagement: null as any,
      updatedData: {
        email: null,
        phoneNumber: null,
        phones: [],
        firstName: null,
        lastName: null,
        state: null,
        street: null,
        city: null,
        zipCode: null,
        assignedLoanAmount: null,
        requestedLoanAmount: null,
        annualIncome: null,
        maxLoanAmount: null,
      },
      action: "",
      isLoading: false,
      isSubmitting: false,
      isProofOfIncomeModal: false,
      isComminicationStatusSending: false,
      isBlockListLoading: false,
      hasUserInDnlList: {
        exist: null,
        reason: null,
      },
      isMaxLoanAmountLoading: false,
      fundedStatusList: [
        "in-repayment non-prime",
        "in-repayment prime",
        "in-repayment",
        "in-repayment delinquent1",
        "in-repayment delinquent2",
        "in-repayment delinquent3",
        "in-repayment delinquent4",
        "paid",
      ],
      disabledStatusList: ["canceled", "expired", "paid", "fraud", "denied"],
      statesListOptions: [
        { value: "AK", text: "AK - Alaska" },
        { value: "AL", text: "AL - Alabama" },
        { value: "AZ", text: "AZ - Arizona" },
        { value: "CA", text: "CA - California" },
        { value: "CO", text: "CO - Colorado" },
        { value: "DE", text: "DE - Delaware" },
        { value: "FL", text: "FL - Florida" },
        { value: "HI", text: "HI - Hawaii" },
        { value: "IA", text: "IA - Iowa" },
        { value: "ID", text: "ID - Idaho" },
        { value: "IL", text: "IL - Illinois" },
        { value: "IN", text: "IN - Indiana" },
        { value: "KS", text: "KS - Kansas" },
        { value: "KY", text: "KY - Kentucky" },
        { value: "LA", text: "LA - Louisiana" },
        { value: "MA", text: "MA - Massachusetts" },
        { value: "ME", text: "ME - Maine" },
        { value: "MI", text: "MI - Michigan" },
        { value: "MN", text: "MN - Minnesota" },
        { value: "MO", text: "MO - Missouri" },
        { value: "MS", text: "MS - Mississippi" },
        { value: "MT", text: "MT - Montana" },
        { value: "ND", text: "ND - North Dakota" },
        { value: "NE", text: "NE - Nebraska" },
        { value: "NH", text: "NH - New Hampshire" },
        { value: "NM", text: "NM - New Mexico" },
        { value: "NV", text: "NV - Nevada" },
        { value: "OH", text: "OH - Ohio" },
        { value: "OK", text: "OK - Oklahoma" },
        { value: "OR", text: "OR - Oregon" },
        { value: "SD", text: "SD - South Dakota" },
        { value: "TN", text: "TN - Tennessee" },
        { value: "TX", text: "TX - Texas" },
        { value: "UT", text: "UT - Utah" },
        { value: "WA", text: "WA - Washington" },
        { value: "WI", text: "WI - Wisconsin" },
        { value: "WY", text: "WY - Wyoming" },
      ],
    };
  },
  methods: {
    isNumber(evt: KeyboardEvent, id: string) {
      if (
        [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "backspace",
          "delete",
          "arrowleft",
          "arrowright",
        ].indexOf(evt.key.toLowerCase()) === -1
      ) {
        evt.preventDefault();
        return false;
      }
      if (id !== "") {
        setTimeout(() => {
          document.getElementById(id)?.focus();
        }, 50);
      }
      return true;
    },
    async onUpdateUserInfo(
      e: any,
      fieldName:
        | "email"
        | "phoneNumber"
        | "firstName"
        | "lastName"
        | "street"
        | "city"
        | "state"
        | "zipCode"
        | "phoneNumberType"
        | "assignedLoanAmount"
        | "requestedLoanAmount"
        | "annualIncome"
        | "maxLoanAmount",
    ) {
      if (fieldName === "phoneNumberType") {
        this.selectedPhoneType = e.target.value;
        return;
      }
      this.$set(this.updatedData, fieldName, e.target.value);
      return null;
    },
    async onSaveAssignedLoanAmount() {
      const newAmount = Number(this.updatedData.assignedLoanAmount);
      if (newAmount <= 0) {
        await this.$swal({
          title: "Error",
          text: "Invalid amount",
          icon: "error",
        });
        return;
      }
      if (newAmount > this.userData.offerData[0].maxLoanAmount) {
        await this.$swal({
          title: "Error",
          text: "Assigned amount shouldn't be more than max loan amount",
          icon: "error",
        });
        return;
      }

      try {
        await adminDashboardRequests.updateAssignedLoanAmount(
          this.screenTrackingId,
          newAmount,
        );
        await this.$swal({
          title: "Success",
          text: "Assigned amount updated",
          icon: "success",
        });
        this.isUserEdit.assignedLoanAmount = false;
        await this.getScreenData();
        return null;
      } catch (error) {
        console.log("ERROR::", " ", error);
        const errMsg = error?.response?.data?.message;
        const errCode = error?.response?.data?.statusCode;
        await this.$swal({
          title: "Error",
          text: `${errMsg}`,
          icon: "error",
        });
      }
    },
    async onSaveRequestedLoanAmount() {
      const newAmount = Number(this.updatedData.requestedLoanAmount);
      if (newAmount <= 0) {
        await this.$swal({
          title: "Error",
          text: "Invalid amount",
          icon: "error",
        });
        return;
      }
      if (newAmount > this.userData.offerData[0].maxLoanAmount) {
        await this.$swal({
          title: "Error",
          text: "Requested amount shouldn't be more than max loan amount",
          icon: "error",
        });
        return;
      }

      try {
        const response = await adminDashboardRequests.updateRequestedLoanAmount(
          this.screenTrackingId,
          newAmount,
        );
        await this.$swal({
          title: "Success",
          text: "Requested amount updated",
          icon: "success",
        });
        this.isUserEdit.requestedLoanAmount = false;
        await this.getScreenData();
        return null;
      } catch (error) {
        console.log("ERROR::", " ", error);
        const errMsg = error?.response?.data?.message;
        const errCode = error?.response?.data?.statusCode;
        await this.$swal({
          title: "Error",
          text: `${errMsg}`,
          icon: "error",
        });
      }
    },
    async onSaveUpdatedInfo(fieldName: string) {
      try {
        const isEmpty = Object.values(this.updatedData).find((item) => !item);
        if (isEmpty) {
          await this.$swal({
            title: "Error",
            text:
              fieldName === "email"
                ? `Verification Email has been sent to ${this.updatedData.email}`
                : "Updated Successfully",
            icon: "error",
          });
        }
        if (fieldName === "email") {
          /* eslint-disable-next-line */
          const emailRegexp = new RegExp("[a-z0-9]+@[a-z]+\.[a-z]{2,3}");
          if (!emailRegexp.test(this.updatedData.email!)) {
            await this.$swal({
              title: "Error",
              text: "Entered mail is not valid",
              icon: "error",
            });
            return null;
          }
        }
        const payload: any = { screenTrackingId: this.screenTrackingId };
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          state,
          street,
          zipCode,
          city,
        } = this.updatedData;

        switch (fieldName) {
          case "email": {
            if (email && email !== this.userData?.email) {
              this.isUserEdit.email = false;
              payload["secondaryEmail"] = this.updatedData.email;
              break;
            }
            return;
          }
          case "phoneNumber": {
            if (this.action === "add") {
              if (phoneNumber && phoneNumber !== this.userData?.phoneNumber) {
                this.isUserEdit.phoneNumber = false;
                payload["phoneNumber"] = this.updatedData.phoneNumber;
                payload["phoneNumberType"] = this.selectedPhoneType;
                this.userData?.phones?.push({
                  phone: phoneNumber,
                  type: this.selectedPhoneType,
                });
                break;
              }
            } else {
              if (
                this.updatedData.phones.length &&
                new Set(this.updatedData.phones).size ===
                  this.updatedData.phones.length
              ) {
                this.isUserEdit.phoneNumber = false;
                payload["phones"] = this.updatedData.phones;
                break;
              }
            }
            return;
          }
          case "username": {
            if (!firstName || !lastName) return;
            const username = `${firstName} ${lastName}`;
            if (username && username !== this.userData?.name) {
              this.isUserEdit.username = false;
              payload["firstName"] = firstName;
              payload["lastName"] = lastName;
              payload["name"] = username;
              break;
            }
            return;
          }
          case "address": {
            payload["state"] = state || this.userData.state;
            payload["zipCode"] = zipCode || this.userData.zipCode;
            payload["street"] = street || this.userData.street;
            payload["city"] = city || this.userData.city;
            this.isUserEdit.address = false;
            break;
          }
          case "state": {
            payload["state"] = state || this.userData.state;
            this.isUserEdit.state = false;
            break;
          }
          default:
            break;
        }
        const response = await adminDashboardRequests.updateUserData(payload);
        delete payload.screenTrackingId;
        this.userData = { ...this.userData, ...payload };
        await this.$swal({
          title: "Success",
          text:
            fieldName === "email"
              ? `Verification Email has been sent to ${this.updatedData.email}`
              : response.data.message,
          icon: "success",
        });
        return null;
      } catch (error) {
        console.log("ERROR::", " ", error);
        const errMsg = error?.response?.data?.message;
        const errCode = error?.response?.data?.statusCode;
        await this.$swal({
          title: "Error",
          text: `${errMsg}`,
          icon: "error",
        });
      }
    },
    async launchProofOfIncomeModal() {
      if (Number(this.updatedData?.annualIncome) > 0) {
        this.isProofOfIncomeModal = true;
      } else {
        await this.$swal({
          title: "Error",
          text: "Annual income should be greater than 0",
          icon: "error",
        });
      }
    },
    async updateAnnualIncome() {
      try {
        const response = await adminDashboardRequests.updateAnnualIncome({
          annualIncome: this.updatedData.annualIncome,
          screenTrackingId: this.screenTrackingId,
        });
        this.hideModal();
        this.$set(this.userData, "annualIncome", this.updatedData.annualIncome);
        this.$set(
          this.userData,
          "monthlyIncome",
          (Number(this.updatedData.annualIncome) / 12).toFixed(2),
        );
        this.$set(this.isUserEdit, "annualIncome", false);
        this.$set(this.updatedData, "annualIncome", null);
        await this.$swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
        });
      } catch (error) {
        await this.$swal({
          title: "Failure",
          text: error.message,
          icon: "error",
        });
      }
    },
    async updateMaxLoanAmount(index = 0) {
      try {
        this.isMaxLoanAmountLoading = true;
        if (
          Number(this.updatedData.maxLoanAmount) ===
          Number(this.userData.offerData[index].maxLoanAmount)
        ) {
          await this.$swal({
            title: "Failure",
            text: "Amount has not been changed",
            icon: "error",
          });
          return;
        } else if (Number(this.updatedData.maxLoanAmount) <= 0) {
          await this.$swal({
            title: "Failure",
            text: "Amount should be greater than 0",
            icon: "error",
          });
          return;
        }
        const response = await adminDashboardRequests.updateMaxLoanAmount({
          maxLoanAmount: this.updatedData.maxLoanAmount,
          screenTrackingId: this.screenTrackingId,
          index,
        });
        await this.$swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
        });
        this.isUserEdit.maxLoanAmount = false;
        this.isMaxLoanAmountLoading = false;
        await this.getScreenData();
        return null;
      } catch (error) {
        this.isMaxLoanAmountLoading = false;
        await this.$swal({
          title: "Failure",
          text: error.response?.data?.message || error.message,
          icon: "error",
        });
      }
    },
    hideModal() {
      this.isProofOfIncomeModal = false;
    },
    async onClickEditIcon(
      fieldName:
        | "email"
        | "username"
        | "phoneNumber"
        | "address"
        | "annualIncome"
        | "assignedLoanAmount"
        | "requestedLoanAmount"
        | "state",
      action = "edit",
    ) {
      this.action = action;
      const oldValue = this.isUserEdit[fieldName];
      this.$set(this.isUserEdit, fieldName, !oldValue);
      if (fieldName === "phoneNumber") {
        if (action === "edit") {
          this.$set(this.updatedData, "phones", this.userData.phones);
        } else {
          this.$set(this.updatedData, fieldName, null);
        }
      }
      return null;
    },
    async sendLoginLink() {
      try {
        this.isLoading = true;
        await adminDashboardRequests.sendLoginLink(this.screenTrackingId);
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: "Login link has sent",
          icon: "success",
        });
      } catch (error) {
        this.isLoading = false;
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isLoading = false;
      }
    },
    async toggleBlockList() {
      try {
        if (this.hasUserInDnlList.exist === true) {
          const result = await this.$swal({
            title: `Delete user from DNL list?`,
            showCancelButton: true,
            icon: "info",
            reverseButtons: true,
            confirmButtonText: `Confirm`,
            cancelButtonText: `Cancel`,
          });
          if (result.isConfirmed) {
            this.isBlockListLoading = true;
            await adminDashboardRequests.removeUserFromBlockList(
              this.screenTrackingId,
            );
            this.isBlockListLoading = false;
            await this.$swal({
              title: "Success!",
              text: "Successfully removed",
              icon: "success",
            });
            this.checkUserHasInBlockList();
          }
        } else {
          const reasonInput = await this.$swal({
            title: "Adding to DNL list",
            input: "text",
            inputPlaceholder: "enter the reason",
            showCloseButton: true,
          });
          if (reasonInput.isConfirmed) {
            this.isBlockListLoading = true;
            await adminDashboardRequests.addUserToBlockList(
              this.screenTrackingId,
              reasonInput.value,
            );
            this.isBlockListLoading = false;
            await this.$swal({
              title: "Success!",
              text: "Successfully added",
              icon: "success",
            });
            this.checkUserHasInBlockList();
          }
        }
      } catch (error) {
        this.isBlockListLoading = false;
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isBlockListLoading = false;
        this.checkUserHasInBlockList();
      }
    },
    async sendPasswordResetLink() {
      if (this.userData) {
        this.isSubmitting = true;
        try {
          await adminDashboardRequests.sendPasswordResetLink(
            this.userData.email,
            this.screenTrackingId,
          );
          this.isSubmitting = false;
          await this.$swal({
            title: "Success!",
            text: `An email message has been sent to ${this.userData.email}`,
            icon: "success",
          });
        } catch (error) {
          this.isSubmitting = false;
          await this.$swal({
            title: "Failure!",
            text: error.message,
            icon: "error",
          });
        }
      }
    },

    async updateCommunicationStatus(newStatus: boolean) {
      try {
        this.isComminicationStatusSending = true;
        await adminDashboardRequests.updateCommunicationStatus(
          this.screenTrackingId,
          newStatus,
        );
        this.isComminicationStatusSending = false;
        this.userData.sendCommunication = newStatus;
        await this.$swal({
          title: "Success!",
          text: "Communication status updated",
          icon: "success",
        });
      } catch (error) {
        this.isComminicationStatusSending = false;
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isComminicationStatusSending = false;
      }
    },

    async getScreenData() {
      try {
        const { data } = await adminDashboardRequests.getApplication(
          this.screenTrackingId,
        );
        const res = await adminDashboardRequests.getPaymentManagement(
          this.screenTrackingId,
        );
        this.userData = data;
        this.paymentManagement = res.data.response;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }
      }
    },
    async checkUserHasInBlockList() {
      const hasInDnlList = await adminDashboardRequests.checkUserInBlockList(
        this.screenTrackingId,
      );
      this.hasUserInDnlList = hasInDnlList.data;
    },

    async exportLoan(screenTrackingId: string) {
      const response =
        await adminDashboardRequests.exportLoan(screenTrackingId);
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const disposition = response.headers["content-disposition"];
      let filename = "";
      if (disposition && disposition.includes("attachment")) {
        const filenameMatch = disposition.match(/filename="(.+?)"/);
        if (filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      if (filename) {
        a.download = filename;
      }
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },

    async handleToggleUserAccess() {
      try {
        const result = await this.$swal({
          title: `${this.userData.isDisabled ? "Enable" : "Disable"} user?`,
          showCancelButton: true,
          icon: "info",
          reverseButtons: true,
          confirmButtonText: `Confirm`,
          cancelButtonText: `Cancel`,
        });
        if (!result.isConfirmed) {
          return;
        }
        await adminDashboardRequests.toggleUserAccess(this.screenTrackingId);
        await this.getScreenData();
        await this.$swal({
          title: "Success!",
          text: `Successfully ${
            this.userData.isDisabled ? "enabled" : "disabled"
          }`,
          icon: "success",
        });
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
  },

  async created() {
    try {
      const { data } = await adminDashboardRequests.getApplication(
        this.screenTrackingId,
      );
      const res = await adminDashboardRequests.getPaymentManagement(
        this.screenTrackingId,
      );
      this.userData = data;
      this.paymentManagement = res.data.response;

      this.checkUserHasInBlockList();
    } catch (error) {
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },
});
