
import Vue from "vue";

import Layout from "@/user-application/layout/pages/Layout.vue";
import { forgotPassword } from "@/user-application/authentication/api";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  components: {
    Layout,
  },

  data() {
    return {
      email: null as null | string,
      isSubmitting: false,
      ENV: process.env,
    };
  },
  methods: {
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: boolean | null;
    }) {
      return validated ? valid : null;
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;
        await forgotPassword(this.email || "");

        await this.$swal({
          title: "Success!",
          text: `An email message has been sent to ${this.email}`,
          icon: "success",
        });

        await this.$router.push({ name: "userLogin" });
        this.isSubmitting = false;
      } catch (error) {
        if (error.response?.status !== 400) {
          this.isSubmitting = false;

          let errorMessage = "";
          if (error.response?.data?.error) {
            errorMessage = error.response.data.error;
          } else if (error.response?.data?.message) {
            errorMessage = error.response.data.message;
          } else {
            errorMessage = error.message;
          }

          showErrorToast(this, "error", errorMessage);
        }
      }
    },
  },
});
