
import Vue from "vue";

import { getUserData } from "@/admin-dashboard/authentication/helpers";
import { getAdminRoles } from "@/admin-dashboard/helpers";

export default Vue.extend({
  data() {
    return {
      userData: getUserData(),
      adminRoles: getAdminRoles(),
    };
  },
});
