
import { adminDashboardRequests } from "@/api/admin-dashboard";
import Vue from "vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
// import MakePaymentButton from "@/components/buttons/MakePaymentButton.vue";
import { errorHandler } from "@/api/error-handler";
import { getUserData } from "@/admin-dashboard/authentication/helpers";
import { getAdminRoles } from "@/admin-dashboard/helpers";
import timeValuez from "@/helpers/selecttime";
import PromisePayButton from "@/components/buttons/PromisePayButton.vue";
import RemovePromiseButton from "@/components/buttons/RemovePromiseButton.vue";
import EditPromiseButton from "@/components/buttons/EditPromiseButton.vue";
//import { updateCustomerDetails , closeLoanDetails, partialReturnDetails } from "@/user-application/authentication/api";

export default Vue.extend({
  components: { Datepicker, PromisePayButton, RemovePromiseButton, EditPromiseButton },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      adminRoles: getAdminRoles(),
      isInRepayment: false,
      isPaid: false,
      isClosed: false,
      paymentManagement: [] as any,
      userData: getUserData(),
      applicationData: [] as any,
      promiseDate: new Date(),
      promiseTime: new Date(),
      email: null as null | string,
      userName: null as null | string,
      collectionAdmin: null as null | string,
      amount: 0 as null | number,
      selectedState: null as null | string,
      openDate: new Date(),
      disabledDates: {
        to: moment().startOf("day").toDate(),
        from: moment().add(1, "months").startOf("day").toDate(),
      },
      documentTypes: [
        {
          value: null,
          text: "Select Document Type",
        },
        {
          value: "driversLicense",
          text: "Driver's License or ID",
        },
        {
          value: "passport",
          text: "Passport",
        },
      ],
      timeValue: timeValuez as { value: string }[],
      autopayStatus: false,
    };
  },

  async created() {
    try {
      const { data } = await adminDashboardRequests.getApplication(
        this.screenTrackingId
      );
      this.email = data.email;
      this.userName = data.name;
      this.collectionAdmin = data.collectionAdmin;
    } catch (error) {
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },

  computed: {
    paymentScheduleLeft(): any[] | undefined {
      if (this.paymentManagement && this.paymentManagement.paymentSchedule) {
        const response = this.paymentManagement.paymentSchedule.filter(
          (scheduleItem: any) => scheduleItem.status === "opened"
        );
        return response;
      }
      return undefined;
    },
    timeValues(): { value: string | null; text: string }[] {
      return this.timeValue.map((time) => {
        return {
          value: time.value,
          text: time.value,
        };
      });
    },
    assignedToUser(): string {
      
      return '';
    },
    payments(): undefined | any[] {
      const today = moment().startOf("day").toDate();
      if (this.paymentManagement && this.paymentManagement.paymentSchedule) {
        const response = this.paymentManagement.paymentSchedule.filter(
          (payment: any) =>
            payment.status === "opened" && moment(payment.date).isBefore(
            moment(today),
            "day"
          )
        );

        return response;
      }
      return undefined;
    },
  },

  methods: {
    async reloadPage() {
      try {
        const { data } = await adminDashboardRequests.getPaymentManagement(
          this.screenTrackingId
        );
        this.paymentManagement = data.response;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }
      }
    },
    todayDate(dates: Date): string {
      return moment(dates).format("MM/DD/YYYY");
    },
    getTime(dates: Date): string {
      return new Date(dates).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    },
    getPaymentid(): string{
      return this.paymentManagement._id || "";
    },
    getValidationState({ validated, valid = null }: any) {
      return validated ? valid : null;
    },
    async complete(promise: Record<string, any>){
       try{
      const result = await this.$swal({
        title: `Promise to Pay Completed for <br> $${promise.contactScheduleReminderAmount} on ${ this.todayDate(promise.contactScheduleReminderDate) } at ${ this.getTime(promise.contactScheduleReminderDate) } ?`,
        showCancelButton: true,
        icon: "info",
        reverseButtons: true,
        confirmButtonText: `Confirm`,
        cancelButtonText: `Cancel`,
      });
      if(result.isConfirmed) {
        await adminDashboardRequests.changeStatusPromisetopay({
          paymentId: this.paymentManagement._id,
          promiseScheduleDate: promise.contactScheduleReminderDate,
          promiseScheduleStatus: "Done",
        });
        await this.$swal({
          title: "Alert",
          text: "Success!",
          icon: "success",
        });
      }
      this.reloadPage();
    }catch(error){
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
    },
    async notcompleted(promise: Record<string, any>){
      try{
      const result = await this.$swal({
        title: `Promise to Pay Incomplete for <br> $${promise.contactScheduleReminderAmount} on ${ this.todayDate(promise.contactScheduleReminderDate) } at ${ this.getTime(promise.contactScheduleReminderDate) } ?`,
        showCancelButton: true,
        icon: "info",
        reverseButtons: true,
        confirmButtonText: `Confirm`,
        cancelButtonText: `Cancel`,
      });
      if(result.isConfirmed) {
        await adminDashboardRequests.changeStatusPromisetopay({
          paymentId: this.paymentManagement._id,
          promiseScheduleDate: promise.contactScheduleReminderDate,
          promiseScheduleStatus: "Incomplete",
        });
        await this.$swal({
          title: "Alert",
          text: "Success!",
          icon: "success",
        });
      }
     this.reloadPage();
    }catch(error){
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
    },
    getStateString(): string{
      return "Create"
    }
    
  },

  async mounted() {
    try {
      const { data } = await adminDashboardRequests.getPaymentManagement(
        this.screenTrackingId
      );
      const pm = data.response;
      if (
        pm?.status === "in-repayment" ||
        pm?.status === "in-repayment prime" ||
        pm?.status === "in-repayment non-prime" ||
        pm?.status === "in-repayment delinquent1" ||
        pm?.status === "in-repayment delinquent2" ||
        pm?.status === "in-repayment delinquent3" ||
        pm?.status === "in-repayment delinquent4"
      ) {
        this.isInRepayment = true;
      } else if (pm?.status === "paid") {
        this.isInRepayment = false;
        this.isPaid = true;
      } else if (pm?.status === "closed") {
        this.isInRepayment = false;
        this.isPaid = false;
        this.isClosed = true;
      }
      this.paymentManagement = pm;
    } catch (error) {

      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },
});
