
import Vue from "vue";

import Layout from "@/user-application/layout/pages/Layout.vue";
import { refundPaymentData, getPaymentSchedule } from "@/user-application/authentication/api";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  components: {
    Layout,
  },
  data() {
    return {
      annualIncome: null as null | string,
      token: null as null | string,
      isSubmitting: false,
      paymentManagement: [] as any,
    };
  },

  computed: {
    payments(): undefined | any[] {
      if (this.paymentManagement && this.paymentManagement.paymentSchedule) {
        const response = this.paymentManagement.paymentSchedule.filter(
          (payment: any) =>
            payment.status === "paid"
        );
        
        return response;
      }
      return undefined;
    },
  },

  methods: {
    
    async onSubmit() {
      try {
        this.isSubmitting = true;
        //refundPaymentData
        const requestBody = {
          token: this.token || "",
          requestId: "",
        };
        await refundPaymentData(requestBody);
        await this.$swal({
          title: "Success!",
          text: "Refund Initiated Successfully",
          icon: "success",
        });
        await this.$router.push({ name: "userLogin" });

      } catch (error) {
        this.isSubmitting = false;

        let errorMessage = "";
        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
  async mounted() {
    this.token = this.$route.params.token;
    try {

      const { data } = await getPaymentSchedule(
        this.token
      );

      this.paymentManagement = data;
      
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }
    }
  },
});

