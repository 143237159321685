
import Vue from "vue";

import Layout from "@/user-application/layout/pages/Layout.vue";
import { login } from "@/user-application/authentication/api";
import { getApplicationData } from "@/user-application/application/api";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  components: {
    Layout,
  },

  data() {
    return {
      email: null as null | string,
      password: null as null | string,
      isSubmitting: false,
      ENV: process.env,
    };
  },
  methods: {
    onStartHereHandler(event: any) {
      window.location.href = process.env.VUE_APP_LOS_URL;
    },
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;
        const requestBody = {
          email: this.email ? this.email.toLowerCase().trim() : "",
          password: this.password || "",
        };
        const { data: jwtToken } = await login(requestBody);
        localStorage.setItem("userToken", JSON.stringify(jwtToken));

        const { data: applicationData } = await getApplicationData();
        // if (!applicationData.isCompleted) {
        // console.log("applicationData.isCompleted", applicationData)

        // await this.$router.push({ name: applicationData.lastStep });
        // } else {
        console.log("first==== pushing to user dashboard");
        await this.$router.push({ name: "userDashboard" });
        // }
      } catch (error) {
        console.log("error===", error);
        this.isSubmitting = false;

        let errorMessage = "";
        if (error?.response?.status === 401) {
          errorMessage = "Email or password invalid.";
        } else if (
          error?.response?.data?.statusCode &&
          error?.response?.data?.statusCode == 400
        ) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
});
