
import Vue from "vue";

import BaseTableSection from "@/components/Tables/base/TableSection.vue";
import CommentsTemplate from "@/admin-dashboard/loan-details/comments/components/CommentsDetailsTemplate.vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import { TableRequestEvent } from "@/types/tables";
import { errorHandler } from "@/api/error-handler";
import { getUserData } from "@/admin-dashboard/authentication/helpers";
import {  getAdminRoles } from "@/admin-dashboard/helpers";

const DEFAULT_REQUEST_PARAMS = { page: 1, perPage: 25 };

export default Vue.extend({
  components: {
    BaseTableSection,
    CommentsTemplate,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      defaultParams: DEFAULT_REQUEST_PARAMS,
      currentStatus: "",
      rows: [],
      totalRows: 0,
      userData: getUserData(),
      adminRoles: getAdminRoles(),
    };
  },

  methods: {
    async fetchTable(event: TableRequestEvent) {
      this.currentStatus = event.status as string;
      try {
        const {
          data,
        } = await adminDashboardRequests.getAllCommentsByScreenTrackingId(
          this.screenTrackingId,
          {
            ...event,
          }
        );
        const { rows, totalRows } = data;
        this.rows = rows;
        this.totalRows = totalRows;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    editEvent(data:any){
      this.$emit('editComment', data)
    }
  },
  async created() {
    await this.fetchTable({ status: "", page: 1, perPage: 25 });
  },
});
