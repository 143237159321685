
import Vue from "vue";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import { getAdminRoles } from "@/admin-dashboard/helpers";
import { getUserData } from "@/admin-dashboard/authentication/helpers";

export default Vue.extend({
  props: {
    currentEmail: {
      required: false,
      type: String,
    },
    currentPhoneNumber: {
      required: false,
      type: String,
    },
    currentUserName: {
      required: false,
      type: String,
    },
    currentRole: {
      required: false,
      type: String,
    },
    currentLocation: {
      required: false,
      type: String,
    },
    adminId: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      email: null as null | string,
      isSubmitting: false,
      modal: false,
      phoneNumber: null as null | string,
      practiceManagements: [
        {
          value: null,
          text: "Select a Location",
        },
      ],
      roles: [
        {
          value: null,
          text: "Select the Role",
        },
      ],
      selectedPracticeManagement: null as null | string,
      selectedRole: null as null | string,
      userName: null as null | string,
      userData: getUserData(),
      adminRoles: getAdminRoles(),
    };
  },

  computed: {
    parsedPhoneNumber(): string {
      const parsedNumber = this.phoneNumber?.replace(/[^0-9]/g, "") ?? "";
      return parsedNumber;
    },
  },
  methods: {
    getValidationState({
      dirty,
      validated,
      valid = null,
    }: {
      dirty: boolean;
      validated: boolean;
      valid: null | boolean;
    }) {
      return dirty || validated ? valid : null;
    },
    async saveAdmin() {
      try {
        const requestParams = {
          email: this.email || "",
          phoneNumber: this.parsedPhoneNumber || "",
          practiceManagement: this.selectedPracticeManagement || "",
          role: this.selectedRole || "",
          userName: this.userName || "",
        };
        await adminDashboardRequests.addAdmin(requestParams);

        await this.$swal({
          title: "Success!",
          text: "Admin successfully added.",
          icon: "success",
        });
        this.$router.push("/admin/manage-admin-users");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async updateAdmin(adminId: string) {
      try {
        const requestParams = {
          email: this.email || "",
          phoneNumber: this.parsedPhoneNumber || "",
          ...(this.selectedPracticeManagement && {
            practiceManagement: this.selectedPracticeManagement,
          }),
          role: this.selectedRole || "",
          userName: this.userName || "",
        };
        await adminDashboardRequests.updateAdminById(adminId, requestParams);

        const userData = getUserData();

        await this.$swal({
          title: "Success!",
          text: "Admin data successfully edited.",
          icon: "success",
        });
        if (userData && userData.id === adminId) {
          localStorage.clear();
          await this.$router.replace("/admin/login");
        } else {
          await this.$router.push("/admin/manage-admin-users");
        }
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async deleteAdmin() {
      try {
        const result = await this.$swal({
          title: `Are you sure you want to delete this user?`,
          showCancelButton: true,
          icon: "question",
          reverseButtons: false,
          confirmButtonText: `Yes`,
          cancelButtonText: `No`,
        });
        if (result.isConfirmed) {
          await adminDashboardRequests.deleteAdminById(this.adminId);

          await this.$swal({
            title: "Success!",
            text: "Admin user successfully deleted.",
            icon: "success",
          });

          await this.$router.push("/admin/manage-admin-users");
        }
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async onSubmit() {
      if (this.adminId) {
        await this.updateAdmin(this.adminId);
      } else {
        await this.saveAdmin();
      }
    },
  },
  async created() {
    try {
      const {
        data: locationsData,
      } = await adminDashboardRequests.getLocations();
      const mappedPracticeManagements = locationsData.map(
        ({ _id, location }: { _id: string; location: string }) => {
          return {
            value: _id,
            text: location,
          };
        }
      );
      this.practiceManagements = [
        ...this.practiceManagements,
        ...mappedPracticeManagements,
      ];

      const { data: rolesData } = await adminDashboardRequests.getRoles();
      const mappedRoles = rolesData.map(
        ({ roleName }: { roleName: string }) => {
          return {
            value: roleName,
            text: roleName,
          };
        }
      );
      this.roles = [...this.roles, ...mappedRoles];

      // fill fields if updating
      if (this.currentEmail) {
        this.email = this.currentEmail;
      }
      if (this.currentPhoneNumber) {
        this.phoneNumber = this.currentPhoneNumber;
      }
      if (this.currentUserName) {
        this.userName = this.currentUserName;
      }
      if (this.currentRole) {
        this.selectedRole = this.currentRole;
      }
      if (this.currentLocation) {
        this.selectedPracticeManagement = this.currentLocation;
      }
    } catch (error) {
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        await this.$swal({
          title: "Error",
          text: `${errorMessage}`,
          icon: "error",
        });
      }
    }
  },
});
