
import Vue from "vue";
import { mapState } from "vuex";

import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Steps from "../components/Steps.vue";

export default Vue.extend({
  components: {
    Header,
    Footer,
    Steps,
  },
  props: {
    steps: {
      required: false,
      type: Boolean,
    },
    login: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      isLoading: (state: any) => state.layout.isLoading,
    }),
  },
});
