
import Vue from "vue";

import Layout from "@/user-application/layout/pages/Layout.vue";
import {  updateCustomerData } from "@/user-application/authentication/api";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  components: {
    Layout,
  },
  data() {
    return {
      annualIncome: null as null | string,
      token: null as null | string,
      isSubmitting: false,
      socialSec1: null as null | string,
      socialSec2: null as null | string,
      socialSec3: null as null | string,
    };
  },

  computed:{
       // TODO refactor this
    parsedAnualIncome(): number {
      if (typeof this.annualIncome === "string") {
        return +this.annualIncome.replace(/[$,]/g, "");
      } else {
        return 0;
      }
    },
  },

  methods: {
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
     ssnInputFocus(){
      if(this.socialSec1 && this.socialSec1.length == 3){
          const ss2 =  this.$refs.socialSec2 as any;
          ss2.focus()
      }
      if(this.socialSec2 && this.socialSec2.length == 2 && this.socialSec1 && this.socialSec1.length == 3){
          const ss3 =  this.$refs.socialSec3 as any;
          ss3.focus()
      }     
    },

     getAnnualIncomeValidationState(){
      const annualIncome = this.parsedAnualIncome;
      if(!annualIncome){
        return null;
      }
      if (annualIncome < 12000) {
        
        return false;
      }
      return true;
    },
    
    async onSubmit() {
      const ssnNumber = this.socialSec1 && this.socialSec2 && this.socialSec3
              ? `${this.socialSec1}${this.socialSec2}${this.socialSec3}`
              : "";
      
      try {
        this.isSubmitting = true;
        const annualIncome1 = `${this.parsedAnualIncome}`;
        const requestBody = {
          token: this.token || "",
          ssn: ssnNumber || "",
          annualIncome: annualIncome1 || "",
          requestId: "",
        };
        await updateCustomerData(requestBody);
        await this.$swal({
          title: "Success!",
          text: "Details successfully updated.",
          icon: "success",
        });
        await this.$router.push({ name: "userLogin" });
      } catch (error) {
        this.isSubmitting = false;

        let errorMessage = "";
        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
  mounted() {
    this.token = this.$route.params.token;
  },
});

