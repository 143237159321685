
import Vue from "vue";

import BaseTableSection from "./base/TableSection.vue";
import IncompleteTemplate from "./templates/opportunities/IncompleteTemplate.vue";
import DeniedTemplate from "./templates/opportunities/DeniedTemplate.vue";
import { adminDashboardRequests } from "../../api/admin-dashboard";
import { TableRequestEvent } from "../../types/tables";
import showErrorToast from '@/helpers/errorToast';

const DEFAULT_REQUEST_PARAMS = {
  status: "all",
  page: 1,
  perPage: 25,
};

export default Vue.extend({
  components: {
    BaseTableSection,
    IncompleteTemplate,
  },
  name: "OpportunitiesTable",
  data() {
    return {
      defaultParams: DEFAULT_REQUEST_PARAMS,
      currentStatus: "",
      totalRows: 0,
      rows: [],
      navConfig: [
        {
          status: "all",
          text: "All",
        },
        {
          status: "approved",
          text: "Conditionally Approved",
        },
        {
          status: "pending",
          text: "Pending",
        },
        {
          status: "readyToFund",
          text: "Ready to Fund",
        },
        {
          status: "authPending",
          text: "Authentication Pending",
        },
        {
          status: "denied",
          text: "Denied",
        },
        {
          status: "expired",
          text: "Expired",
        },
          {
              status: "canceled",
              text: "Canceled",
          },
          {
              status: "fraud",
              text: "Fraud",
          },
      ],
    };
  },
  methods: {
    async fetchTable(event: TableRequestEvent) {
      this.currentStatus = event.status as string;
      if (event.status === "all" && event.search === "") {
        this.rows = [];
        this.totalRows = 0;
        return;
      }
      console.log("Fetching data with:", event);
      if (event.status === "all") {
        event.status = ["approved", "pending", "denied", "expired", "canceled", "fraud"];
      }

      const { data } = await adminDashboardRequests.getLoans({ ...event });
      const { items: rows, total: totalRows } = data;

      this.rows = rows;
      this.totalRows = totalRows;
    },
  },
});
