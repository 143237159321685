
import Vue from "vue";

import Layout from "../layouts/admin/Layout.vue";
import AdminsTable from "@/components/Tables/AdminsTable.vue";
import VendorsTable from "@/components/Tables/VendorsTable.vue";

export default Vue.extend({
  components: { Layout, VendorsTable },
});
