var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"admin-layout flex"},[_c('b-sidebar',{staticClass:"shadow",class:{ absolute: _vm.isMobile, relative: !_vm.isMobile },attrs:{"id":"sidebar-main","header-class":"side-nav-display-none","body-class":"side-nav-transparent","width":"260px"},model:{value:(_vm.showNav),callback:function ($$v) {_vm.showNav=$$v},expression:"showNav"}},[_c('side-bar'),(_vm.isMobile)?_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-main",modifiers:{"sidebar-main":true}}],staticClass:"background"}):_vm._e()],1),_c('div',{class:{
      'flex column grow': true,
      'nav-opened': !_vm.isMobile && _vm.showNav,
      'nav-closed': _vm.isMobile || !_vm.showNav,
    }},[_c('div',{class:{ 'layout-header': true, 'mobile-layout-header': _vm.isMobile }},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-main",modifiers:{"sidebar-main":true}}]},[_c('i',{staticClass:"material-icons menu-icon"},[_vm._v("menu")])]),_c('div',{staticClass:"flex align-center header-buttons"},[_c('ShareLinkButton'),_c('ActiveUserButton')],1)]),_c('div',{class:{
        'layout-content': true,
        'mobile-layout-content': _vm.isMobile,
      }},[_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }