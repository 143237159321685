
import Vue from "vue";
import { mapMutations } from "vuex";

import { getApplicationData } from "@/user-application/application/api";
import { uploadDocument } from "@/user-application/document-upload/api";
import Layout from "@/user-application/layout/pages/Layout.vue";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  components: {
    Layout,
  },

  data() {
    return {
      dragCount: 0,
      dragCountBack: 0,
      isUploaded: false,
      isDriversLicense: false,
      isDragging: false,
      isDraggingBack: false,
      isUploading: false,
      lastStep: null as null | string,
      isPassport: false,
      review: false,
      selectedFileBack: null as null | string,
      selectedFileBackName: null as null | string,
      selectedFileFront: null as null | string,
      selectedFileFrontName: null as null | string,
      upload: false,
    };
  },

  methods: {
    ...mapMutations(["setStep", "setIsLoading"]),
    async goToDashboard() {
      await this.$router.push({ name: "userDashboard" });
    },
    onDragEnter(e: Event) {
      e.preventDefault();

      this.dragCount++;
      this.isDragging = true;
    },
    onDragLeave(e: Event) {
      e.preventDefault();

      this.dragCount--;

      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    },
    onDragEnterBack(e: Event) {
      e.preventDefault();

      this.dragCountBack++;
      this.isDraggingBack = true;
    },
    onDragLeaveBack(e: Event) {
      e.preventDefault();

      this.dragCountBack--;
      if (this.dragCountBack <= 0) {
        this.isDraggingBack = false;
      }
    },
    onDropFront(e: DragEvent) {
      e.preventDefault();
      e.stopPropagation();

      this.isDragging = false;
      const selectedImage: File | undefined = e.dataTransfer?.files[0];
      this.selectedFileFrontName = e.dataTransfer?.files[0]?.name || "";
      if (selectedImage) {
        this.createBase64ImageFront(selectedImage);
      }
    },
    onDropBack(e: DragEvent) {
      e.preventDefault();
      e.stopPropagation();

      this.isDragging = false;
      const selectedImage = e.dataTransfer?.files[0];
      this.selectedFileBackName = e.dataTransfer?.files[0]?.name || "";
      if (selectedImage) {
        this.createBase64ImageBack(selectedImage);
      }
    },
    onFileSelectedFront(e: any) {
      const selectedImage = e.target?.files[0];
      this.selectedFileFrontName = e.target.files[0].name;
      this.createBase64ImageFront(selectedImage);
    },
    createBase64ImageFront(fileObject: File) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileFront = e.target?.result;
        this.saveImgFront(this.selectedFileFront || "");
      };
      reader.readAsDataURL(fileObject);
    },
    saveImgFront(img: string) {
      this.selectedFileFront = img;
    },
    async uploadImage() {
      try {
        this.setIsLoading(true);
        if (this.isPassport) {
          const passport = this.selectedFileFront || "";
          const passportBase64 = passport.split("base64,")[1];
          const requestBody = {
            documentType: "passport" as const,
            passport: passportBase64,
          };

          await uploadDocument(requestBody);

          this.isUploaded = true;
          this.setIsLoading(false);
        } else {
          const driversLicenseFront = this.selectedFileFront || "";
          const driversLicenseFrontBase64 =
            driversLicenseFront.split("base64,")[1];
          const driversLicenseBack = this.selectedFileBack || "";
          const driversLicenseBackBase64 =
            driversLicenseBack.split("base64,")[1];

          const requestBody = {
            documentType: "drivers license" as const,
            driversLicenseFront: driversLicenseFrontBase64,
            driversLicenseBack: driversLicenseBackBase64,
          };
          await uploadDocument(requestBody);

          this.isUploaded = true;
          this.setIsLoading(false);
        }
      } catch (error) {
        this.setIsLoading(false);

        let errorMessage = "";
        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
    onFileSelectedBack(e: any) {
      const selectedImage = e.target.files[0]; // get first file
      this.selectedFileBackName = e.target.files[0].name;
      this.createBase64ImageBack(selectedImage);
    },
    createBase64ImageBack(fileObject: File) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileBack = e.target.result;
        this.saveImgBack(this.selectedFileBack || "");
      };
      reader.readAsDataURL(fileObject);
    },
    saveImgBack(img: string) {
      this.selectedFileBack = img;
    },
    cancelUploadFront() {
      this.selectedFileFront = null;
      this.selectedFileFrontName = null;
      this.review = false;
    },
    cancelUploadBack() {
      this.selectedFileBack = null;
      this.selectedFileBackName = null;
      this.review = false;
    },
    setIdDriver() {
      this.isUploading = false;
      this.isDriversLicense = true;
      this.isPassport = false;
    },
    setPassport() {
      this.isUploading = false;
      this.isPassport = true;
      this.isDriversLicense = false;
    },
    returnToUpload() {
      this.isUploading = false;
      this.isPassport = false;
      this.isDriversLicense = false;
      this.upload = false;
    },
    setUpload() {
      this.upload = true;
    },
    setReview() {
      this.review = true;
    },
  },

  async created() {
    try {
      this.setStep(6);
      const { data } = await getApplicationData();
      const { isCompleted, lastStep } = data;

      if (isCompleted) {
        await this.$router.push({ name: "userDashboard" });
      } else if (lastStep !== "document-upload") {
        await this.$router.push({ name: lastStep });
      } else {
        this.lastStep = lastStep;
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await this.$router.push({ name: "userLogin" });
      } else {
        showErrorToast(this, "error", error.message);
      }
    }
  },
});
