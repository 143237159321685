
import Vue from "vue";

import Card from "./primitives/Card.vue";

export default Vue.extend({
  components: { Card },
  props: {
    status: { required: true, type: String },
    count: { required: true, type: Number },
    link: { required: false, type: String },
  },
  methods: {
  },
});
