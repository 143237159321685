
import Vue from "vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { adminDashboardRequests } from "@/api/admin-dashboard";

export default Vue.extend({
  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  components: {
    VueJsonPretty,
  },

  data() {
    return {
      transData: null,
      clarityReportData: [],
      clarityClearInquiry: null,
      clarityClearInquiry18: null,
      clarityClearCredit: null,
      clarityFraudInsight: null,
      clarityBankCall: null,
      flinksAccountDetail: null,
      factorTrustData: null,
      clarityLeadReport: "",
    };
  },

  methods: {
    getTotalIncome(data: any) {
      const { sum_employer_income, account_age_days } = data;
      console.log(
        "sum_employer_income, account_age_days",
        sum_employer_income,
        account_age_days
      );
      const flinksTotalIncome = sum_employer_income * (365 / account_age_days);
      return flinksTotalIncome.toFixed(2);
    },
  },

  async mounted() {
    const { data, status } = await adminDashboardRequests.getCreditReport(
      this.screenTrackingId
    );
    this.transData = data.creditInfo.tradeInfo;
    this.factorTrustData = data.factorTrust?.IDV;

    const clarityResponse = await adminDashboardRequests.getClarityReport(
      this.screenTrackingId
    );
    this.clarityReportData = clarityResponse.data;

    this.clarityReportData.filter((item: any, index) => {
      if (!item.lead) {
        if (item.ruleNumber === 5) this.clarityClearInquiry = item;
        if (item.ruleNumber === 8) this.clarityClearCredit = item;
        if (item.ruleNumber === 18) this.clarityClearInquiry18 = item;
        if (item.ruleNumber === 19) this.clarityFraudInsight = item;
        if (item.ruleNumber === 20) this.clarityBankCall = item;
        if (item.ruleNumber === 17) this.flinksAccountDetail = item;
      } else {
        this.clarityLeadReport = item.raw;
      }
    });
  },
});
