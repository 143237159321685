
import Vue from "vue";

import BaseTable from "@/components/Tables/base/Table.vue";

export default Vue.extend({
  components: { BaseTable },

  props: {
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
  },

  data() {
    return {
      // fields: [
      //   { key: "logReference", label: "Log Reference" },
      //   { key: "moduleName", label: "Module Name" },
      //   { key: "message", label: "Message" },
      //   { key: "dateCreated", label: "Created Date" },
      // ],
    };
  },
});
