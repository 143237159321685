
import Vue from "vue";

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { changePaymentAmount } from "@/user-application/dashboard/api";
import { errorHandler } from "@/api/error-handler";

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 4,
});

export default Vue.extend({
  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
    regularAmount: {
      required: true,
      type: Number,
    },
    promoAmount: {
      required: false,
      type: Number,
    },
    currentAmount: {
      required: true,
      type: Number,
    },
    payoffAmount: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      amount: "" as number | string,
      error: "",
      isLoading: false,
      isPromoAmount: false,
      isRegularAmount: false,
      isValidAmount: true,
      mask: currencyMask,
      modal: false,
    };
  },

  computed: {
    amountToNumber(): number {
      if (typeof this.amount === "string") {
        const newAmount = parseFloat(this.amount.replace(/[$,]/g, ""));
        return newAmount;
      }

      return this.amount;
    },
  },

  methods: {
    onChangeAmount() {
      this.modal = true;
      this.isRegularAmount = true;
      this.amount = "" + this.regularAmount;
      if (this.currentAmount == this.regularAmount) {
        this.isRegularAmount = true;
        this.isPromoAmount = false;
        this.amount = "" + this.regularAmount;
      } else {
        this.isPromoAmount = true;
        this.isRegularAmount = false;
        this.amount = "" + this.promoAmount;
      }
    },
    onCancel() {
      this.isValidAmount = true;
      this.error = "";
      //this.amount = "" + this.regularAmount;
      this.isLoading = false;
      this.modal = false;
    },
    setPaymentAmount(loanType: string) {
      if (loanType === "regular") {
        this.isRegularAmount = true;
        this.isPromoAmount = false;
        this.amount = "" + this.regularAmount;
      } else {
        this.isRegularAmount = false;
        this.isPromoAmount = true;
        this.amount =
          this.payoffAmount < this.promoAmount
            ? "" + this.payoffAmount
            : "" + this.promoAmount;
      }
    },
    onAmountBlur(event: any) {
      const { value } = event.target;
      if (this.error) {
        this.error = "";
      }

      if (typeof value === "string") {
        const parsedAmount = parseFloat(value.replace(/[$,]/g, ""));
        this.isValidAmount =
          parsedAmount < this.regularAmount || parsedAmount > this.payoffAmount
            ? false
            : true;
        if (!this.isValidAmount) {
          this.error = `Amount must be higher than $${Number(this.regularAmount).toFixed(2)} and lower than $${Number(this.payoffAmount).toFixed(2)}`;
        }
      } else {
        this.isValidAmount =
          value < this.regularAmount || value > this.payoffAmount
            ? false
            : true;
        if (!this.isValidAmount) {
          this.error = `Amount must be higher than $${Number(this.regularAmount).toFixed(2)} and lower than $${Number(this.payoffAmount).toFixed(2)}`;
        }
      }
    },
    onAmountKeyDown() {
      this.isRegularAmount = false;
      this.isPromoAmount = false;
    },
    async onSubmit() {
      if (this.amountToNumber === this.currentAmount) {
        this.error = "Payment amount has not changed";
        return;
      }
      const amount = this.amountToNumber;
      try {
        this.isLoading = true;
        const requestBody = {
          screenTracking: this.screenTrackingId,
          amount: amount
        };
        await changePaymentAmount(requestBody);
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: "Payment amount successfully changed.",
          icon: "success",
        });

        this.error = "";
        this.isRegularAmount = true;
        this.isPromoAmount = false;
        this.modal = false;
        this.$emit("reloadPage");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }

        this.error = "";
        this.isLoading = false;
        this.modal = false;
      }
    },
  },
});
