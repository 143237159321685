
import Vue from "vue";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import { getUserData } from "@/admin-dashboard/authentication/helpers";
import { isCardExpired, getAdminRoles } from "@/admin-dashboard/helpers";
export default Vue.extend({
  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      selectedDocumentType: null,
      documentTypes: [
        {
          value: null,
          text: "Select Document Type",
        },
        {
          value: "driversLicense",
          text: "Driver's License or ID",
        },
        {
          value: "passport",
          text: "Passport",
        },
        {
          value: "paystub",
          text: "Pay Stub",
        },
        {
          value: "proofOfResidence",
          text: "Proof of Residence",
        },
        {
          value: "other",
          text: "Others",
        },
      ],
      fileFront: null as null | File,
      fileFrontBase64: null as null | string,
      fileFrontName: null as null | string,
      fileBack: null as null | File,
      fileBackBase64: null as null | string,
      fileBackName: null as null | string,
      otherDoc: null as null | string,
      otherDocTitle: null as null | string,
      isLoading: false,
      userDocuments: [],
      userConsents: [],
      userData: getUserData(),
      adminRoles: getAdminRoles(),
      userEmail: "",
    };
  },

  // computed: {
  //   documentDate(date) {
  //     var updateDate = new Date(date);
  //     return (
  //       (updateDate.getMonth() > 8
  //         ? updateDate.getMonth() + 1
  //         : '0' + (updateDate.getMonth() + 1)) +
  //       '/' +
  //       (updateDate.getDate() > 9
  //         ? updateDate.getDate()
  //         : '0' + updateDate.getDate()) +
  //       '/' +
  //       updateDate.getFullYear()
  //     );
  //   },
  // },

  methods: {
    async onClickGenerateContract() {
      try {
        this.isLoading = true;
        await adminDashboardRequests.generateAgreement(this.screenTrackingId);
        await this.loadUserConsents();
        this.isLoading = false;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isLoading = false;
      }
    },
    async onClickGenerateStatement() {
      try {
        this.isLoading = true;
        await adminDashboardRequests.generateStatement(this.screenTrackingId);
        await this.loadUserConsents();
        this.isLoading = false;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isLoading = false;
      }
    },
    onOtherDocTitleChange(e: any) {
      const value = e.target.value;
      this.otherDocTitle = value;
    },

    getValidationState({ validated, valid = null }: any) {
      return validated ? valid : null;
    },
    onFileFrontSelected(event: any) {
      const selectedImage =
        event.dataTransfer?.files[0] || event.target?.files[0];
      this.fileFrontName =
        event.dataTransfer?.files[0].name || event.target?.files[0].name;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileFrontBase64 = (event.target?.result as string).split(
          "base64,"
        )[1];
      };
      reader.readAsDataURL(selectedImage);
    },
    onFileBackSelected(event: any) {
      const selectedImage =
        event.dataTransfer?.files[0] || event.target?.files[0];
      this.fileBackName =
        event.dataTransfer?.files[0].name || event.target?.files[0].name;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileBackBase64 = (event.target?.result as string).split(
          "base64,"
        )[1];
      };
      reader.readAsDataURL(selectedImage);
    },
    async loadUserDocuments() {
      try {
        const { data } = await adminDashboardRequests.getUserDocuments(
          this.screenTrackingId
        );
        this.userDocuments = data;
      } catch (error) {
        if (error.response.status === 404) {
          return;
        }

        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }
      }
    },
    async loadUserConsents() {
      const consents = await adminDashboardRequests.getUserConsents(
        this.screenTrackingId
      );
      this.userConsents = consents.data;
      let i = 0;
      for (const x in this.userConsents) {
        const resp: any = this.userConsents[i];
        const DateObj = new Date(resp.createdAt);
        const dates = DateObj.getDate();
        const months = DateObj.getMonth();
        const years = DateObj.getFullYear();
        resp.createdAt = `${months}/${dates}/${years}`;
        i++;
      }
    },
    cleanState() {
      this.selectedDocumentType = null;
      this.fileFront = null;
      this.fileFrontBase64 = null;
      this.fileFrontName = null;
      this.fileBack = null;
      this.fileBackBase64 = null;
      this.fileBackName = null;

      this.$nextTick(() => {
        (this.$refs.observer as any).reset();
      });
    },
    async onSubmit() {
      this.isLoading = true;
      const requestBody: any = {};
      if (this.selectedDocumentType === "passport") {
        requestBody.documentType = "passport";
        requestBody.passport = this.fileFrontBase64;
      } else if (this.selectedDocumentType === "driversLicense") {
        requestBody.documentType = "drivers license";
        requestBody.driversLicenseFront = this.fileFrontBase64;
        requestBody.driversLicenseBack = this.fileBackBase64;
      } else if (this.selectedDocumentType === "other") {
        requestBody.documentType = "otherDoc";
        requestBody.otherDocTitle = this.otherDocTitle;
        this.$set(requestBody, "otherDoc", this.fileFrontBase64);
      } else {
        requestBody.documentType = this.selectedDocumentType;
        this.$set(requestBody, requestBody.documentType, this.fileFrontBase64);
        // requestBody = this.fileFrontBase64;
      }

      try {
        await adminDashboardRequests.uploadDocument(
          this.screenTrackingId,
          requestBody
        );

        this.cleanState();
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: "Document(s) uploaded successfully.",
          icon: "success",
        });

        await this.loadUserDocuments();
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    try {
      const result = await Promise.all([
        this.loadUserDocuments(),
        this.loadUserConsents(),
      ]);

      const { data: user } = await adminDashboardRequests.getApplication(
        this.screenTrackingId
      );
      this.userEmail = user.email;
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }

      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },

  // documentDate(date) {
  //   console.log('Date: ', date);
  //   const updateDate = new Date(date);
  //   return (
  //     (updateDate.getMonth() > 8
  //       ? updateDate.getMonth() + 1
  //       : '0' + (updateDate.getMonth() + 1)) +
  //     '/' +
  //     (updateDate.getDate() > 9
  //       ? updateDate.getDate()
  //       : '0' + updateDate.getDate()) +
  //     '/' +
  //     updateDate.getFullYear()
  //   );
  // },
});
