import { render, staticRenderFns } from "./ResetPassword.vue?vue&type=template&id=f8f966b2&scoped=true&"
import script from "./ResetPassword.vue?vue&type=script&lang=ts&"
export * from "./ResetPassword.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/bootstrap/css/bootstrap.min.css?vue&type=style&index=0&id=f8f966b2&prod&scoped=true&lang=css&"
import style1 from "../../../public/assets/styles/css/AdminLTE.min.css?vue&type=style&index=1&id=f8f966b2&prod&scoped=true&lang=css&"
import style2 from "../../assets/styles/css/material-dashboard.css?vue&type=style&index=2&id=f8f966b2&prod&scoped=true&lang=css&"
import style3 from "../../assets/styles/css/MySkin.css?vue&type=style&index=3&id=f8f966b2&prod&scoped=true&lang=css&"
import style4 from "../../assets/styles/css/frontend/v3/layout.css?vue&type=style&index=4&id=f8f966b2&prod&scoped=true&lang=css&"
import style5 from "./ResetPassword.vue?vue&type=style&index=5&id=f8f966b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f966b2",
  null
  
)

export default component.exports