
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import Layout from "@/user-application/layout/pages/Layout.vue";

import { getApplicationData } from "@/user-application/application/api";
import { getOffers, saveOffer } from "@/user-application/offers/api";

import showErrorToast from "@/helpers/errorToast";
import IOffer from "../types/IOffer";

const amountRequestedMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 4,
});

export default Vue.extend({
  components: {
    Layout,
  },

  data() {
    return {
      approvedUpTo: null as null | number,
      isSubmitting: false,
      minimumRequestAmount: null as null | number,
      offers: [] as any[],
      promo: false,
      amountRequested: null as null | string,
      amountRequestedMask,
      selectedOffer: {} as Record<string, unknown>,
      selectedOffer1: {} as Record<string, unknown>,
      selectedOfferId: null as null | string,
      selectedTerm: null as null | number,
      titles: ["StretchPlan", "PlusPlan", "SpeedPlan"],
    };
  },

  computed: {
    ...mapState({
      requestedAmount: (state: any) => state.user.requestedAmount,
    }),
    parsedAmountRequested(): number {
      if (typeof this.amountRequested === "string") {
        return +this.amountRequested.replace(/[$,]/g, "");
      } else {
        return 0;
      }
    },
    getRequestedAmountValidationState() {
      const requestedAmount = this.parsedAmountRequested;
      if (!(this.minimumRequestAmount && this.approvedUpTo)) {
        return null;
      }
      if (
        requestedAmount < this.minimumRequestAmount ||
        requestedAmount > this.approvedUpTo
      ) {
        return false;
      }

      return true;
    },
  },

  methods: {
    ...mapMutations(["setStep", "setIsLoading"]),
    async getOffersLayout(requestedLoanAmount?: number) {
      
      if (!requestedLoanAmount) {
        requestedLoanAmount = this.parsedAmountRequested;
      }
      if(requestedLoanAmount && requestedLoanAmount.toString().length == 4){
      try {
        const { data } = await getOffers(requestedLoanAmount);
        const { approvedUpTo, minimumAmount, offers , status } = data;
        // const offers1 :any[] = [];
        // let x = 0;
        // if(offers.length > 0){
        //   for (x = 0; x < offers.length; x++){
        //   if(x == 0){
        //     offers1.push(offers[x]);
        //   }
        //   else{
        //     if(offers1[x - 1].term == offers[x].term){
        //       console.log("");
        //     }else{
        //       offers1.push(offers[x]);
        //     }
        //   }
        // }
        // }
        
        if (status === "declined") {
          await this.$router.push({ name: "denied" });
        } else {
          const parsedRequestAmount = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          })
            .format(requestedLoanAmount)
            .replace(/[$USD]/g, "");
          this.amountRequested = parsedRequestAmount;
          this.approvedUpTo = approvedUpTo;
          this.minimumRequestAmount = minimumAmount;
          this.offers = offers
            .slice()
            .reverse()
            .map(
              (
                offer: IOffer & { showOffers: boolean; title: string },
                index: number
              ) => {
                if (offer.canUsePromo) {
                  offer.promoSelected = false;
                }

                offer.showOffers = false;
                offer.title = this.titles[index];

                return offer;
              }
            );
        }
      } catch (error) {
        this.isSubmitting = false;
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }
        if(errorMessage != "Bad Request"){
          showErrorToast(this, "error", errorMessage);
        }
        
      }
      }
    },
    selectChecked(offer: IOffer){
      if(this.selectedOffer1 != null){
      if(offer.loanId == this.selectedOffer1.loanId){
        if(this.selectedOffer1.promoSelected == false){
          return true;
        }else{
          return false;
        }
      }else{
        return true;
      }
    }else{
      return true;
    }
    },
    selectChecked1(offer: IOffer){
      if(this.selectedOffer1 != null){
      if(offer.loanId == this.selectedOffer1.loanId){
        if(this.selectedOffer1.promoSelected == true){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
      }else{
        return false;
      }
    },
    selectPromo(promo: boolean) {
      if (promo) {
        this.selectedOffer.promoSelected = true;
      } else {
        this.selectedOffer.promoSelected = false;
      }
    },
    selectOffer(offer: IOffer, promoSelected: boolean) {
      this.selectedOfferId = offer.loanId;
      this.selectedOffer = { ...offer };
      this.selectedOffer.promoSelected = promoSelected;
      this.selectedTerm = offer.term;
      this.offers = this.offers.map((offerObj) => {
        if (offerObj.term === offer.term) {
          offerObj.promoSelected = promoSelected;
        }
        return offerObj;
      });
    },
    selectTerm(offer: IOffer) {
      
      if (this.selectedTerm !== offer.term) {
        this.selectedOfferId = null;
        this.selectedOffer = {};
      }
      this.selectedTerm = offer.term;
      if (!Object.keys(this.selectedOffer).length)
        this.selectOffer(offer, offer.promoSelected);
    },
    setAutoPay(offer: IOffer) {
      if (this.selectedTerm === offer.term) {
        this.promo = false;
      }
    },
    setAutoPayTrue(offer: IOffer) {
      if (this.selectedTerm === offer.term) {
        this.promo = true;
      }
    },

    async onSubmit(skipAutoPay = false) {
      this.isSubmitting = true;
      this.setIsLoading(true);

      try {
        const requestBody = {
          skipAutoPay,
          loanId: this.selectedOffer.loanId as string,
          promoSelected: this.selectedOffer.promoSelected as boolean,
        };

        await saveOffer(requestBody);
        this.isSubmitting = false;
        this.setIsLoading(false);

        await this.$router.push({ name: "reviewOffer" });
      } catch (error) {
        this.setIsLoading(false);
        this.isSubmitting = false;
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
    toggleOpened(offer: IOffer) {
      this.offers = this.offers.map((offerObj) => {
        if (offerObj.term !== offer.term) {
          offerObj.showOffers = false;
        } else {
          offerObj.showOffers = !offerObj.showOffers;
        }

        return offerObj;
      });
    },
  },

  async created() {
    try {
      const { data } = await getApplicationData();
      const { isCompleted, lastStep, requestedAmount, selectedOffer } = data;

      if (isCompleted) {
        await this.$router.push({ name: "userDashboard" });
      } else if (lastStep !== "offers") {
        await this.$router.push({ name: lastStep });
      } else {
        this.setStep(2);
        this.selectedOffer1 = selectedOffer;
        await this.getOffersLayout(requestedAmount);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await this.$router.push({ name: "userLogin" });
      } else {
        showErrorToast(this, "error", error.message);
      }
    }
  },

  async mounted() {
    const allRadios = document.getElementsByName("re");
    let booRadio: any;
    let x = 0;
    for (x = 0; x < allRadios.length; x++) {
      (allRadios[x] as any).onclick = function() {
        if (booRadio === this) {
          this.checked = false;
          booRadio = null;
        } else {
          booRadio = this;
        }
      };
    }
  },
});
