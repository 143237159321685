
import Vue from "vue";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  data() {
    return {
      email: null as null | string,
      firstName: null as null | string,
      isSubmitting: false,
      lastName: null as null | string,
      locations: [] as { text: string; value: string }[],
      phone: null as null | string,
      selectedSendOptions: [] as string[],
      selectedLocation: null as null | string,
      sendLinkOptions: [
        { text: "Send Email", value: "email" },
        { text: "Send SMS", value: "sms" },
      ],
      sendLinkOptionsError: null as null | string,
      showShareLink: false,
    };
  },

  computed: {
    parsedPhoneNumber(): string {
      if (this.phone) {
        return this.phone.replace(/[^0-9]/g, "");
      }

      return "";
    },
  },

  methods: {
    onCheckboxInput() {
      if (this.sendLinkOptionsError) {
        this.sendLinkOptionsError = null;
      }
    },
    isLetter(event: KeyboardEvent) {
      const char = event.key;
      if (!/^[a-zA-Z\s]+$/.test(char)) {
        event.preventDefault();
      }
    },
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    resetForm() {
      this.email = null;
      this.firstName = null;
      this.lastName = null;
      this.lastName = null;
      this.phone = null;
      this.selectedLocation = null;
      this.showShareLink = false;
      this.selectedSendOptions = [];
      this.sendLinkOptionsError = null;
      this.$nextTick(() => {
        (this.$refs.observer as any).reset();
      });
    },
    async onSubmit() {
      if (this.selectedSendOptions.length <= 0) {
        this.sendLinkOptionsError =
          "At least one option of sending must be checked";
        return;
      }

      try {
        this.isSubmitting = true;
        const requestBody = {
          email: this.email || "",
          firstName: this.firstName || "",
          lastName: this.lastName || "",
          phone: this.parsedPhoneNumber,
          practiceManagement: this.selectedLocation || "",
          sendEmail: this.selectedSendOptions.includes("email"),
          sendSms: this.selectedSendOptions.includes("sms"),
          source: "web" as const,
        };
        await adminDashboardRequests.sendApplicationLink(requestBody);

        await this.$swal({
          title: "Success!",
          text: "Application Successfully Sent.",
          icon: "success",
        });
        this.resetForm();
        this.isSubmitting = false;
      } catch (error) {
        this.isSubmitting = false;
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },

  async created() {
    const { data: locationsData } = await adminDashboardRequests.getLocations();
    const mappedLocations = locationsData.map(
      ({ _id, location }: { _id: string; location: string }) => {
        return {
          value: _id,
          text: location,
        };
      },
    );
    this.locations = [
      {
        value: null,
        text: "Select a Location",
      },
      ...mappedLocations,
    ];
  },
});
