
import Vue from "vue";

import BaseTable from "@/components/Tables/base/Table.vue";

export default Vue.extend({
  components: { BaseTable },

  props: {
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
  },

  data() {
    return {
      fields: [
        { key: "loanReference", label: "Loan Reference" },
        { key: "logReference", label: "Log Reference" },
        { key: "email", label: "Email Address" },
        { key: "moduleName", label: "Module Name" },
        { key: "message", label: "Message" },
        { key: "ip", label: "Ip Address" },
        { key: "dateCreated", label: "Created Date" },
        { key: "id", label: "Action" },
      ],
    };
  },
});
