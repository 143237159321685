
import Vue from "vue";

import Layout from "../layouts/admin/Layout.vue";
import ManageVendor from "@/components/details/ManageVendor.vue";

export default Vue.extend({
  components: {
    Layout,
    ManageVendor,
  },
});
