
import Vue from "vue";
import { errorHandler } from "@/api/error-handler";
import { adminDashboardRequests } from "@/api/admin-dashboard";
// import LogActivityTabTable from "@/admin-dashboard/rules-details/components/LogActivityTabTable.vue";
// import { VueCollapsiblePanelGroup, VueCollapsiblePanel } from '@dafcoe/vue-collapsible-panel';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const collapsiblePanel = require("@dafcoe/vue-collapsible-panel");
// const { VueCollapsiblePanelGroup, VueCollapsiblePanel } = collapsiblePanel;
// import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css';

export default Vue.extend({
  data() {
    return {
      text: "Testing",
      items: [
        { label: "Stephen Hawking", ruleId: 1 },
        { name: "Johnny Appleseed", id: 2 },
      ],
      fields: [
        { key: "ruleId", label: "RuleID" },
        { key: "ruleName", label: "Rule Name" },
      ],
      rulesIds: [],
      ruleContext: null,
    };
  },
  components: {
    // LogActivityTabTable,
    // VueCollapsiblePanelGroup,
    // VueCollapsiblePanel
  },
  mounted: function mounted() {
    this.fetchTable();
  },
  methods: {
    async fetchTable() {
      try {
        const { data } =
          await adminDashboardRequests.getRulesDetailsByScreenTrackingId(
            this.screenTrackingId,
          );
        const { rulesDetails, totalRows } = data;
        if (rulesDetails.ruleData) {
          // may be empty if application is skipping some rules (for example: UCL)
          const rulesIds: any = Object.keys(rulesDetails.ruleData);
          const ruleContext: any = rulesDetails.ruleData;
          this.rulesIds = rulesIds;
          this.ruleContext = ruleContext;
        }
        // this.totalRows = totalRows;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
  },
  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },
});
