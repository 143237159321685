
import Vue from "vue";
import BaseTable from "../../base/Table.vue";

export default Vue.extend({
  name: "VendorTemplate",
  components: { BaseTable },
  data() {
    return {
      fields: [
        { key: "name", label: "Vendor Name" },
        { key: "apiKey", label: "API Key" },
        { key: "autoImport", label: "Auto Import" },
        { key: "createdAt", label: "Created Date " },
        { key: "_id", label: "Action" },
      ],
    };
  },
  methods: {
    handleAutoImport(status: boolean) {
      return status ? "Yes" : "No";
    },
  },
  props: {
    title: {
      required: false,
      default: null,
      type: String,
    },
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
  },
});
