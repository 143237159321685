
import Vue from "vue";

import Layout from "../layouts/admin/Layout.vue";
import showErrorToast from "@/helpers/errorToast";
import { adminDashboardRequests } from "@/api/admin-dashboard";

export default Vue.extend({
  components: { Layout },

  data() {
    return {
      newPassword: null as null | string,
      existingPassword: null as null | string,
      isSubmitting: false,
    };
  },

  methods: {
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;
        await adminDashboardRequests.changePassword(
          this.existingPassword || "",
          this.newPassword || ""
        );
        await this.$swal({
          title: "Success!",
          text: "Password successfully changed.",
          icon: "success",
        });

        await this.$router.push({ name: "adminDashboard" });
      } catch (error) {
        this.isSubmitting = false;

        let errorMessage = "";
        if (error.response.status === 400) {
          errorMessage = "Password invalid.";
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
});
