
import Vue from "vue";

import { getApplicationData } from "@/user-application/application/api";
import { getDashboardData } from "@/user-application/dashboard/api";
import MakePaymentButton from "@/user-application/dashboard/components/MakePaymentButton.vue";
import ChangePaymentAmount from "@/user-application/dashboard/components/ChangePaymentAmount.vue";
import { errorHandler } from "@/api/error-handler";
import moment from "moment";

export default Vue.extend({
  components: { MakePaymentButton, ChangePaymentAmount },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      isInRepayment: false,
      isPaid: false,
      paymentManagement: [] as any,
      email: "",
      promopayStatus: false,
      promopayCheck: false,
      ledger: {} as any,
    };
  },

  created() {
    this.promopayStatus = false;
    this.promopayCheck = false;
  },

  computed: {
    paymentScheduleLeft(): any[] | undefined {
      if (this.paymentManagement && this.paymentManagement.paymentSchedule) {
        const response = this.paymentManagement.paymentSchedule.filter(
          (scheduleItem: any) =>
            scheduleItem.status === "opened" &&
            moment().isAfter(scheduleItem.date)
        );
        return response;
      }
      return undefined;
    },
    promoPaymentLeft(): any[] | undefined {
      if (
        this.paymentManagement &&
        this.paymentManagement.initialPaymentSchedule
      ) {
        const response = this.paymentManagement.initialPaymentSchedule.filter(
          (scheduleItem: any) => scheduleItem.status === "opened"
        );
        let i = 0;
        const response1: any[] = [];
        let startPrincipal = 0;
        let endPrincipal = 0;
        const responsePay = this.paymentManagement.paymentSchedule.filter(
          (scheduleItem: any) => scheduleItem.status === "paid"
        );
        let paidPrincipal = 0;
        const payDate = this.paymentManagement.nextPaymentSchedule;
        if (responsePay.length > 0) {
          let j = 0;
          for (const payV in responsePay) {
            paidPrincipal = responsePay[j].principal + paidPrincipal;
            j = j + 1;
          }
        }
        const DateObj = new Date(payDate);
        const dates = DateObj.getDate();
        let months = DateObj.getMonth() + 1;
        let years = DateObj.getFullYear();
        for (const x in response) {
          const resp: any = response[i];
          if (i == 0) {
            startPrincipal = resp.startPrincipal - paidPrincipal;
            resp.startPrincipal = startPrincipal;
            resp.interest = 0;
            resp.amount = this.paymentManagement.promoPaymentAmount - resp.fees;
            resp.principal = resp.amount;
            resp.endPrincipal = resp.startPrincipal - resp.amount;
            endPrincipal = resp.endPrincipal;
          } else {
            resp.startPrincipal = endPrincipal;
            startPrincipal = resp.startPrincipal;
            resp.amount = this.paymentManagement.promoPaymentAmount - resp.fees;
            resp.interest = 0;
            resp.principal = resp.amount;
            resp.endPrincipal = endPrincipal - resp.amount;
            endPrincipal = resp.endPrincipal;
          }
          resp.date = `${months}/${dates}/${years}`;
          if (months == 12) {
            months = 1;
            years = years + 1;
          } else {
            months = months + 1;
          }
          if (startPrincipal > this.paymentManagement.promoPaymentAmount) {
            response1.push(resp);
          } else if (startPrincipal > 0) {
            resp.amount = resp.startPrincipal - resp.fees;
            resp.interest = 0;
            resp.principal = resp.startPrincipal;
            resp.endPrincipal = 0;
            response1.push(resp);
          }
          i = i + 1;
        }
        return response1;
      }
      return undefined;
    },
    payments(): undefined | any[] {
      if (this.paymentManagement && this.paymentManagement.paymentSchedule) {
        const response = this.paymentManagement.paymentSchedule.filter(
          (payment: any) =>
            payment.status === "paid" || payment.status === "failed"
        );

        return response;
      }
      return undefined;
    },
  },

  methods: {
    getNextPaymentAmount(paymentManagement: any) {
      // debugger;
      const data = paymentManagement?.paymentSchedule?.find(
        (item: any) => item.status === "opened"
      );
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(data.amount) || "N/A";
    },
    async turnOnPromopay() {
      try {
        if (this.paymentManagement) {
          if (this.promopayStatus == false) {
            this.promopayStatus = true;
            this.promopayCheck = true;
          } else {
            this.promopayStatus = false;
            this.promopayCheck = false;
          }
        } else {
          //this.$emit('updateTabIndex', 3);
          // await this.$swal({
          //   title: "Alert",
          //   text: "Something went wrong. Try again later",
          //   icon: "info",
          // });
        }
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async reloadPage() {
      try {
        const dashboardDataResponse = await getDashboardData();
        const {
          paymentManagementData,
          email,
          ledger,
        } = dashboardDataResponse.data;
        this.email = email;
        this.ledger = ledger;

        if (
          paymentManagementData?.status === "in-repayment prime" ||
          paymentManagementData?.status === "in-repayment" ||
          paymentManagementData?.status === "in-repayment non-prime"
        ) {
          this.isInRepayment = true;
        } else if (paymentManagementData?.status === "paid") {
          this.isInRepayment = false;
          this.isPaid = true;
        }

        this.paymentManagement = paymentManagementData;
      } catch (error) {
        if (error.response.status === 404) {
          return;
        }

        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }
      }
    },
  },

  async mounted() {
    try {
      const [
        applicationDataResponse,
        dashboardDataResponse,
      ] = await Promise.all([getApplicationData(), getDashboardData()]);
      const {
        paymentManagementData,
        email,
        ledger,
      } = dashboardDataResponse.data;
      this.email = email;
      this.ledger = ledger;
      // debugger;
      if (
        paymentManagementData?.status === "in-repayment" ||
        paymentManagementData?.status === "in-repayment prime" ||
        paymentManagementData?.status === "in-repayment non-prime" ||
        paymentManagementData?.status === "in-repayment delinquent1" ||
        paymentManagementData?.status === "in-repayment delinquent2" ||
        paymentManagementData?.status === "in-repayment delinquent3" ||
        paymentManagementData?.status === "in-repayment delinquent4"
      ) {
        this.isInRepayment = true;
      } else if (paymentManagementData?.status === "paid") {
        this.isInRepayment = false;
        this.isPaid = true;
      }

      this.paymentManagement = paymentManagementData;
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }

      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },
});
