
import Vue from "vue";

import Layout from "../layouts/admin/Layout.vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import ManageVendor from "@/components/details/ManageVendor.vue";

export default Vue.extend({
  components: {
    ManageVendor,
    Layout,
  },

  data() {
    return {
      vendorId: null as null | string,
      name: null,
      apiKey: null,
      autoImport: null,
    };
  },

  async created() {
    try {
      const vendorId = this.$route.params.id;
      this.vendorId = vendorId;
      const { data } = await adminDashboardRequests.getVendorById(vendorId);
      const { name, apiKey, autoImport } = data;

      this.name = name;
      this.apiKey = apiKey;
      this.autoImport = autoImport;
    } catch (error) {
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },
});
