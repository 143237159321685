
import Vue from "vue";

import BaseTableSection from "@/components/Tables/base/TableSection.vue";
import AuditLogTemplate from "@/admin-dashboard/audit-log/components/AuditLogTemplate.vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import { TableRequestEvent } from "@/types/tables";
import { errorHandler } from "@/api/error-handler";

const DEFAULT_REQUEST_PARAMS = { page: 1, perPage: 25 };

export default Vue.extend({
  components: {
    BaseTableSection,
    AuditLogTemplate,
  },

  data() {
    return {
      defaultParams: DEFAULT_REQUEST_PARAMS,
      currentStatus: "",
      rows: [],
      totalRows: 0,
    };
  },

  methods: {
    async fetchTable(event: TableRequestEvent) {
      this.currentStatus = event.status as string;
      try {
        const { data } = await adminDashboardRequests.getAllLogActivities({
          ...event,
        });
        const { rows, totalRows } = data;
        this.rows = rows;
        this.totalRows = totalRows;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
  },
});
