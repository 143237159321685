
import Vue from "vue";
import moment from "moment";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Datepicker from "vuejs-datepicker";
import { extend } from "vee-validate";

import {
  getDashboardData,
  getPaymentPreview,
  submitPayment,
} from "@/user-application/dashboard/api";
import { errorHandler } from "@/api/error-handler";
import { isCardExpired } from "@/user-application/helpers/";

extend("positive", (value: any) => {
  if (typeof value === "string") {
    const parsedAmount = value.replace(/[$,]/g, "");
    const isLowerThanOne = parseFloat(parsedAmount) < 5;
    if (isLowerThanOne) {
      return "Amount must be greater than $5.00";
    }
    return true;
  } else {
    if (value < 1) {
      return "Amount must be greater than $5.00";
    }
    return true;
  }
});

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 4,
});

export default Vue.extend({
  components: {
    Datepicker,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
    regularAmount: {
      required: true,
      type: Number,
    },
    promoAmount: {
      required: true,
      type: Number,
    },
    currentAmount: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      modal: false,
      makePaymentMethodVia: null as null | string,
      disclaimerModal: false,
      previewPaymentComponent: false,
      previewNewScheduleComponent: false,
      isMonthlyPayment: true,
      isPayoff: false,
      isValidAmount: true,
      previewResult: undefined as any,
      previewResults: undefined as any,
      payoffAmount: 0,
      suggestedPaymentAmount: 0 as number,
      userCards: [] as {
        paymentMethodToken?: string;
        cardNumberLastFour?: string;
        paymentType: string;
        _id: string;
        accountNumber?: string;
        financialInstitution?: string;
      }[],
      selectedCard: "",

      amount: "",
      amount2: 0,
      newScheduleItemTransactionId: "",
      paymentType: "regular",
      paymentDate: new Date(),
      isLoading: false,
      mask: currencyMask,
      paymentManagementData: {},
      disabledDates: {
        to: moment().startOf("day").toDate(),
        from: moment().add(1, "day").startOf("day").toDate(),
      },
    };
  },

  computed: {
    amountToNumber(): number {
      if (typeof this.amount === "string") {
        const newAmount = parseFloat(this.amount.replace(/[$,]/g, ""));
        return newAmount;
      }

      return this.amount;
    },
    isPaymentToday(): boolean {
      return moment(this.paymentDate)
        .startOf("day")
        .isSame(moment().startOf("day"));
    },
    parsedPaymentDate(): Date {
      return moment(this.paymentDate).startOf("day").toDate();
    },
    paymentScheduleLeft(): any[] {
      const response = this.previewResult.preview.paymentSchedule.filter(
        (scheduleItem: any) => scheduleItem.status === "opened",
      );
      return response;
    },
  },

  methods: {
    onSelectPaymentMethod(event: any) {
      this.makePaymentMethodVia = event.target?.value;
    },
    showDisclaimer() {
      this.previewPaymentComponent = false;
      this.disclaimerModal = true;
    },
    onNext() {
      this.goToNewSchedule();
    },
    goToPreviewPayment() {
      this.disclaimerModal = false;
      this.previewNewScheduleComponent = false;
      this.previewPaymentComponent = true;
    },
    async onAmountBlur(event: any) {
      const parsedAmount = (event.target.value as string).replace(/[$,]/, "");
      if (parseFloat(parsedAmount) < 5) {
        return;
      }
      const requestBody = {
        screenTracking: this.screenTrackingId,
        amount: parseFloat(parsedAmount),
        paymentDate: this.parsedPaymentDate,
      };
      const { data } = await getPaymentPreview(requestBody);
      const { previewResult, regularPayment } = data;
      this.suggestedPaymentAmount =
        previewResult.ledger.delinquentAmount ||
        regularPayment ||
        previewResult.paymentAmount;
      this.payoffAmount = data.payoff;
      this.amount = Number(previewResult.paymentAmount).toFixed(2);
      this.newScheduleItemTransactionId =
        previewResult.preview.newScheduleItemTransactionId;
      this.previewResult = previewResult;
      this.previewResults = previewResult;
    },
    goToNewSchedule() {
      this.disclaimerModal = false;
      this.previewPaymentComponent = false;
      this.previewNewScheduleComponent = true;
    },
    async showPreviewPayment() {
      this.modal = true;
      if (!this.userCards || this.userCards.length <= 0) {
        await this.$swal({
          title: "Error",
          text: "User didn't add a card yet",
          icon: "error",
        });
        this.modal = false;
        return;
      }

      this.previewPaymentComponent = true;
      this.paymentType = "regular";
      const requestBody = {
        screenTracking: this.screenTrackingId,
        paymentDate: this.parsedPaymentDate,
      };
      const { data } = await getPaymentPreview(requestBody);
      const { previewResult, regularPayment } = data;

      // this.amount = Number(previewResult.paymentAmount).toFixed(
      //     2
      // );
      this.suggestedPaymentAmount =
        previewResult.ledger.delinquentAmount ||
        regularPayment ||
        previewResult.paymentAmount;
      this.payoffAmount = data.payoff;
      this.amount = Number(this.suggestedPaymentAmount).toFixed(2);
      // this.amount = Number(previewResult.paymentAmount).toFixed(
      //     2
      // );
      this.newScheduleItemTransactionId =
        previewResult.preview.newScheduleItemTransactionId;
      this.previewResult = previewResult;
      this.previewResults = previewResult;
    },
    hidePreviewPayment: async function () {
      this.modal = false;
      this.disclaimerModal = false;
      this.previewPaymentComponent = false;
      this.previewNewScheduleComponent = false;
      this.isMonthlyPayment = true;
      this.isPayoff = false;
      this.paymentType = "regular";
      const dashboardDataResponse = await getDashboardData();
      const { userAccountsData } = dashboardDataResponse.data;
      if (userAccountsData && userAccountsData.length > 0) {
        this.selectedCard = userAccountsData.find((card: any) => card.isDefault)
          ?._id;
        if (!this.selectedCard) {
          this.selectedCard = userAccountsData[0]._id;
        }
      }
    },
    async toggleMonthlyPayment() {
      this.isPayoff = false;
      this.isMonthlyPayment = true;

      const requestBody = {
        screenTracking: this.screenTrackingId,
        paymentDate: this.parsedPaymentDate,
      };
      const { data } = await getPaymentPreview(requestBody);
      const { previewResult, regularPayment } = data;

      this.previewResult = previewResult;
      this.suggestedPaymentAmount =
        previewResult.ledger.delinquentAmount ||
        regularPayment ||
        previewResult.paymentAmount;
      this.payoffAmount = data.payoff;
      this.amount = Number(this.suggestedPaymentAmount).toFixed(2);
      this.newScheduleItemTransactionId =
        previewResult.preview.newScheduleItemTransactionId;
    },
    async togglePayoff() {
      this.isMonthlyPayment = false;
      this.isPayoff = true;

      const requestBody = {
        screenTracking: this.screenTrackingId,
        amount: this.previewResult.ledger.payoff,
        paymentDate: this.parsedPaymentDate,
      };
      const { data } = await getPaymentPreview(requestBody);
      const { previewResult } = data;

      this.previewResult = previewResult;
      this.payoffAmount = data.payoff;
      this.amount = Number(this.payoffAmount).toFixed(2);
      console.log(
        "Number(this.payoffAmount).toFixed(2)",
        typeof Number(this.payoffAmount).toFixed(2),
      );
      this.newScheduleItemTransactionId =
        previewResult.preview.newScheduleItemTransactionId;
    },
    setPaymentType(paymentType: string) {
      this.paymentType = paymentType;
    },
    async onDateSelected(date: Date) {
      const requestBody = {
        screenTracking: this.screenTrackingId,
        amount: this.amountToNumber,
        paymentDate: moment(date).startOf("day").toDate(),
      };
      const { data } = await getPaymentPreview(requestBody);
      const { previewResult, regularPayment } = data;

      this.previewResult = previewResult;
      this.suggestedPaymentAmount =
        previewResult.ledger.delinquentAmount ||
        regularPayment ||
        previewResult.paymentAmount;
      this.payoffAmount = data.payoff;
      this.amount = Number(
        this.paymentType === "regular"
          ? this.suggestedPaymentAmount
          : this.payoffAmount,
      ).toFixed(2);
      this.newScheduleItemTransactionId =
        previewResult.preview.newScheduleItemTransactionId;
    },
    onAmountKeyDown() {
      this.isPayoff = false;
      this.isMonthlyPayment = false;
    },
    async submitPayment() {
      try {
        this.isLoading = true;
        const requestBody = {
          screenTracking: this.screenTrackingId,
          paymentMethodToken: this.selectedCard,
          amount: this.amountToNumber,
          paymentDate: this.parsedPaymentDate,
          paymentVia: this.makePaymentMethodVia,
        };
        console.log("first", requestBody);
        await submitPayment(requestBody);
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: this.isPaymentToday
            ? "Payment successfully submitted."
            : `Payment sucessfully scheduled to ${moment(this.paymentDate)
                .startOf("day")
                .format("MM/DD/YYYY")}`,
          icon: "success",
        });

        this.modal = false;
        this.previewPaymentComponent = false;
        this.previewNewScheduleComponent = false;
        this.$emit("reloadPage");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }

        this.isLoading = false;
        this.modal = false;
        this.previewPaymentComponent = false;
        this.previewNewScheduleComponent = false;
      }
    },
  },

  async mounted() {
    const requestBody = {
      screenTracking: this.screenTrackingId,
      paymentDate: this.parsedPaymentDate,
    };
    const { data: paymentPreviewData } = await getPaymentPreview(requestBody);
    const { previewResult, regularPayment } = paymentPreviewData;
    this.previewResult = previewResult;
    this.previewResults = previewResult;
    this.suggestedPaymentAmount =
      previewResult.ledger.delinquentAmount ||
      regularPayment ||
      previewResult.paymentAmount;
    this.payoffAmount = paymentPreviewData.payoff;
    console.log(
      "--------suggestedPaymentAmount--------data==",
      this.suggestedPaymentAmount,
    );

    this.amount = Number(this.suggestedPaymentAmount).toFixed(2);
    console.log("----------------data==", this.amount);

    this.newScheduleItemTransactionId =
      previewResult.preview.newScheduleItemTransactionId;

    const dashboardDataResponse = await getDashboardData();
    const { userAccountsData, paymentManagementData } =
      dashboardDataResponse.data;

    this.paymentManagementData = paymentManagementData;
    if (!paymentManagementData.canRunAutomaticPayment) {
      this.disabledDates.from = moment().add(1, "days").startOf("day").toDate();
    }
    if (userAccountsData && userAccountsData.length > 0) {
      this.userCards = userAccountsData.filter((card: any) => {
        if (
          card.paymentType === "CARD" &&
          !isCardExpired(card.cardExpiration)
        ) {
          return card;
        } else if (card.paymentType === "ACH") return card;
      });

      this.selectedCard = userAccountsData.find((card: any) => card.isDefault)
        ?._id;
      if (!this.selectedCard) {
        this.selectedCard = userAccountsData[0]._id;
      }
      this.makePaymentMethodVia = this.selectedCard;
    }
  },
});
