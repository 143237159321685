
import Vue from "vue";

export default Vue.extend({
  props: {
    nav: {
      required: false,
      default: null,
      type: Array,
    },
    useSearch: {
      required: false,
      default: true,
      type: Boolean,
    },
    searchValue: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
    },
  },
});
