export default [
    { value: "12:00 AM"},
    { value: "1:00 AM"},
    { value: "2:00 AM"},
    { value: "3:00 AM"},
    { value: "4:00 AM"},
    { value: "5:00 AM"},
    { value: "6:00 AM"},
    { value: "7:00 AM"},
    { value: "8:00 AM"},
    { value: "9:00 AM"},
    { value: "10:00 AM"},
    { value: "11:00 AM"},
    { value: "12:00 PM"},
    { value: "1:00 PM"},
    { value: "2:00 PM"},
    { value: "3:00 PM"},
    { value: "4:00 PM"},
    { value: "5:00 PM"},
    { value: "6:00 PM"},
    { value: "7:00 PM"},
    { value: "8:00 PM"},
    { value: "9:00 PM"},
    { value: "10:00 PM"},
    { value: "11:00 PM"},
  ];
  