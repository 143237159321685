
import Vue from "vue";
import moment from "moment";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Datepicker from "vuejs-datepicker";
import { extend } from "vee-validate";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import { isCardExpired } from "@/admin-dashboard/helpers/";
import states from "@/helpers/states";
import { assignLoan } from "@/user-application/authentication/api";

export default Vue.extend({
  components: {
    //Datepicker,
  },

  props: {
   
  },

  data() {
    return {
      modal: false,
      adminUsers: [] as {
        email: string;
        role: string;
        id: string;
      }[],
      selectedCard: "",
      isLoading: false,
    };
  },

  computed: {

  },

  //    fees: 0,
  //         interest: 0,
  //         unpaidInterest: 0,
  //         principal: 0,

  methods: {
    onCancel() {
      this.isLoading = false;
      this.modal = false;
    },
    async onAssign() {
      const myArrayFromLocalStorage = localStorage.getItem('collectionData')
    if (myArrayFromLocalStorage && myArrayFromLocalStorage.length) {
    const myArray = JSON.parse(myArrayFromLocalStorage)
      try{
    const assignResult = await assignLoan(myArray, this.selectedCard);
      this.$swal({
        title: "Alert",
        text: "Loan assigned successfully",
        icon: "info",
        confirmButtonText: `Ok`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$router.go(0);
        }
      });
    }catch(error){
      this.$swal({
        title: "Alert",
        text: "Loan cannot be assigned",
        icon: "info",
        confirmButtonText: `Ok`,
      }).then(async (result) => {
        if(result.isConfirmed){
          this.$router.go(0);
        }
      });
    }
    
    }
    
    },
    onAssignLoan() {
      const arrayLS = localStorage.getItem('collectionData')!
      const myArray = JSON.parse(arrayLS)
      if (myArray && myArray.length > 1) {
        this.modal = true;
      }
    },
    async showPopup(sender: any[]) {
      await this.$swal({
        title: "Error",
        text: `${JSON.stringify(sender)}`,
        icon: "error",
      });
    },
  },

  async mounted() {

    const { data } = await adminDashboardRequests.getAdmin(
      
    );

   
    
    if (data && data.rows.length > 0) {
      this.adminUsers = data.rows.filter(
        (admin: any) => admin.role == 'Super Admin'
      );
      this.selectedCard = "";
    }
  },
});
