
import Vue from "vue";

import BaseTable from "../../base/Table.vue";

export default Vue.extend({
  name: "UserTemplate",
  components: { BaseTable },

  data() {
    return {
      fields: [
        { key: "region", label: "Region" },
        { key: "managementRegion", label: "Management Region" },
        { key: "location", label: "Location" },
        { key: "openDate", label: "Open Date" },
        { key: "address", label: "Address" },
        { key: "city", label: "City" },
        { key: "stateCode", label: "State" },
        { key: "zip", label: "Zip" },
        { key: "phone", label: "Phone" },
        { key: "regionalManager", label: "Regional Manager" },
        { key: "_id", label: "Action" },
      ],
    };
  },

  props: {
    title: {
      required: false,
      default: null,
      type: String,
    },
    rows: {
      required: true,
      default: () => [],
      type: Array,
    },
    onSort: {
      required: false,
      default: null,
      type: Function,
    },
  },
});
