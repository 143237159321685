
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  methods: {
    async printSummary() {
      this.$htmlToPaper("printMe");
    },
  },
  computed: {
    ...mapState({
      provider: (state: any) => state.contract.provider,
      paymentScheduleInfo: (state: any) => state.contract.paymentScheduleInfo,
      userData: (state: any) => state.contract.userData,
      selectedOffer: (state: any) => state.contract.selectedOffer,
      screenTracking: (state: any) => state.contract.screenTracking,
      today: (state: any) => state.contract.date,
      signature: (state: any) => state.contract.signature,
    }),
  },
});
