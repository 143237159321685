
import Vue from "vue";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import { isCardExpired } from "@/admin-dashboard/helpers/";

export default Vue.extend({
  components: {
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      modal: false,
      makePaymentMethodVia: null as null | string,
      manualFundingComponent: false,
      userCards: [] as {
        paymentMethodToken: string;
        cardNumberLastFour: string;
      }[],
      bankAccounts: [] as any[],
      selectedCard: "",
      isLoading: false,
      val: false,
      allPaymentMethods: [] as any,
      uniqueId: null as null | string,
      achItemId: null as null | string,
    };
  },
  methods: {
    isDisabled(type:string): boolean {

      if(type === 'card') {
        return this.uniqueId == null || this.uniqueId.length < 1
      } else {
        return this.achItemId == null || this.achItemId.length < 1
      }
    },
    async onSubmit(type = "card") {

      try {
        //if(this.makePaymentMethodVia) {
          this.isLoading = true;
          await adminDashboardRequests.setManualFunded({
            type,
            itemId: type === 'card'? this.uniqueId : this.achItemId,
            screenTrackingId: this.screenTrackingId
          });
          this.isLoading = false;
          await this.$swal({
            title: "Success!",
            text: 'Successfully funded',
            icon: "success",
          });

          this.modal = false;
          this.manualFundingComponent = false;
          this.$emit("reloadPage");
        // } else {
        //   await this.$swal({
        //     title: "Warning!",
        //     text: 'Select funding item',
        //     icon: "error",
        //   });
        // }
      } catch (error) {
        this.resetForms();
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }

        this.isLoading = false;
        this.modal = false;
        this.manualFundingComponent = false;
      }
    },
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    async showManualFunding() {
      this.modal = true;
      this.manualFundingComponent = true;
    },
    resetForms() {
      this.achItemId = null;
      this.uniqueId = null
    },
    hideManualFunding: async function () {
      this.resetForms();
      this.modal = true;
      this.manualFundingComponent = false;
      this.$router.go(0);
    },
    onCancel() {
      this.$nextTick(() => {
        (this.$refs.observer as any).reset();
      });
    },
    async showPopup() {
      await this.$swal({
        title: "Error",
        text: `${this.val}`,
        icon: "error",
      });
    },
    async reloadPage() {
      try {
        console.log("Reloading");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
  },

  async mounted() {

    const { data } = await adminDashboardRequests.getUserCards(
      this.screenTrackingId
    );

    const {
      data: userBankAccounts,
    } = await adminDashboardRequests.listUserBanks(this.screenTrackingId);
    this.bankAccounts = userBankAccounts;

    this.reloadPage();
    const allPaymentMethods = [...data, ...userBankAccounts];
    this.allPaymentMethods = allPaymentMethods;
    if (data && data.length > 0) {
      this.userCards = data.filter(
        (card: any) => !isCardExpired(card.cardExpiration)
      );
      this.selectedCard = allPaymentMethods.find(
        (card: any) => card.isDefault
      )._id;
      this.makePaymentMethodVia = this.selectedCard;
    }
  },
});
