
import Vue from "vue";

import Layout from "@/user-application/layout/pages/Layout.vue";
import { resetPassword } from "@/user-application/authentication/api";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  components: {
    Layout,
  },
  data() {
    return {
      password: null as null | string,
      confirmPassword: null as null | string,
      token: null as null | string,
      isSubmitting: false,
    };
  },

  methods: {
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    getPasswordValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated
        ? valid &&
            this.password &&
            /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/.test(this.password) &&
            this.confirmPassword === this.password
        : null;
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;

        await resetPassword(this.token || "", this.password || "");
        await this.$swal({
          title: "Success!",
          text: "Password successfully changed.",
          icon: "success",
        });

        await this.$router.push({ name: "userLogin" });
      } catch (error) {
        this.isSubmitting = false;

        let errorMessage = "";
        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
  mounted() {
    this.token = this.$route.params.token;
  },
});
