
import Vue from "vue";
import moment from "moment";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Datepicker from "vuejs-datepicker";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 4,
});

export default Vue.extend({
  components: {
    Datepicker,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      modal: false,
      previewPaymentComponent: false,
      previewNewScheduleComponent: false,
      previewResult: undefined as any,
      previewResults: undefined as any,
      paymentManagement: [] as any,
      amount: "",
      newScheduleItemTransactionId: "",
      paymentDate: new Date(),
      isLoading: false,
      mask: currencyMask,
      val: false,
    };
  },

  computed: {
    amountToNumber(): number {
      if (typeof this.amount === "string") {
        const newAmount = parseFloat(this.amount.replace(/[$,]/g, ""));
        return newAmount;
      }

      return this.amount;
    },
    parsedPaymentDate(): Date {
      return moment(this.paymentDate).toDate();
    },
  },

  methods: {
    async onNext() {
      this.goToNewSchedule();
    },
    async showPopup() {
      await this.$swal({
        title: "Error",
        text: `${this.val}`,
        icon: "error",
      });
    },
    async reloadPage() {
      try {
        const { data } = await adminDashboardRequests.getPaymentManagement(
            this.screenTrackingId
        );
        this.paymentManagement = data.response;
        const today = moment()
            .startOf("day")
            .toDate();
        if (
            moment(this.paymentManagement.nextPaymentSchedule).isBefore(
                moment(today),
                "day"
            )
        ) {
          this.val = true;
        }
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    goToPreviewPayment() {
      this.previewNewScheduleComponent = false;
      this.previewPaymentComponent = true;
    },
    async onAmountBlur(event: any) {
      const parsedAmount = (event.target.value as string).replace(/[$,]/, "");
      if (parseFloat(parsedAmount) < 1) {
        return;
      }

      const { data } = await adminDashboardRequests.getPaymentPreview(
          this.screenTrackingId,
          { amount: parsedAmount, paymentDate: this.parsedPaymentDate }
      );
      const { previewResult } = data;

      this.amount = Number(parsedAmount).toFixed(2);

      this.newScheduleItemTransactionId =
          previewResult.preview.newScheduleItemTransactionId;
      this.previewResult = previewResult;
      this.previewResults = previewResult;
    },
    goToNewSchedule() {
      this.previewPaymentComponent = false;
      this.previewNewScheduleComponent = true;
    },
    async showPreviewPayment() {
      this.modal = true;

      this.previewPaymentComponent = true;

      const { data } = await adminDashboardRequests.getPaymentPreview(
          this.screenTrackingId,
          { paymentDate: this.parsedPaymentDate }
      );
      const { previewResult } = data;
      this.amount = Number(previewResult.paymentAmount).toFixed(2);

      this.newScheduleItemTransactionId =
          previewResult.preview.newScheduleItemTransactionId;
      this.previewResult = previewResult;
      this.previewResults = previewResult;
    },
    hidePreviewPayment: async function () {
      this.modal = false;
      this.previewPaymentComponent = false;
      this.previewNewScheduleComponent = false;
    },
    async submitPayment() {
      try {
        this.isLoading = true;
        const paymentDate = moment(this.parsedPaymentDate).isSame(moment(), 'days')
            ? moment().toDate()
            : moment(this.parsedPaymentDate).toDate();
        await adminDashboardRequests.makeSpecialCreditPayment(this.screenTrackingId, {
          amount: this.amountToNumber,
          paymentDate,
        });
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: "Special Credit Payment successfully submitted.",
          icon: "success",
        });

        this.modal = false;
        this.previewPaymentComponent = false;
        this.previewNewScheduleComponent = false;
        this.$emit("reloadPage");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }

        this.isLoading = false;
        this.modal = false;
        this.previewPaymentComponent = false;
        this.previewNewScheduleComponent = false;
      }
    },
  },

  async mounted() {
    const {
      data: paymentPreviewData,
    } = await adminDashboardRequests.getPaymentPreview(this.screenTrackingId, {
      paymentDate: this.parsedPaymentDate,
    });
    const { previewResult } = paymentPreviewData;
    this.previewResult = previewResult;
    this.previewResults = previewResult;
    this.amount = Number(paymentPreviewData.paymentAmount).toFixed(2);
    this.newScheduleItemTransactionId =
        previewResult.preview.newScheduleItemTransactionId;
  },
});
