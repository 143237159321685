
import Vue from "vue";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import AddCardButton from "@/components/buttons/AddCardButton.vue";
import { isCardExpired, getAdminRoles } from "@/admin-dashboard/helpers";
import { getUserData } from "@/admin-dashboard/authentication/helpers";

export default Vue.extend({
  components: {
    AddCardButton,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      cards: [],
      bankAccounts: [],
      userData: getUserData(),
      adminRoles: getAdminRoles(),
      modal: false,
      currentDC: {},
      initialDC: {},
      isDefault: false,
      intialDefaultCardId: null,
      isLoading: false,
      componentKey: 0,
      duplicatesList: [] as any,
      paymentMethodControll: false,
      selectedAutopayMethod: "",
      paymentManagement: [] as any,
      currentDCAutopay: "",
      initialDCAutopay: "",
    };
  },

  methods: {
    setDefaultCard(id: any, checkbox: any, cards: any) {
      //uncheck other boxes
      for (const box of checkbox) {
        if (box.id !== id) {
          box.checked = false;
        }
      }

      //set default card
      for (const box of checkbox) {
        if (box.id == id) {
          box.checked = true;
          const defaultCard = cards.find(
            (card: { _id: any }) => card._id == box.id,
          );
          return defaultCard._id;
        }
      }
    },
    check: function (e: any, method: "card" | "bank") {
      this.modal = true;
      const checkbox = document.querySelectorAll(".checks");
      const appliedMethod = method === "card" ? this.cards : this.bankAccounts;
      this.currentDC = this.setDefaultCard(
        e.target.id,
        checkbox,
        appliedMethod,
      );
    },
    checkAutopay: function (e: any, method: "CARD" | "ACH") {
      this.selectedAutopayMethod = method;
      this.paymentMethodControll = true;
      const checkbox = document.querySelectorAll(".checksAutopay");
      const appliedMethod = method === "CARD" ? this.cards : this.bankAccounts;
      this.currentDCAutopay = this.setDefaultCard(
        e.target.id,
        checkbox,
        appliedMethod,
      );
    },
    hideModal(e: any) {
      this.modal = false;
      //revert to initialDC
      const checkbox = document.querySelectorAll(".checks");
      this.setDefaultCard(this.initialDC, checkbox, this.cards);
    },
    hidePaymentMethodControll(e: any) {
      this.paymentMethodControll = false;
      const checkbox = document.querySelectorAll(".checksAutopay");
      this.setDefaultCard(this.initialDCAutopay, checkbox, this.cards);
    },
    saveDefaultCard: async function () {
      const paymentMethodToken: any = this.currentDC;
      //update card
      await adminDashboardRequests.updateUserCard(paymentMethodToken);
      this.initialDC = this.currentDC;
      this.modal = false;
      this.getUserCards();
    },
    isCardExpired(cardExpiration: string) {
      return isCardExpired(cardExpiration);
    },
    async getUserCards() {
      const {
        data: { response: data },
      } = await adminDashboardRequests.getUserCards(this.screenTrackingId);
      this.cards = data;
      const defaultCard = data.find((card: any) => card.isDefault === true);
      this.initialDC = defaultCard._id;
      this.currentDC = defaultCard._id;

      const defaultCardAutopay = data.find(
        (card: any) => card.defaultForAutopay === true,
      );
      this.initialDCAutopay = defaultCardAutopay._id;
      this.currentDCAutopay = defaultCardAutopay._id;

      const checkbox = document.querySelectorAll(".checks");
      this.setDefaultCard(this.currentDC, checkbox, this.cards);

      const checksAutopay = document.querySelectorAll(".checksAutopay");
      this.setDefaultCard(this.currentDCAutopay, checksAutopay, this.cards);
    },
    async reloadPage() {
      try {
        const {
          data: { response: userCards, duplicatesList: cardDuplicates },
        } = await adminDashboardRequests.getUserCards(this.screenTrackingId);
        this.cards = userCards;
        this.duplicatesList = cardDuplicates;
        const defaultCard = userCards.find(
          (card: any) => card.isDefault === true,
        );
        this.initialDC = defaultCard?._id;
        this.currentDC = defaultCard?._id;

        const { data } = await adminDashboardRequests.getPaymentManagement(
          this.screenTrackingId,
        );
        this.paymentManagement = data.response;

        const defaultCardAutopay = userCards.find(
          (card: any) => card.defaultForAutopay === true,
        );
        this.initialDCAutopay = defaultCardAutopay?._id;
        this.currentDCAutopay = defaultCardAutopay?._id;

        const {
          data: {
            userBankData: userBankAccounts,
            duplicatesList: accountDuplicates,
          },
        } = await adminDashboardRequests.listUserBanks(this.screenTrackingId);

        this.bankAccounts = userBankAccounts;
        this.duplicatesList = [...this.duplicatesList, ...accountDuplicates];
      } catch (error) {
        if (error.response.status === 404) {
          return;
        }

        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }
      }
    },
    async resignEFTA(cardToken: string) {
      this.isLoading = true;
      await adminDashboardRequests.resignEFTA(this.screenTrackingId, cardToken);
      this.isLoading = false;
      await this.reloadPage();
      await this.$swal({
        title: "Success!",
        text: "EFTA Resigned!",
        icon: "success",
      });
      this.isLoading = false;
    },
    async removeCardOrBank(paymentType: "ACH" | "CARD", paymentId: string) {
      const payload = {
        screenTrackingId: this.screenTrackingId,
        paymentType,
        paymentId,
      };
      await adminDashboardRequests.removeCardOrAchAccount(payload);
      await this.reloadPage();
      await this.$swal({
        title: "Success!",
        text: "Account has been removed",
        icon: "success",
      });
    },
    saveDefaultPaymentMethod: async function () {
      await adminDashboardRequests.toggleDefaultPaymentMethod(
        this.paymentManagement._id,
        this.selectedAutopayMethod,
      );
      await adminDashboardRequests.setDefaultCardForAutopay(
        this.screenTrackingId,
        this.currentDCAutopay,
      );
      this.paymentMethodControll = false;
      this.getUserCards();
    },
  },

  async mounted() {
    try {
      /*
      const userCardsPromise = adminDashboardRequests.getUserCards(
        this.screenTrackingId
      );

      const userBankAccountsPromise = adminDashboardRequests.listUserBanks(
        this.screenTrackingId
      );

      interface ResponseFormat extends AxiosResponse {
        data: Array<Record<string, unknown>>
      }
      const processData = ([{ data: userCards }, { data: userBankAccounts }]: Array<ResponseFormat>) => {
        this.cards = userCards;
        this.bankAccounts = userBankAccounts;
        const defaultCard = userCards.find(
          (card: any) => card.isDefault === true
        );
        this.initialDC = defaultCard?.paymentMethodToken;
        this.currentDC = defaultCard?.paymentMethodToken;

        const defaultCardAutopay = userCards.find(
          (card: any) => card.defaultForAutopay === true
        );
        this.initialDCAutopay = defaultCardAutopay?._id;
        this.currentDCAutopay = defaultCardAutopay?._id;
      };

      await Promise.all([userCardsPromise, userBankAccountsPromise]).then(processData.bind(this));
      */
      const {
        data: { response: userCards, duplicatesList: cardDuplicates },
      } = await adminDashboardRequests.getUserCards(this.screenTrackingId);
      this.cards = userCards;
      this.duplicatesList = cardDuplicates;
      const defaultCard = userCards.find(
        (card: any) => card.isDefault === true,
      );
      this.initialDC = defaultCard?._id;
      this.currentDC = defaultCard?._id;

      const {
        data: {
          userBankData: userBankAccounts,
          duplicatesList: accountDuplicates,
        },
      } = await adminDashboardRequests.listUserBanks(this.screenTrackingId);

      this.bankAccounts = userBankAccounts;
      this.duplicatesList = [...this.duplicatesList, ...accountDuplicates];

      const { data } = await adminDashboardRequests.getPaymentManagement(
        this.screenTrackingId,
      );
      this.paymentManagement = data.response;
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }

      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },
});
