
import Vue from "vue";

import states from "@/helpers/states";
import { errorHandler } from "@/api/error-handler";
import CardValidator from "card-validator";
import { getApplicationData } from "@/user-application/application/api";
import IOffer from "@/user-application/offers/types/IOffer";
import { getIp } from "@/user-application/helpers";
import showErrorToast from "@/helpers/errorToast";
import { mapMutations } from "vuex";
import {
  addCard,
  saveEFTA,
  addBank,
} from "@/user-application/repayment/api";
import { enableAutopay } from "@/user-application/dashboard/api";

const AMEX_MASK = '#### ###### #####'
const VISA_MASK = '#### #### #### ####'
export default Vue.extend({
  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
    paymentManagementId: {
      required: true,
      type: String,
    },
    autopayStatus: {
      required: true,
      type: Boolean,
    },
    addCardFlag: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      cardCode: null as null | string,
      userData: null as null | Record<string, any>,
      cardHolder: null as null | string,
      cardNumber: null as null | string,
      city: null as null | string,
      expirationDate: null as null | string,
      firstName: null as null | string,
      isLoading: false,
      lastName: null as null | string,
      modal: this.addCardFlag,
      selectedState: null as null | string,
      states: states as { state: string; stateCode: null | string }[],
      street: null as null | string,
      zipCode: null as null | string,
      isDefaultCard: false,
      isEFTAChecked: false,
      isAutopayChecked: this.addCardFlag,
      autopayStatusData: this.autopayStatus,
      checked: false,
      isSubmitting: false,
      applicationReference: null as null | string,
      phones: null as null | { phone: string; type: string }[],
      selectedOffer: null as null | IOffer,
      ricSignature: null as null | string,
      ip: null as null | string,
      bankName: null as null | string,
      accountHolder: null as null | string,
      routingNumber: null as null | string,
      accountNumber: null as null | string,
    };
  },
  computed: {
    isAmex(): boolean {
      if (this.cardNumber) {
        return (this.cardNumber.substr(0, 2) === '37' || this.cardNumber.substr(0, 2) === '34')
      }
      return false
    },
    amexOrVisa(): string {
      return this.isAmex ? AMEX_MASK : VISA_MASK
    },
    amexOrVisaSecurityCode(): string {
      return this.isAmex ? "####" : "###"
    },
    amexOrVisaLength(): number {
      return this.isAmex ? 17 : 19
    },
    amexOrVisaSecLength(): number {
      return this.isAmex ? 4 : 3
    },
    parsedStates(): { value: string | null; text: string }[] {
      return this.states.map((state) => {
        return {
          value: state.stateCode,
          text: state.state,
        };
      });
    },
  },
  methods: {
    ...mapMutations(["setIsLoading"]),
    showAddCardModal() {
      this.modal = true;
    },
    async getUserinfo() {
      try {
        const [
          applicationDataResponse,
        ] = await Promise.all([getApplicationData()]);
        this.userData = applicationDataResponse.data;
      } catch (error) {
        return error;
      }
    },
    async showHomeAddress() {
      const userData = await this.getUserinfo();

      if (this.checked == true) {
        this.firstName = this.userData ? this.userData.firstName : null;
        this.lastName = this.userData ? this.userData.lastName : null;
        this.selectedState = this.userData ? this.userData.state : null;
        this.zipCode = this.userData ? this.userData.zip : null;
        this.city = this.userData ? this.userData.city : null;
        this.street = this.userData ? this.userData.street : null;
        const fName = this.$refs.firstName as any;
        const lName = this.$refs.lastName as any;
        const sName = this.$refs.street as any;
        const cName = this.$refs.city as any;
        const zName = this.$refs.zipCode as any;
        fName.focus();
        lName.focus();
        sName.focus();
        cName.focus();
        zName.focus();
        fName.focus();
      }
      else {
        this.firstName = null;
        this.lastName = null;
        this.selectedState = null;
        this.zipCode = null;
        this.city = null;
        this.street = null;
      }
    },
    onCancel() {
      this.street = null;
      this.city = null;
      this.firstName = null;
      this.lastName = null;
      this.selectedState = null;
      this.zipCode = null;
      this.cardCode = null;
      this.cardNumber = null;
      this.expirationDate = null;
      this.selectedState = null;
      this.modal = false;
      this.isDefaultCard = false;
      this.isSubmitting = false;

      this.$nextTick(() => {
        (this.$refs.observer as any).reset();
      });
    },
    isLetter(event: KeyboardEvent) {
      const char = event.key;
      if (!/^[a-zA-Z\s]+$/.test(char)) {
        event.preventDefault();
      }
    },
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    async saveEFTAAgreement() {
      const [expMonth, expYear] = this.expirationDate?.split("/") ?? "";
      const cardNumber = this.cardNumber?.split(" ").join("");
      const userData = await this.getUserinfo();
      const requestBody = {
        userId: this.userData ? this.userData.userId : "",
        applicationReference: this.userData ? this.userData.applicationReference : "",
        cardCode: this.cardCode || "",
        cardHolder: this.cardHolder || "",
        cardIssuer: this.cardIssuer() || "",
        cardNumber: cardNumber || "",
        city: this.city || "",
        expirationMonth: expMonth || "",
        expirationYear: expYear || "",
        firstName: this.userData ? this.userData.firstName : "",
        lastName: this.userData ? this.userData.lastName : "",
        phoneNumber: this.userData ? this.userData.phones[0].phone : "",
        selectedOffer: this.userData ? this.userData.selectedOffer : {},
        selectedState: this.selectedState || "",
        signature: this.userData ? this.userData.ricSignature : "",
        street: this.street || "",
        zipCode: this.zipCode || "",
      };

      // await saveEFTA(requestBody);
    },
    async saveCard() {
      this.isSubmitting = true;
      const [expMonth, expYear] = this.expirationDate?.split("/") ?? "";
      const cardNumber = this.cardNumber?.split(" ").join("");
      const requestBody = {
        billingAddress1: this.street || "",
        billingCity: this.city || "",
        billingFirstName: this.firstName || "",
        billingLastName: this.lastName || "",
        billingState: this.selectedState || "",
        billingZip: this.zipCode || "",
        cardCode: this.cardCode || "",
        cardNumber: cardNumber || "",
        expMonth,
        expYear,
        paymentType: 'CARD',
        screenTrackingId: this.screenTrackingId,
        nameOnCard: this.cardHolder,
      };
      const { data } = await addCard(requestBody);
    },
    cardIssuer() {
      if (this.cardNumber) {
        const result = CardValidator.number(this.cardNumber);
        return result.card?.niceType;
      }
      return null;
    },

    async onSubmit(type = "card") {
      this.isSubmitting = true;
      this.isDefaultCard = true;
      this.isLoading = true;
      try {
        if (type === 'card') {
          if (!this.cardIssuer()) {
            this.cardNumber = "";
            this.cardCode = "";
            this.expirationDate = "";
            this.cardHolder = "";
            await this.$swal({
              title: "Error",
              text: `${"The card info you entered is invalid!"}`,
              icon: "error",
            });
            this.isLoading = false;
            return;
          }
          const promiseArray = [this.saveEFTAAgreement(), this.saveCard()];
          await Promise.all(promiseArray);
          if (this.isAutopayChecked) {
            const requestBody = { paymentManagementId: this.paymentManagementId }
            await enableAutopay(requestBody);
            this.autopayStatusData = true;
          }
          await this.$swal({
            title: "Success!",
            text: "Card successfully added.",
            icon: "success",
          });
          this.isLoading = false;
          this.modal = false;
          this.$emit("reloadPage");
        } else if (type === 'ACH') {
          const requestBody = {
            bankName: this.bankName || "",
            accountHolder: this.accountHolder || "",
            routingNumber: this.routingNumber || "",
            accountNumber: this.accountNumber || "",
          };

          await addBank(
            this.screenTrackingId,
            requestBody
          );
          this.isLoading = false;

          await this.$swal({
            title: "Success!",
            text: "ACH successfully added.",
            icon: "success",
          });

          this.modal = false;
          this.$emit("reloadPage");
        }

      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isLoading = false;
        this.modal = false;
      }
    },
  },
});
