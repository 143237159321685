

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { getApplicationData, updateUserCard, removeCardOrAchAccount } from "@/user-application/application/api";
import { getDashboardData } from "@/user-application/dashboard/api";
import Vue from "vue";
import AddCardButton from "@/user-application/dashboard/components/AddCardButton.vue";
import { isCardExpired } from "@/admin-dashboard/helpers";
import { errorHandler } from "@/api/error-handler";

export default Vue.extend({
  components: { AddCardButton, },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
    addCardFlag: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      cards: [] as Array<any>,
      modal: false,
      currentDC: {},
      initialDC: {},
      isDefault: false,
      paymentManagementData: {},
      intialDefaultCardId: null,
      isLoading: true,
      componentKey: 0,
      userBankAccount: [] as Array<any>,
    };
  },

  methods: {
    setDefaultCard(id: any, checkbox: any, cards: any) {
      //uncheck other boxes
      for (const box of checkbox) {
        if (box.id !== id) {
          box.checked = false;
        }
      }
      //set default card
      for (const box of checkbox) {
        if (box.id == id) {
          box.checked = true;
          const defaultCard = cards.find((card: { _id: any; }) => card._id == box.id);
          return defaultCard._id;
        }
      }
    },
    check: function (e: any, method: 'card' | 'bank') {
      this.modal = true;
      const checkbox = document.querySelectorAll('.checks');
      const appliedMethod = method === 'card' ? this.cards : this.userBankAccount;
      this.currentDC = this.setDefaultCard(e.target.id, checkbox, appliedMethod);
    },
    hideModal(e: any) {
      this.modal = false;
      //revert to initialDC
      const checkbox = document.querySelectorAll('.checks');
      this.setDefaultCard(this.initialDC, checkbox, this.cards);
    },
    separateCardAndAchAccount: function (userCard: Array<any>) {
      if (!userCard) return;
      this.userBankAccount = userCard.filter((item) => item.paymentType === 'ACH');
      this.cards = userCard.filter((item) => item.paymentType === 'CARD');
    },
    saveDefaultCard: async function () {

      const paymentMethodToken: any = this.currentDC;
      // update card
      const requestBody = {
        // paymentMethodToken: paymentMethodToken,
        paymentId: paymentMethodToken
      }
      await updateUserCard(requestBody);
      this.initialDC = this.currentDC;
      this.modal = false;
      this.getUserCards();
    },
    isCardExpired(cardExpiration: string) {
      return isCardExpired(cardExpiration);
    },
    async getUserCards() {
      const [
        applicationDataResponse,
        dashboardDataResponse,
      ] = await Promise.all([getApplicationData(), getDashboardData()]);
      const {
        userAccountsData,
      } = dashboardDataResponse.data;
      this.cards = userAccountsData;
      const defaultCard = userAccountsData.find((card: any) => card.isDefault === true);
      this.separateCardAndAchAccount(userAccountsData);
      this.initialDC = defaultCard._id;
      this.currentDC = defaultCard._id;
      const checkbox = document.querySelectorAll('.checks');
      this.setDefaultCard(this.currentDC, checkbox, this.cards);
    },
    async reloadPage() {
      try {
        const [
          applicationDataResponse,
          dashboardDataResponse,
        ] = await Promise.all([getApplicationData(), getDashboardData()]);
        const {
          userAccountsData,
        } = dashboardDataResponse.data;
        this.cards = userAccountsData;
        this.separateCardAndAchAccount(userAccountsData);
        const defaultCard = userAccountsData.find((card: any) => card.isDefault === true);
        if (defaultCard) {
          this.initialDC = defaultCard.paymentMethodToken;
          this.currentDC = defaultCard.paymentMethodToken;
          // this.saveDefaultCard();
        }

      } catch (error) {
        if (error.response.status === 404) {
          return;
        }

        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }
      }
    },
    async removeCardOrBank(paymentType: "ACH" | "CARD", paymentId: string) {
      const payload = {
        screenTrackingId: this.screenTrackingId,
        paymentType,
        paymentId,
      };
      await removeCardOrAchAccount(payload);
      await this.$swal({
        title: "Success!",
        text: "Account has been removed",
        icon: "success",
      });
      this.reloadPage();
    },
  },

  async mounted() {
    try {
      const [
        applicationDataResponse,
        dashboardDataResponse,
      ] = await Promise.all([getApplicationData(), getDashboardData()]);
      const {
        userAccountsData,
        paymentManagementData,
      } = dashboardDataResponse.data;
      this.paymentManagementData = paymentManagementData;
      this.cards = userAccountsData;
      this.separateCardAndAchAccount(userAccountsData);
      const defaultCard = userAccountsData.find((card: any) => card.isDefault === true);
      if (defaultCard) {
        this.initialDC = defaultCard.paymentMethodToken;
        this.currentDC = defaultCard.paymentMethodToken;
      }
      this.isLoading = false;
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }

      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },
});
