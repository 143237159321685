
import Vue from "vue";

import Layout from "@/views/layouts/admin/Layout.vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import showErrorToast from "@/helpers/errorToast";

export default Vue.extend({
  components: {
    Layout,
  },

  data() {
    return {
      createdAt: null as null | string,
      ip: null as null | string,
      jsonData: null as null | string,
      loanReference: null as null | string,
      logReference: null as null | string,
      message: null as null | string,
      moduleName: null as null | string,
      requestUri: null as null | string,
    };
  },

  async created() {
    try {
      const logActivityId = this.$route.params.id;
      const { data } = await adminDashboardRequests.getLogActivityById(
        logActivityId
      );
      const {
        createdAt,
        ip,
        jsonData,
        loanReference,
        logReference,
        message,
        moduleName,
        requestUri,
      } = data;

      this.createdAt = createdAt;
      this.ip = ip;
      this.jsonData = jsonData;
      (this.loanReference = loanReference), (this.logReference = logReference);
      this.message = message;
      this.moduleName = moduleName;
      this.requestUri = requestUri;
    } catch (error) {
      let errorMessage = "";
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else {
        errorMessage = error.message;
      }

      showErrorToast(this, "error", errorMessage);
    }
  },
});
