var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseTable',{attrs:{"title":_vm.title,"fields":_vm.fields,"rows":_vm.rows,"onSort":_vm.onSort},scopedSlots:_vm._u([{key:"cell(check)",fn:function(data){return [_c('div',[_c('b-form-row',[_c('input',{staticClass:"isCollection",attrs:{"type":"checkbox","id":data.item.screenTrackingId},domProps:{"value":data.item.screenTrackingId},on:{"change":function($event){return _vm.onCheckboxInput(
              $event,
              data.item.email,
              _vm.title,
              data.item.screenTrackingId,
              data.item.status,
            )}}}),_c('label',{staticClass:"collectionLabel",attrs:{"for":"checkbox"}},[_vm._v(_vm._s(data.item.idKeyName))])])],1)]}},{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
        path: `/admin/loan-details/${data.item.screenTrackingId}`,
        query: { status: 'MR' },
      }}},[_vm._v(_vm._s(data.item.name))])]}},{key:"cell(origin)",fn:function(data){return [(data.item.leadType === 'default')?_c('span',[_vm._v("Loan Retriever")]):(
        data.item.origin === 'LEAD' && data.item.leadType !== 'default'
      )?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(data.item.leadType))+" ")]):_c('span',[_vm._v(_vm._s(data.item.leadType))])]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("string")(data.item.status))+" ")]}},{key:"cell(approvedUpTo)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.approvedUpTo))+" ")]}},{key:"cell(selectedAmount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("currency")(data.item.selectedAmount))+" ")]}},{key:"cell(phone)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("phone")(data.item.phone))+" ")]}},{key:"cell(dateCreated)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("timestamp")(data.item.dateCreated))+" ")]}},{key:"cell(assignedTo)",fn:function(data){return [_c('label',{staticClass:"data",attrs:{"for":"assignedTo"}},[_vm._v(" "+_vm._s(data.item.collectionAdmin)+" ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }