
import Vue from "vue";

import CommunicationTabTable from "@/admin-dashboard/audit-log/components/CommunicationTabTable.vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import showErrorToast from "@/helpers/errorToast";
import { getUserData } from "@/admin-dashboard/authentication/helpers";
import {  getAdminRoles } from "@/admin-dashboard/helpers";


export default Vue.extend({
  components: {
    CommunicationTabTable,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
    userEmail: {
      required: false,
      type: String || null,
    },
  },

  data() {
    return {
      method: ["Email", "Cell phone"],
      selectedMethod: "" as string,
      summary: "" as string,
      email: null as null | string,
      cellphone: null as null | number,
      isSubmitting: false as boolean,
      id: null as null | string,
      userData: getUserData(),
      adminRoles: getAdminRoles(),
    };
  },
  async mounted() {
    this.email = this.userEmail;
  },

  methods: {
    async editEvent(data:any){
      console.log(data);
      this.selectedMethod = data.communicationHistory[0].method.toLowerCase() === 'email' ? 'Email' : 'Cell phone';
      this.summary = data.communicationHistory[0].summary;
      this.email = data.communicationHistory[0].email;
      this.cellphone = data.communicationHistory[0].cellPhone;
      this.email = data.communicationHistory[0].email;
      this.id = data.id;
    },
    async deleteEvent(data:any){
      const result = await this.$swal({
        title: `Are you sure you want to delete log?`,
        showCancelButton: true,
        icon: "question",
        reverseButtons: false,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`,
      });
      if (result.isConfirmed) {
        console.log(data.id);
          try {
            this.isSubmitting = true;
            await adminDashboardRequests.deleteCommunicationHistory(data.id);
            this.isSubmitting = false;
            await (this.$refs.communicationTable as any).fetchTable({
              status: "",
              page: 1,
              perPage: 25,
            });
          } catch (error) {
            this.isSubmitting = false;
            let errorMessage = "";

            if (error.response?.data?.error) {
              errorMessage = error.response.data.error;
            } else if (error.response?.data?.message) {
              errorMessage = error.response.data.message;
            } else {
              errorMessage = error.message;
            }

            showErrorToast(this, "error", errorMessage);
        }
        
      }
    },
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    cleanState() {
      this.selectedMethod = "";
      this.summary = "";
      this.email = null;
      this.cellphone = null;
      this.id = null;

      this.$nextTick(() => {
        (this.$refs.observer as any).reset();
      });
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;
        const requestBody = {
          date: new Date(),
          method: this.selectedMethod,
          summary: this.summary,
          email: this.email,
          cellPhone: this.cellphone,
          id: this.id
        };

        if(this.id) {
          const result = await adminDashboardRequests.updateCommunicationHistory(
            this.id,
            requestBody
          );
        } else {
          const result = await adminDashboardRequests.addCommunicationHistory(
            this.screenTrackingId,
            requestBody
          );
        }
        
        // reload comments table
        await (this.$refs.communicationTable as any).fetchTable({
          status: "",
          page: 1,
          perPage: 25,
        });

        this.cleanState();
        showErrorToast(this, "success", "Communication Added Successfully");

        this.isSubmitting = false;
      } catch (error) {
        this.isSubmitting = false;
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
});
