
import Vue from "vue";
import { mapMutations } from "vuex";
import moment from "moment";
import { extend } from "vee-validate";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import states from "@/helpers/states";
import showErrorToast from "@/helpers/errorToast";
import Layout from "../../layout/pages/Layout.vue";
import { createUser, runCreditReport } from "../api";
import { login } from "@/user-application/authentication/api";

const incomeMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 7,
});

const requestedAmountMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 4,
});

extend("password", (value: string) => {
  if (/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/.test(value)) {
    return true;
  }
  return "Password should be at least 8 characters long and should have at least 1 number";
});

export default Vue.extend({
  components: {
    Layout,
  },

  props: {
    practiceManagementId: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      annualIncome: null as null | string,
      city: null as null | string,
      days: [] as { value: string; text: string }[],
      driversLicenseNumber: null as null | string,
      driversLicenseState: null as null | string,
      email: null as null | string,
      errors: {} as Record<string, unknown>,
      esignatureAgreement: false,
      FCRAAgreement: false,
      firstName: null as null | string,
      ip: null as null | string,
      inquiryAgreement: false,
      isSubmitting: false,
      lastName: null as null | string,
      incomeMask,
      requestedAmountMask,
      middleInitial: null,
      months: [] as { text: string; value: string }[],
      password: null as null | string,
      passwordConfirmation: null as null | string,
      phoneRecords: [{ phone: "", type: "home" }] as {
        phone: string;
        type: "mobile" | "home" | "office";
      }[],
      requestedAmount: null as null | string,
      selectedDay: null as null | string,
      selectedDriversLicenseState: null as null | string,
      selectedMonth: null as null | string,
      selectedState: null as null | string,
      selectedYear: null as null | string,
      showModalCallPolicy: false,
      showModalCredit: false,
      showModalElectr: false,
      showModalSMS: false,
      socialSec1: null as null | string,
      socialSec2: null as null | string,
      socialSec3: null as null | string,
      state: null as null | string,
      states: states as { state: string; stateCode: null | string }[],
      street: null as null | string,
      term: null as null | string,
      termsAgreement: false,
      unitApt: null as null | string,
      years: [] as { text: string; value: string }[],
      zipCode: null as null | string,
    };
  },

  computed: {
    todayDate(): string {
      return moment().format("MM/DD/YYYY");
    },

    parsedStates(): { value: string | null; text: string }[] {
      return this.states.map((state) => {
        return {
          value: state.stateCode,
          text: state.state,
        };
      });
    },
    // TODO refactor this
    parsedAnualIncome(): number {
      if (typeof this.annualIncome === "string") {
        return +this.annualIncome.replace(/[$,]/g, "");
      } else {
        return 0;
      }
    },
    parsedRequestedAmount(): number {
      if (typeof this.requestedAmount === "string") {
        return +this.requestedAmount.replace(/[$,]/g, "");
      } else {
        return 0;
      }
    },
    phoneTypes(): { value: string | null; text: string }[] {
      return [
        {
          value: null,
          text: "Select",
        },
        {
          value: "mobile",
          text: "Mobile",
        },
        {
          value: "home",
          text: "Home",
        },
        {
          value: "office",
          text: "Office",
        },
      ];
    },
    parsedPhones(): { phone: string; type: "mobile" | "home" | "office" }[] {
      return this.phoneRecords.map((item) => {
        return { phone: item.phone.replace(/[()\-\s]/g, ""), type: item.type };
      });
    },
  },

  beforeMount() {
    this.getIp();
    this.getdaysInMonth();
    this.getMonths();
    this.getYears();
  },

  methods: {
    ...mapMutations(["setIsLoading"]),
    currencyToNumber(value: string): number {
      return +value.replace(/[$,]/g, "");
    },
    ssnInputFocus() {
      if (this.socialSec1 && this.socialSec1.length == 3) {
        const ss2 = this.$refs.socialSec2 as any;
        ss2.focus();
      }
      if (
        this.socialSec2 &&
        this.socialSec2.length == 2 &&
        this.socialSec1 &&
        this.socialSec1.length == 3
      ) {
        const ss3 = this.$refs.socialSec3 as any;
        ss3.focus();
      }
    },
    getMinimumAllowedBirthDate(year: string) {
      const today = moment();
      const todayDay = today.date();
      const thisMonth = today.month();
      if (year === this.years[0].value) {
        this.days = this.days.filter((day) => {
          return +day.value >= todayDay;
        });
        if (this.selectedDay && +this.selectedDay < +this.days[0].value) {
          this.selectedDay = this.days[0].value;
        }

        this.months = this.months.filter((month) => {
          return +month.value;
        });

        if (this.selectedMonth && +this.selectedMonth < +this.months[0].value) {
          this.selectedMonth = this.months[0].value;
        }
        if (this.selectedMonth && +this.selectedMonth > this.months.length) {
          this.selectedMonth = this.months[0].value;
        }
      } else {
        let month = "";
        if (!this.selectedMonth) {
          const parsedMonth: string | number = thisMonth + 1;
          month = parsedMonth <= 9 ? `0${parsedMonth}` : `${parsedMonth}`;
        } else {
          month = this.selectedMonth;
        }

        this.getdaysInMonth(month);
        this.getMonths();
      }
      this.calculateAge();
    },
    calculateAge() {
      if (this.selectedDay && this.selectedMonth && this.selectedYear) {
        const dob =
          this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay;

        const age = moment().diff(dob, "years", true);

        if (age < 18) {
          this.selectedMonth = null;
          this.selectedDay = null;
          this.selectedYear = null;
          const ageT = this.$refs.ageText1 as any;
          ageT.hidden = false;
        } else {
          const ageT = this.$refs.ageText1 as any;
          ageT.hidden = true;
        }
      }
    },
    getdaysInMonth(month?: string) {
      let daysInMonth = 31;
      const year = this.selectedYear ? +this.selectedYear : moment().year();
      const days: { value: string; text: string }[] = [];

      if (month) {
        switch (month) {
          case "04":
          case "06":
          case "09":
          case "11":
            daysInMonth = 30;
            break;
          case "02":
            // leap year
            if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
              daysInMonth = 29;
            } else {
              daysInMonth = 28;
            }
            break;
        }
      }

      for (let index = 1; index <= daysInMonth; index++) {
        if (index <= 9) {
          days.push({ value: `0${index}`, text: `0${index}` });
        } else {
          days.push({ value: `${index}`, text: `${index}` });
        }
      }

      this.days = days;
      // reset the selected day if previous selected day has more days and the last day of the month is greater than the last day of current month
      if (
        this.selectedDay &&
        +this.selectedDay > +days[days.length - 1].value
      ) {
        this.selectedDay = null;
      }

      this.calculateAge();
    },
    getMonths() {
      this.months = [
        {
          value: "01",
          text: "January",
        },
        {
          value: "02",
          text: "February",
        },
        {
          value: "03",
          text: "March",
        },
        {
          value: "04",
          text: "April",
        },
        {
          value: "05",
          text: "May",
        },
        {
          value: "06",
          text: "June",
        },
        {
          value: "07",
          text: "July",
        },
        {
          value: "08",
          text: "August",
        },
        {
          value: "09",
          text: "September",
        },
        {
          value: "10",
          text: "October",
        },
        {
          value: "11",
          text: "November",
        },
        {
          value: "12",
          text: "December",
        },
      ];
    },
    getYears() {
      const years: { value: string; text: string }[] = [];
      const currentYear = moment().year();
      const firstYear = currentYear - 100;
      let year = currentYear - 18;

      while (year >= firstYear) {
        years.push({ value: `${year}`, text: `${year}` });
        year--;
      }

      this.years = years;
    },

    onCheckboxInput(checkboxName: string) {
      if (this.errors[checkboxName]) {
        this.errors[checkboxName] = null;
      }
    },
    getIp() {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ip = ip;
        });
    },
    isLetter(event: KeyboardEvent) {
      const char = event.key;
      if (!/^[a-zA-Z\s]+$/.test(char)) {
        event.preventDefault();
      }
    },
    addPhoneRecord() {
      if (this.phoneRecords.length >= 5) {
        this.errors.phone = "Maximum of 5 phones allowed.";
        return;
      }
      this.phoneRecords.push({ phone: "", type: "home" });
    },
    removePhoneRecord(index: number) {
      this.phoneRecords.splice(index, 1);
    },
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    getPasswordValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated
        ? valid &&
            this.password &&
            /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/.test(this.password) &&
            this.passwordConfirmation === this.password
        : null;
    },
    getAnnualIncomeValidationState() {
      const annualIncome = this.parsedAnualIncome;
      if (!annualIncome) {
        return null;
      }
      if (annualIncome < 12000) {
        return false;
      }
      return true;
    },
    getRequestedAmountValidationState() {
      const requestedAmount = this.parsedRequestedAmount;
      if (!requestedAmount) {
        return null;
      }
      if (requestedAmount < 1000 || requestedAmount > 7000) {
        return false;
      }

      return true;
    },
    async getValidationApply() {
      try {
        this.setIsLoading(true);
        this.isSubmitting = true;
        const createUserRequestBody = {
          annualIncome: this.parsedAnualIncome,
          city: this.city ? this.city : "",
          dateOfBirth:
            this.selectedYear && this.selectedMonth && this.selectedDay
              ? moment(
                  `${this.selectedYear}-${this.selectedMonth}-${this.selectedDay}`
                )
                  .startOf("day")
                  .toISOString()
              : "",
          driversLicenseNumber: this.driversLicenseNumber
            ? this.driversLicenseNumber
            : "",
          driversLicenseState: this.driversLicenseState
            ? this.driversLicenseState
            : "",
          email: this.email ? this.email.toLowerCase().trim() : "",
          firstName: this.firstName ? this.firstName : "",
          lastName: this.lastName ? this.lastName : "",
          password: this.password ? this.password : "",
          phones: this.parsedPhones,
          practiceManagement: this.practiceManagementId,
          requestedAmount: this.requestedAmount
            ? this.currencyToNumber(this.requestedAmount)
            : 0,
          source: "web" as "web" | "lead-list",
          ssnNumber:
            this.socialSec1 && this.socialSec2 && this.socialSec3
              ? `${this.socialSec1}${this.socialSec2}${this.socialSec3}`
              : "",
          state: this.selectedState ? this.selectedState : "",
          street: this.street ? this.street : "",
          unitApt: this.unitApt ? this.unitApt : "",
          zipCode: this.zipCode ? this.zipCode : "",
        };
        await createUser(createUserRequestBody);
        const loginRequestBody = {
          email: this.email ? this.email.toLowerCase().trim() : "",
          password: this.password ? this.password : "",
        };
        const { data: jwtToken } = await login(loginRequestBody);
        localStorage.setItem("userToken", JSON.stringify(jwtToken));

        const { data: creditReport } = await runCreditReport(jwtToken.token);
        if (!creditReport.isLoanApproved) {
          await this.$router.push({ name: "denied" });
        } else {
          this.setIsLoading(false);
          this.isSubmitting = false;
          await this.$router.push({ name: "offers" });
        }
      } catch (error) {
        this.setIsLoading(false);
        this.isSubmitting = false;
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
    async onSubmit() {
      let isInvalid = false;
      if (!this.FCRAAgreement) {
        this.errors.FCRAAgreement =
          "Please agree with our Fair Credit Reporting Act policy.";
        isInvalid = true;
      }
      if (!this.inquiryAgreement) {
        this.errors.inquiryAgreement = "Please agree with our inquiry policy.";
        isInvalid = true;
      }
      if (!this.termsAgreement) {
        this.errors.termsAgreement =
          "Please agree with our Terms of Use policy.";
        isInvalid = true;
      }
      if (!this.esignatureAgreement) {
        this.errors.esignatureAgreement =
          "Please agree with our e-signature policy.";
        isInvalid = true;
      }

      if (isInvalid) {
        return;
      }

      try {
        this.isSubmitting = true;
        this.setIsLoading(true);
        const createUserRequestBody = {
          annualIncome: this.parsedAnualIncome,
          city: this.city ? this.city : "",
          dateOfBirth:
            this.selectedYear && this.selectedMonth && this.selectedDay
              ? moment(
                  `${this.selectedMonth}-${this.selectedDay}-${this.selectedYear}`,
                  "MM-DD-YYYY"
                ).format("MM-DD-YYYY")
              : "",
          driversLicenseNumber: this.driversLicenseNumber
            ? this.driversLicenseNumber
            : "",
          driversLicenseState: this.driversLicenseState
            ? this.driversLicenseState
            : "",
          email: this.email ? this.email.toLowerCase().trim() : "",
          firstName: this.firstName ? this.firstName : "",
          lastName: this.lastName ? this.lastName : "",
          password: this.password ? this.password : "",
          phones: this.parsedPhones,
          practiceManagement: this.practiceManagementId,
          requestedAmount: this.requestedAmount
            ? this.currencyToNumber(this.requestedAmount)
            : 0,
          source: "web" as "web" | "lead-list",
          ssnNumber:
            this.socialSec1 && this.socialSec2 && this.socialSec3
              ? `${this.socialSec1}${this.socialSec2}${this.socialSec3}`
              : "",
          state: this.selectedState ? this.selectedState : "",
          street: this.street ? this.street : "",
          unitApt: this.unitApt ? this.unitApt : "",
          zipCode: this.zipCode ? this.zipCode : "",
        };
        if (this.parsedAnualIncome >= 12000) {
          if (this.parsedRequestedAmount >= 1000 && this.parsedRequestedAmount <= 7000) {
            // Creates base user and screen_tracking documents with no offer info
            await createUser(createUserRequestBody);
            const loginRequestBody = {
              email: this.email ? this.email.toLowerCase().trim() : "",
              password: this.password ? this.password : "",
            };
            const { data: jwtToken } = await login(loginRequestBody);
            localStorage.setItem("userToken", JSON.stringify(jwtToken));
            // Pull credit report form TU, create payment_management document if denied from creditreport
            // Offer is generated when loading the offers page
            const { data: creditReport } = await runCreditReport(
              jwtToken.token
            );
            if (!creditReport.isLoanApproved) {
              await this.$router.push({ name: "denied" });
            } else {
              this.setIsLoading(false);
              this.isSubmitting = false;
              await this.$router.push({ name: "offers" });
            }
          } else {
            this.setIsLoading(false);
            this.isSubmitting = false;
            this.$swal
              .fire({
                title: "Requested amount must be between $1000 and $7000",
                showCancelButton: false,
                icon: "warning",
                reverseButtons: true,
                confirmButtonText: `Ok`,
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  return false;
                }
              });
          }
        } else {
          this.setIsLoading(false);
          this.isSubmitting = false;
          this.$swal
            .fire({
              title:
                "Your gross annual income is less than $12k per year, would you like to continue? Press “Edit” to reenter your annual income amount.",
              showCancelButton: true,
              icon: "warning",
              reverseButtons: true,
              confirmButtonText: `Continue`,
              cancelButtonText: `Edit`,
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.getValidationApply();
              } else if (result.isDenied) {
                return false;
              }
            });
        }
      } catch (error) {
        this.setIsLoading(false);
        this.isSubmitting = false;
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
});
