
import Vue from "vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import selecttime from "@/helpers/selecttime";
import { adminDashboardRequests } from "@/api/admin-dashboard";

export default Vue.extend({
  components: {
    Datepicker,
  },

   props: {
    dateValue: {
      required: true,
      type: String,
    },
    timeValue: {
      required: true,
      type: String,
    },
    scheduleValue: {
      required: true,
      type: String,
    },
    amountValue: {
      required: true,
      type: Number,
    },
    idValue: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      modal: false,
      adminUsers: [] as {
        email: string;
        role: string;
        id: string;
      }[],
      selectedCard: "",
      isLoading: false,
      openDate: this.dateValue,
      selectedState: this.timeValue,
      amount: this.amountValue,
      timeSlots: selecttime as { value: string }[],
      disabledDates: {
        to: moment().startOf("day").toDate(),
        from: moment().add(1, "months").startOf("day").toDate(),
      },
    };
  },

  computed: {
      timeValues(): { value: string | null; text: string }[] {
      return this.timeSlots.map((time) => {
        return {
          value: time.value,
          text: time.value,
        };
      });
    },
  },

  methods: {
    getValidationState({
      validated,
      valid = null,
      }: {
        validated: boolean;
        valid: null | boolean;
      }) {
      return validated ? valid : null;
    },
    async onEdit() {
      this.isLoading = false;
      this.modal = false;
        try{
          await adminDashboardRequests.updatePromisetopay({
            paymentId: this.idValue,
            promiseScheduleDate: this.scheduleValue,
            newPromiseDate: moment(
            `${this.openDate} ${this.selectedState}`,
            'MM-DD-YYYY h:mm A',
          ).toLocaleString(),
            promisedPayAmount: this.amount,
          });
          this.$swal({
            title: `Alert`,
            text: "Promise to pay updated",
            icon: "info",
            confirmButtonText: `Ok`,
          }).then(async (result) => {
            if(result.isConfirmed){
                this.modal = false;
                this.$router.go(0);
            }
          });
        }catch(error){
            this.$swal({
            title: `${error}`,
            text: "Promise to pay unsuccessful",
            icon: "info",
            confirmButtonText: `Ok`,
        }).then(async (result) => {
          if(result.isConfirmed){
              this.modal = false;
          }
        });
      }
    },
    async onCancel() {
      this.isLoading = false;
      this.modal = false;
    },
    async onEditPromise() {
      this.modal = true;
    },
    async showPopup(sender: any[]) {
      await this.$swal({
        title: "Error",
        text: `${JSON.stringify(sender)}`,
        icon: "error",
      });
    },
  },

  async mounted() {
    const { data } = await adminDashboardRequests.getAdmin();

   
    
    if (data && data.rows.length > 0) {
      this.adminUsers = data.rows.filter(
        (admin: any) => admin.role == 'Super Admin'
      );
      this.selectedCard = "";
    }
  },
});
