
import Vue from "vue";
import { adminDashboardRequests } from "@/api/admin-dashboard";

export default Vue.extend({
  components: {
    //Datepicker,
  },

   props: {
    dateValue: {
      required: true,
      type: String,
    },
    timeValue: {
      required: true,
      type: String,
    },
    amountValue: {
      required: true,
      type: Number,
    },
    idValue: {
      required: true,
      type: String,
    },
    stateString: {
      required: true,
      type: String,
    }
  },

  data() {
    return {
      modal: false,
      adminUsers: [] as {
        email: string;
        role: string;
        id: string;
      }[],
      selectedCard: "",
      description: "",
      isLoading: false,
    };
  },

  computed: {

  },

  methods: {
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    onCancel() {
      this.isLoading = false;
      this.modal = false;
    },

    async onAssign() {
      try{
        await adminDashboardRequests.promisetoPay({
          paymentId: this.idValue,
          isRemovingSchedule: false,
          promiseScheduleDate: this.dateValue,
          promiseScheduleTime: this.timeValue,
          promiseDescription: this.description,
          promisedPayAmount: this.amountValue,
          promiseScheduleStatus: "Added",
          customerContacted: false,
        });
        this.$swal({
          title: `Alert`,
          text: "Promise to Pay Updated",
          icon: "info",
          confirmButtonText: `Ok`,
        }).then(async (result) => {
          if(result.isConfirmed){
            this.modal = false;
            this.$router.go(0);
          }
        });
      }catch(error){
        this.$swal({
          title: `${error}`,
          text: "Promise to Pay Unsuccessful",
          icon: "info",
          confirmButtonText: `Ok`,
        }).then(async (result) => {
          if(result.isConfirmed){
            this.modal = false;
          }
        });
      }
    },
    async promiseToPay() {
        if(this.amountValue != null && this.dateValue != null && this.timeValue != null ){
            this.modal = true;
        }else{
            await this.$swal({
        title: "Alert",
        text: `Enter valid amount with date and time`,
        icon: "error",
      });
        }
    },
    async showPopup(sender: any[]) {
      await this.$swal({
        title: "Error",
        text: `${JSON.stringify(sender)}`,
        icon: "error",
      });
    },
  },

  async mounted() {

    const { data } = await adminDashboardRequests.getAdmin();
    
    if (data && data.rows.length > 0) {
      this.adminUsers = data.rows.filter(
        (admin: any) => admin.role == 'Super Admin'
      );
      this.selectedCard = "";
    }
  },
});
