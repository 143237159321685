
import Vue from "vue";
import { errorHandler } from "@/api/error-handler";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import borrowerDocumentTypes from "@/helpers/borrowerDocumentTypes";
import { getDashboardData } from "@/user-application/dashboard/api";
export default Vue.extend({
  components: {},
  props: ["UserInfoData", "DashboardData", "DocumentData"],
  async mounted() {
    try {
      const {
        address,
        annualIncome,
        approvedUpTo,
        city,
        email,
        firstName,
        lastName,
        phones,
        referenceNumber,
        applicationReference,
        requestedAmount,
        selectedOffer,
        ssn,
        state,
        zip,
        screenTrackingId,
      } = this.UserInfoData;
      this.address = address;
      this.annualIncome = annualIncome;
      this.approvedUpTo = approvedUpTo;
      this.city = city;
      this.email = email;
      this.firstName = firstName;
      this.lastName = lastName;
      this.phones = phones;
      this.referenceNumber = referenceNumber;
      this.areferenceNumber = applicationReference;
      this.requestedAmount = requestedAmount;
      this.selectedOffer = selectedOffer;
      this.ssn = ssn;
      this.state = state;
      this.zip = zip;

      const {
        driversLicensePaths,
        esignaturePath,
        paymentManagementData,
        ricPath,
        eftaPath,
        eftaConsents,
        userDocuments,
        smsPolicyPath,
        passportPath,
        otherDocPath,
        otherDocTitle,
        paystubPath,
        proofOfResidencePath,
        userConsents,
      } = this.DashboardData;

      this.driversLicensePaths = driversLicensePaths;
      this.esignaturePath = esignaturePath;
      this.paymentManagementData = paymentManagementData;
      this.ricPath = ricPath;
      this.eftaPath = eftaPath;
      this.smsPolicyPath = smsPolicyPath;
      this.documentsData = userDocuments;
      this.eftaConsents = eftaConsents;
      this.passportPath = passportPath;
      this.paystubPath = paystubPath;
      this.proofOfResidencePath = proofOfResidencePath;
      this.otherDocPath = otherDocPath;
      this.otherDocTitle = otherDocTitle;
      this.userConsents = userConsents;
    } catch (error) {
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },

  data() {
    return {
      address: null as null | string,
      annualIncome: null as null | number,
      approvedUpTo: null as null | number,
      city: null as null | string,
      driversLicensePaths: null as null | Record<string, unknown>,
      proofOfResidencePath: null as null | string,
      eftaPath: null as null | string,
      email: null as null | string,
      esignaturePath: null as null | string,
      firstName: null as null | string,
      lastName: null as null | string,
      passportPath: null as null | string,
      paymentManagementData: null as null | Record<string, any>,
      phones: [] as { phone: string; type: "home" | "mobile" | "office" }[],
      referenceNumber: null as null | string,
      areferenceNumber: null as null | string,
      requestedAmount: null as null | number,
      ricPath: null as null | string,
      selectedOffer: null as null | Record<string, any>,
      smsPolicyPath: null as null | string,
      ssn: null as null | string,
      state: null as null | string,
      zip: null as null | string,
      eftaConsents: [],
      documentsData: [],
      paystubPath: null,
      selectedDocumentType: null,
      fileFront: null as null | File,
      fileFrontBase64: null as null | string,
      fileFrontName: null as null | string,
      fileBack: null as null | File,
      fileBackBase64: null as null | string,
      fileBackName: null as null | string,
      isLoading: false,
      userDocuments: [],
      userConsents: [],
      otherDocPath: null as null | string,
      otherDoc: null as null | string,
      otherDocTitle: null as null | string,

      documentTypes: borrowerDocumentTypes,
    };
  },

  computed: {},

  methods: {
    getValidationState({ validated, valid = null }: any) {
      return validated ? valid : null;
    },
    onOtherDocTitleChange(e: any) {
      const value = e.target.value;
      this.otherDocTitle = value;
    },
    cleanState() {
      this.selectedDocumentType = null;
      this.fileFront = null;
      this.fileFrontBase64 = null;
      this.fileFrontName = null;
      this.fileBack = null;
      this.fileBackBase64 = null;
      this.fileBackName = null;

      this.$nextTick(() => {
        (this.$refs.observer as any).reset();
      });
    },
    async loadUserDocuments() {
      try {
        const { data } = await adminDashboardRequests.getUserDocuments(
          this.UserInfoData?.screenTrackingId
        );
        this.documentsData = data;
      } catch (error) {
        if (error.response.status === 404) {
          return;
        }

        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({ icon: "error", title: `${errorMessage}` });
        }
      }
    },
    async onSubmit() {
      const screenTrackingId = this.UserInfoData?.screenTrackingId;
      const userId = this.UserInfoData?.userId;

      this.isLoading = true;
      const requestBody: any = {};
      requestBody.userId = userId;
      if (this.selectedDocumentType === "passport") {
        requestBody.documentType = "passport";
        requestBody.passport = this.fileFrontBase64;
      } else if (this.selectedDocumentType === "driversLicense") {
        requestBody.documentType = "drivers license";
        requestBody.driversLicenseFront = this.fileFrontBase64;
        requestBody.driversLicenseBack = this.fileBackBase64;
      } else if (this.selectedDocumentType === "other") {
        requestBody.documentType = "otherDoc";
        requestBody.otherDocTitle = this.otherDocTitle;
        this.$set(requestBody, "otherDoc", this.fileFrontBase64);
      } else {
        requestBody.documentType = this.selectedDocumentType;
        this.$set(requestBody, requestBody.documentType, this.fileFrontBase64);
        // requestBody = this.fileFrontBase64;
      }

      try {
        await adminDashboardRequests.userUploadDocument(
          screenTrackingId,
          requestBody
        );

        this.cleanState();
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: "Document(s) uploaded successfully.",
          icon: "success",
        });

        await this.loadUserDocuments();
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isLoading = false;
      }
    },
    onFileFrontSelected(event: any) {
      const selectedImage =
        event.dataTransfer?.files[0] || event.target?.files[0];
      this.fileFrontName =
        event.dataTransfer?.files[0].name || event.target?.files[0].name;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileFrontBase64 = (event.target?.result as string).split(
          "base64,"
        )[1];
      };
      reader.readAsDataURL(selectedImage);
    },
    onFileBackSelected(event: any) {
      const selectedImage =
        event.dataTransfer?.files[0] || event.target?.files[0];
      this.fileBackName =
        event.dataTransfer?.files[0].name || event.target?.files[0].name;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileBackBase64 = (event.target?.result as string).split(
          "base64,"
        )[1];
      };
      reader.readAsDataURL(selectedImage);
    },
    async onClickGenerateStatement() {
      try {
        this.isLoading = true;
        await adminDashboardRequests.generateStatement(
          this.UserInfoData?.screenTrackingId
        );
        const { data } = await getDashboardData();
        this.userConsents = data?.userConsents;
        this.isLoading = false;
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
        this.isLoading = false;
      }
    },
  },
});
