
import Vue from "vue";

import BaseTableSection from "./base/TableSection.vue";
import { adminDashboardRequests } from "../../api/admin-dashboard";
import { TableRequestEvent } from "../../types/tables";
import LeadTemplate from "@/components/Tables/templates/manage-users/LeadTemplate.vue";

const DEFAULT_REQUEST_PARAMS = { page: 1, perPage: 25 };
export default Vue.extend({
  components: {
    BaseTableSection,
    LeadTemplate,
  },

  data() {
    return {
      defaultParams: DEFAULT_REQUEST_PARAMS,
      currentStatus: "",
      rows: [],
      totalRows: 0,
      vendors: [
        {
          value: null,
          text: "All Vendors",
        },
      ],
      selectedVendor: null as null | string,
    };
  },
  methods: {
    async fetchTable(event: TableRequestEvent) {
      this.currentStatus = event.status as string;
      const { data } = await adminDashboardRequests.getLeads({
        ...event,
      });
      const { rows, totalRows } = data;
      this.rows = rows;
      this.totalRows = totalRows;
    },
    async onVendorChange() {
      await this.fetchTable({
        ...DEFAULT_REQUEST_PARAMS,
        ...(this.selectedVendor && {
          search: `${this.selectedVendor}`,
        }),
        status: "status",
      });
    },
  },
  async mounted() {
    const { data } = await adminDashboardRequests.getVendors({
      status: "status",
      page: 1,
      perPage: 1000,
    });
    if (data) {
      const mappedVendors = data.rows.map((vendor: any) => ({
        value: vendor.name,
        text: vendor.name,
      }));
      this.vendors = [...this.vendors, ...mappedVendors];
    }
  },
});
