
import Vue from "vue";
import moment from "moment";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Datepicker from "vuejs-datepicker";
import { extend } from "vee-validate";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import { isCardExpired } from "@/admin-dashboard/helpers/";

extend("positive", (value: any) => {
  if (typeof value === "string") {
    const parsedAmount = value.replace(/[$,]/g, "");
    const isLowerThanOne = parseFloat(parsedAmount) < 5;
    if (isLowerThanOne) {
      return "Amount must be greater than $5.00";
    }
    return true;
  } else {
    if (value < 1) {
      return "Amount must be greater than $5.00";
    }
    return true;
  }
});

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 4,
});

export default Vue.extend({
  components: {
    Datepicker,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      modal: false,
      makePaymentMethodVia: null as null | string,
      previewPaymentComponent: false,
      previewNewScheduleComponent: false,
      isMonthlyPayment: true,
      isPayoff: false,
      previewResult: undefined as any,
      previewResults: undefined as any,
      userCards: [] as {
        paymentMethodToken: string;
        cardNumberLastFour: string;
      }[],
      bankAccounts: [] as any[],
      selectedCard: "",
      paymentManagement: [] as any,
      amount: "",
      newScheduleItemTransactionId: "",
      paymentType: "regular",
      paymentDate: new Date(),
      isLoading: false,
      mask: currencyMask,
      val: false,
      allPaymentMethods: [] as any,
      disabledDates: {
        to: moment()
            .startOf("day")
            .toDate(),
        from: moment()
            .add(1, "months")
            .startOf("day")
            .toDate(),
      },
      payoffAmount: 0,
      suggestedPaymentAmount: 0,
    };
  },

  computed: {
    amountToNumber(): number {
      if (typeof this.amount === "string") {
        const newAmount = parseFloat(this.amount.replace(/[$,]/g, ""));
        return newAmount;
      }

      return this.amount;
    },
    isPaymentToday(): boolean {
      return moment(this.paymentDate)
          .startOf("day")
          .isSame(moment().startOf("day"));
    },
    parsedPaymentDate(): Date {
      return moment(this.paymentDate).toDate();
    },
    paymentScheduleLeft(): any[] {
      const response = this.previewResult.preview.paymentSchedule.filter(
          (scheduleItem: any) => scheduleItem.status === "opened"
      );
      return response;
    },
  },

  methods: {
    async onNext() {
      //this.showPopup();
      //if(this.val != true){
      this.goToNewSchedule();
      //}
    },
    onSelectPaymentMethod(event: any) {
      this.makePaymentMethodVia = event.target?.value;
    },
    showAccountList(data: any) {
      let value = null;
      if (data?.paymentType === "ACH") {
        value = `${data.bankName} - ********${data.accountNumber.slice(-4)}`;
      } else {
        value = `${data.nameOnCard} - ${data.cardNumberLastFour}`;
      }
      return value;
    },
    async showPopup() {
      await this.$swal({
        title: "Error",
        text: `${this.val}`,
        icon: "error",
      });
    },
    async reloadPage() {
      try {
        const { data } = await adminDashboardRequests.getPaymentManagement(
            this.screenTrackingId
        );
        this.paymentManagement = data.response;
        const today = moment()
            .startOf("day")
            .toDate();
        if (
            moment(this.paymentManagement.nextPaymentSchedule).isBefore(
                moment(today),
                "day"
            )
        ) {
          this.val = true;
          this.disabledDates = {
            to: moment()
                .startOf("day")
                .toDate(),
            from: moment()
                .add(1, "months")
                .startOf("day")
                .toDate(),
          };
        }
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    goToPreviewPayment() {
      this.previewNewScheduleComponent = false;
      this.previewPaymentComponent = true;
    },
    async onAmountBlur(event: any) {
      const parsedAmount = (event.target.value as string).replace(/[$,]/, "");
      if (parseFloat(parsedAmount) < 1) {
        return;
      }

      const { data } = await adminDashboardRequests.getPaymentPreview(
          this.screenTrackingId,
          { amount: parsedAmount, paymentDate: this.parsedPaymentDate }
      );
      const { previewResult } = data;

      this.amount = Number(previewResult.paymentAmount).toFixed(2);

      this.newScheduleItemTransactionId =
          previewResult.preview.newScheduleItemTransactionId;
      this.previewResult = previewResult;
      this.previewResults = previewResult;
    },
    goToNewSchedule() {
      this.previewPaymentComponent = false;
      this.previewNewScheduleComponent = true;
    },
    async showPreviewPayment() {
      this.modal = true;
      // if (!this.userCards || this.userCards.length <= 0) {
      //   await this.$swal({
      //     title: "Error",
      //     text: "User didn't add a card yet",
      //     icon: "error",
      //   });
      //   this.modal = false;
      //   return;
      // }

      this.previewPaymentComponent = true;

      const { data } = await adminDashboardRequests.getPaymentPreview(
          this.screenTrackingId,
          { paymentDate: this.parsedPaymentDate }
      );
      const { previewResult } = data;
      this.suggestedPaymentAmount =
          previewResult.ledger.delinquentAmount || previewResult.paymentAmount;
      this.amount = Number(this.suggestedPaymentAmount).toFixed(2);

      this.newScheduleItemTransactionId =
          previewResult.preview.newScheduleItemTransactionId;
      this.previewResult = previewResult;
      this.previewResults = previewResult;
    },
    hidePreviewPayment: async function () {
      this.modal = false;
      this.previewPaymentComponent = false;
      this.previewNewScheduleComponent = false;
      this.isMonthlyPayment = true;
      this.isPayoff = false;
      this.paymentType = 'regular';
      const { data: { response: data } } = await adminDashboardRequests.getUserCards(
          this.screenTrackingId
      );
      const {
        data: { userBankData: userBankAccounts },
      } = await adminDashboardRequests.listUserBanks(this.screenTrackingId);

      this.bankAccounts = userBankAccounts;

      const allPaymentMethods = [...data, ...userBankAccounts];

      if (data && data.length > 0) {
        this.selectedCard = allPaymentMethods.find(
            (card: any) => card.isDefault
        )?._id;
        if (!this.selectedCard && allPaymentMethods.length) {
          this.selectedCard = allPaymentMethods[0]._id;
        }
      }
    },
    async toggleMonthlyPayment() {
      this.isPayoff = false;
      this.isMonthlyPayment = true;
      const { data } = await adminDashboardRequests.getPaymentPreview(
          this.screenTrackingId,
          { paymentDate: this.parsedPaymentDate }
      );
      const { previewResult } = data;

      this.previewResult = previewResult;
      this.suggestedPaymentAmount =
          previewResult.ledger.delinquentAmount || previewResult.paymentAmount;
      this.amount = Number(this.suggestedPaymentAmount).toFixed(2);

      this.newScheduleItemTransactionId =
          previewResult.preview.newScheduleItemTransactionId;
    },
    async togglePayoff() {
      this.isMonthlyPayment = false;
      this.isPayoff = true;
      const { data } = await adminDashboardRequests.getPaymentPreview(
          this.screenTrackingId,
          {
            amount: this.previewResult.ledger.payoff,
            paymentDate: this.parsedPaymentDate,
          }
      );
      const { previewResult } = data;

      this.previewResult = previewResult;
      this.amount = Number(previewResult.paymentAmount).toFixed(2);
      this.newScheduleItemTransactionId =
          previewResult.preview.newScheduleItemTransactionId;
    },
    setPaymentType(paymentType: string) {
      this.paymentType = paymentType;
    },
    async onDateSelected(date: Date) {
      const { data } = await adminDashboardRequests.getPaymentPreview(
          this.screenTrackingId,
          {
            amount: this.amountToNumber,
            paymentDate: moment(date)
                .startOf("day")
                .toDate(),
          }
      );
      const { previewResult } = data;

      this.previewResult = previewResult;
      this.suggestedPaymentAmount = previewResult.ledger.delinquentAmount ||
          previewResult.paymentAmount;
      this.payoffAmount = data.payoff;
      this.amount = Number(this.paymentType === 'regular' ? this.suggestedPaymentAmount : this.payoffAmount).toFixed(2);
      this.newScheduleItemTransactionId =
          previewResult.preview.newScheduleItemTransactionId;
    },
    onAmountKeyDown() {
      this.isPayoff = false;
      this.isMonthlyPayment = false;
    },
    async submitPayment() {
      try {
        this.isLoading = true;
        const paymentDate = moment(this.parsedPaymentDate).isSame(moment(), 'days')
            ? moment().toDate()
            : moment(this.parsedPaymentDate).toDate();
        await adminDashboardRequests.submitPayment(this.screenTrackingId, {
          paymentMethodToken: "",
          amount: this.amountToNumber,
          paymentDate,
          paymentVia: this.makePaymentMethodVia,
        });
        this.isLoading = false;
        await this.$swal({
          title: "Success!",
          text: this.isPaymentToday
              ? "Payment successfully submitted."
              : `Payment successfully scheduled to ${moment(this.paymentDate)
                  .startOf("day")
                  .format("MM/DD/YYYY")}`,
          icon: "success",
        });

        this.modal = false;
        this.previewPaymentComponent = false;
        this.previewNewScheduleComponent = false;
        this.$emit("reloadPage");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }

        this.isLoading = false;
        this.modal = false;
        this.previewPaymentComponent = false;
        this.previewNewScheduleComponent = false;
      }
    },
    // async submitPayment() {
    //   try {
    //     //moment(scheduleItem.date).startOf('day').isBefore(this.paymentDate) &&

    //    this.previewResult.preview.paymentSchedule.forEach(async (scheduleItem: IPaymentScheduleItem) => {

    //       const isEarlyPayment = moment(scheduleItem.date).isSameOrBefore(
    //       moment(this.paymentManagement.nextPaymentSchedule),
    //       'day',
    //     ) &&
    //     moment(scheduleItem.date).isSameOrBefore(
    //       moment(this.paymentManagement.nextPaymentSchedule),
    //       'day',
    //     );
    //       await this.$swal({
    //       title: "Success!",
    //       text: this.isPaymentToday
    //         ? "Payment successfully submitted."
    //         : `PSK Payment sucessfully scheduled to\n\n\n ${scheduleItem.date} \n]\n`,
    //       icon: "success",
    //     });
    //     });
    //     // this.isLoading = true;
    //     // await adminDashboardRequests.submitPayment(this.screenTrackingId, {
    //     //   paymentMethodToken: this.selectedCard,
    //     //   amount: this.amountToNumber,
    //     //   paymentDate: this.parsedPaymentDate,
    //     // });
    //     // this.isLoading = false;

    //     // this.modal = false;
    //     // this.previewPaymentComponent = false;
    //     // this.previewNewScheduleComponent = false;
    //     // this.$emit("reloadPage");
    //   } catch (error) {
    //     const errorMessage = await errorHandler(error, this.$router);
    //     if (errorMessage) {
    //       await this.$swal({
    //         title: "Error",
    //         text: `${errorMessage}`,
    //         icon: "error",
    //       });
    //     }

    //     this.isLoading = false;
    //     this.modal = false;
    //     this.previewPaymentComponent = false;
    //     this.previewNewScheduleComponent = false;
    //   }
    // },
  },

  async mounted() {
    const {
      data: paymentPreviewData,
    } = await adminDashboardRequests.getPaymentPreview(this.screenTrackingId, {
      paymentDate: this.parsedPaymentDate,
    });
    const { previewResult } = paymentPreviewData;
    this.previewResult = previewResult;
    this.payoffAmount = paymentPreviewData.payoff;
    this.previewResults = previewResult;
    this.suggestedPaymentAmount =
        previewResult.ledger.delinquentAmount || paymentPreviewData.paymentAmount;
    this.amount = Number(this.suggestedPaymentAmount).toFixed(2);
    this.newScheduleItemTransactionId =
        previewResult.preview.newScheduleItemTransactionId;

    const { data: { response: data } } = await adminDashboardRequests.getUserCards(
        this.screenTrackingId
    );

    const {
      data: { userBankData: userBankAccounts },
    } = await adminDashboardRequests.listUserBanks(this.screenTrackingId);
    this.bankAccounts = userBankAccounts;

    this.reloadPage();
    const allPaymentMethods = [...data, ...userBankAccounts];
    this.allPaymentMethods = allPaymentMethods;
    if (data && data.length > 0) {
      this.userCards = data.filter(
          (card: any) => !isCardExpired(card.cardExpiration)
      );
    }
    this.selectedCard = allPaymentMethods.find(
        (card: any) => card.isDefault
    )?._id;
    if (!this.selectedCard && allPaymentMethods.length) {
      this.selectedCard = allPaymentMethods[0]._id;
    }
    this.makePaymentMethodVia = this.selectedCard;
  },
});
