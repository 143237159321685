
import Vue from "vue";
import { errorHandler } from "@/api/error-handler";

import {
  enableAutopay,
  toggleSmsSubscription,
} from "@/user-application/dashboard/api";

export default Vue.extend({
  components: {},
  props: ["UserInfoData", "DashboardData", "tabIndex"],
  async created() {
    try {
      const {
        address,
        annualIncome,
        approvedUpTo,
        city,
        email,
        firstName,
        lastName,
        phones,
        referenceNumber,
        applicationReference,
        requestedAmount,
        selectedOffer,
        ssn,
        state,
        zip,
      } = this.UserInfoData;
      this.address = address;
      this.annualIncome = annualIncome;
      this.approvedUpTo = approvedUpTo;
      this.city = city;
      this.email = email;
      this.firstName = firstName;
      this.lastName = lastName;
      this.phones = phones;
      this.referenceNumber = referenceNumber;
      this.areferenceNumber = applicationReference;
      this.requestedAmount = requestedAmount;
      this.selectedOffer = selectedOffer;
      this.ssn = ssn;
      this.state = state;
      this.zip = zip;

      const {
        driversLicensePaths,
        esignaturePath,
        passportPath,
        paymentManagementData,
        ricPath,
        eftaPath,
        smsPolicyPath,
        userAccountsData,
        user,
      } = this.DashboardData;
      this.driversLicensePaths = driversLicensePaths;
      this.esignaturePath = esignaturePath;
      this.passportPath = passportPath;
      this.paymentManagementData = paymentManagementData;
      this.autopayStatus = paymentManagementData.canRunAutomaticPayment;
      this.autopayCheck = this.autopayStatus;
      this.ricPath = ricPath;
      this.eftaPath = eftaPath;
      this.smsPolicyPath = smsPolicyPath;
      this.cards = userAccountsData;
      this.smsSubscribed = !user.smsUnsubscribed;
    } catch (error) {
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({ icon: "error", title: `${errorMessage}` });
      }
    }
  },

  data() {
    return {
      cards: [],
      address: null as null | string,
      annualIncome: null as null | number,
      approvedUpTo: null as null | number,
      city: null as null | string,
      driversLicensePaths: null as null | Record<string, unknown>,
      eftaPath: null as null | string,
      email: null as null | string,
      esignaturePath: null as null | string,
      firstName: null as null | string,
      lastName: null as null | string,
      passportPath: null as null | string,
      paymentManagementData: null as null | Record<string, any>,
      phones: [] as { phone: string; type: "home" | "mobile" | "office" }[],
      referenceNumber: null as null | string,
      areferenceNumber: null as null | string,
      requestedAmount: null as null | number,
      ricPath: null as null | string,
      selectedOffer: null as null | Record<string, any>,
      smsPolicyPath: null as null | string,
      ssn: null as null | string,
      state: null as null | string,
      zip: null as null | string,
      autopayStatus: false,
      autopayCheck: false,
      smsSubscribed: true,
      ENV: process.env,
    };
  },

  computed: {},

  methods: {
    async turnOnAutopay() {
      try {
        if (this.cards.length > 0) {
          if (this.paymentManagementData) {
            const requestData = {
              paymentManagementId: this.paymentManagementData._id,
            };
            await enableAutopay(requestData);
            this.autopayStatus = true;
            await this.$swal({
              title: "Success!",
              text: "AutoPay is enabled!",
              icon: "success",
            });
            this.$emit("reloadPage");
          }
        } else {
          this.$emit("updateTabIndex", 3);
          await this.$swal({
            title: "Alert",
            text: "Please add a payment method first",
            icon: "info",
          });
        }
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async switchSmsUnsubscribed() {
      try {
        await toggleSmsSubscription(this.UserInfoData.userId);
        await this.$swal({
          title: "Success!",
          text: `SMS subscription is ${
            this.smsSubscribed ? "enabled" : "disabled"
          }!`,
          icon: "success",
        });
        this.$emit("reloadPage");
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
  },
});
