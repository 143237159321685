
import Vue from "vue";

import LogActivityTabTable from "@/admin-dashboard/audit-log/components/LogActivityTabTable.vue";

export default Vue.extend({
  components: {
    LogActivityTabTable,
  },

  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },
});
