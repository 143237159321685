
import Vue from "vue";

import { adminDashboardRequests } from "@/api/admin-dashboard";
import { errorHandler } from "@/api/error-handler";
import { getAdminRoles } from "@/admin-dashboard/helpers";
import { getUserData } from "@/admin-dashboard/authentication/helpers";

export default Vue.extend({
  props: {
    vendorId: {
      required: false,
      type: String,
    },
    currentName: {
      required: false,
      type: String,
    },
    currentApiKey: {
      required: false,
      type: String,
    },
    currentAutoImport: {
      required: false,
      type: Boolean,
    },
  },

  data() {
    return {
      isSubmitting: false,
      modal: false,
      name: null as null | string,
      apiKey: null as null | string,
      selectedAutoImport: true,
      autoImports: [
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],
    };
  },
  computed: {},
  methods: {
    getValidationState({
      dirty,
      validated,
      valid = null,
    }: {
      dirty: boolean;
      validated: boolean;
      valid: null | boolean;
    }) {
      return dirty || validated ? valid : null;
    },
    async saveVendor() {
      try {
        const requestParams = {
          name: this.name || "",
          apiKey: this.apiKey || "",
          autoImport: this.selectedAutoImport ?? true,
        };
        await adminDashboardRequests.addVendor(requestParams);

        await this.$swal({
          title: "Success!",
          text: "Vendor successfully added.",
          icon: "success",
        });
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async updateVendor(vendorId: string) {
      try {
        const requestParams = {
          name: this.name || "",
          apiKey: this.apiKey || "",
          autoImport: this.selectedAutoImport,
        };
        await adminDashboardRequests.updateVendor(vendorId, requestParams);

        await this.$swal({
          title: "Success!",
          text: "Vendor data successfully edited.",
          icon: "success",
        });
      } catch (error) {
        const errorMessage = await errorHandler(error, this.$router);
        if (errorMessage) {
          await this.$swal({
            title: "Error",
            text: `${errorMessage}`,
            icon: "error",
          });
        }
      }
    },
    async onSubmit() {
      if (this.vendorId) {
        await this.updateVendor(this.vendorId);
      } else {
        await this.saveVendor();
      }
      this.$router.push("/admin/manage-vendors");
    },
  },
  async mounted() {
    try {
      // fill fields if updating
      if (this.vendorId) {
        this.name = this.currentName;
        this.apiKey = this.currentApiKey;
        this.selectedAutoImport = this.currentAutoImport;
      }
    } catch (error) {
      const errorMessage = await errorHandler(error, this.$router);
      if (errorMessage) {
        await this.$swal({
          title: "Error",
          text: `${errorMessage}`,
          icon: "error",
        });
      }
    }
  },
});
