var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"steps-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('div',{staticClass:"steps-row"},[_c('div',{staticClass:"single-step",class:[
              { 'step-active': _vm.step === 1 },
              { stepNonActive: _vm.step > 1 },
            ]},[(_vm.step === 1)?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1):_vm._e()]),_c('div',{staticClass:"single-step",class:[
              { 'step-active': _vm.step === 2 },
              { stepNonActive: _vm.step > 2 },
            ]},[(_vm.step === 2)?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1):_vm._e()]),_c('div',{staticClass:"single-step",class:[
              { 'step-active': _vm.step === 3 },
              { stepNonActive: _vm.step > 3 },
            ]},[(_vm.step === 3)?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1):_vm._e()]),_c('div',{staticClass:"single-step",class:[
              { 'step-active': _vm.step === 4 },
              { stepNonActive: _vm.step > 4 },
            ]},[(_vm.step === 4)?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1):_vm._e()]),_c('div',{staticClass:"single-step",class:[
              { 'step-active': _vm.step === 5 },
              { stepNonActive: _vm.step > 5 },
            ]},[(_vm.step === 5)?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1):_vm._e()]),_c('div',{staticClass:"single-step",class:[
              { 'step-active': _vm.step === 6 },
              { stepNonActive: _vm.step > 6 },
            ]},[(_vm.step === 6)?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }