
import Vue from "vue";
import { mapMutations } from "vuex";
import moment from "moment";

import states from "@/helpers/states";
import Layout from "@/user-application/layout/pages/Layout.vue";
import showErrorToast from "@/helpers/errorToast";
import { getIp } from "@/user-application/helpers";
import { adminDashboardRequests } from "@/api/admin-dashboard";
import { getApplicationData } from "@/user-application/application/api";
import {
  addCard,
  makeDownPayment,
  saveEFTA,
  setupAutoPayLater,
  validateCard,
} from "@/user-application/repayment/api";
import IOffer from "@/user-application/offers/types/IOffer";
import { extend } from "vee-validate";
import CardValidator from "card-validator";

const AMEX_MASK = '#### ###### #####'
const VISA_MASK = '#### #### #### ####'
extend("notExpired", (value: string) => {
  const today = moment();
  const thisMonth = today.month() + 1;
  const thisYear = today.year();
  const splitted = value.split("/");
  const month = +splitted[0];
  const year = +splitted[1] + 2000; //20xx

  if (
    month > 12 ||
    year < thisYear ||
    (year === thisYear && month < thisMonth)
  ) {
    return "Expired card";
  }

  return true;
});

export default Vue.extend({
  components: {
    Layout,
  },
  props: {
    screenTrackingId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      agreement: false,
      applicationReference: null as null | string,
      apply: false,
      autoPayMethod: false,
      userData: null as null | Record<string, any>,
      checked: false,
      cardCode: null as null | string,
      cardHolder: null as null | string,
      cardNumber: null as null | string,
      city: null as null | string,
      expirationDate: null as null | string,
      firstName: null as null | string,
      ip: null as null | string,
      isEFTAChecked: false,
      isSubmitting: false,
      lastName: null as null | string,
      loanStartDate: moment()
        .add(1, "month")
        .startOf("day")
        .format("MM/DD/YYYY"),
      paymentMethodToken: null as null | string,
      phones: null as null | { phone: string; type: string }[],
      review: false,
      ricSignature: null as null | string,
      selectedOffer: null as null | IOffer,
      selectedState: null as null | string,
      showModalElectr: false,
      state: null as null | string,
      states: states as { state: string; stateCode: null | string }[],
      street: null as null | string,
      userFirstName: null as null | string,
      userLastName: null as null | string,
      userCity: null as null | string,
      userStreet: null as null | string,
      userState: null as null | string,
      userzipCode: null as null | string,
      zipCode: null as null | string,
    };
  },

  computed: {
    isAmex(): boolean {
      if (this.cardNumber) {
        return (this.cardNumber.substr(0, 2) === '37' || this.cardNumber.substr(0, 2) === '34')
      }
      return false
    },
    amexOrVisa(): string {
      return this.isAmex ? AMEX_MASK : VISA_MASK
    },
    amexOrVisaSecurityCode(): string {
      return this.isAmex ? "####" : "###"
    },
    amexOrVisaLength(): number {
      return this.isAmex ? 17 : 19
    },
    amexOrVisaSecLength(): number {
      return this.isAmex ? 4 : 3
    },
    parsedStates(): { value: string | null; text: string }[] {
      return this.states.map((state) => {
        return {
          value: state.stateCode,
          text: state.state,
        };
      });
    },
    todayDate() {
      return moment().format("MM/DD/YYYY");
    },
    dateNextMonth() {
      return moment()
        .add(1, "months")
        .format("L");
    },
    cardIssuer() {
      if (this.cardNumber) {
        const result = CardValidator.number(this.cardNumber);
        return result.card?.niceType;
      }
      return null;
    },
  },


  methods: {
    ...mapMutations(["setStep", "setIsLoading"]),
    isLetter(event: KeyboardEvent) {
      const char = event.key;
      if (!/^[a-zA-Z\s]+$/.test(char)) {
        event.preventDefault();
      }
    },

    async showHomeAddress() {
      const [expMonth, expYear] = this.expirationDate?.split("/") ?? "";
      const cardNumber = this.cardNumber?.split(" ").join("");


      if (this.checked == true) {
        this.firstName = this.userFirstName ? "   " + this.userFirstName : null;
        this.lastName = this.userLastName ? " " + this.userLastName : null;
        this.selectedState = this.userState ? this.userState : null;
        this.zipCode = this.userzipCode ? " " + this.userzipCode : null;
        this.city = this.userCity ? " " + this.userCity : null;
        this.street = this.userStreet ? " " + this.userStreet : null;
        const fName = this.$refs.firstName as any;
        const lName = this.$refs.lastName as any;
        const sName = this.$refs.street as any;
        const cName = this.$refs.city as any;
        const zName = this.$refs.zipCode as any;
        fName.focus();
        lName.focus();
        sName.focus();
        cName.focus();
        zName.focus();
        fName.focus();
      }
      else {
        this.firstName = null;
        this.lastName = null;
        this.selectedState = null;
        this.zipCode = null;
        this.city = null;
        this.street = null;
      }
    },
    getValidationState({
      validated,
      valid = null,
    }: {
      validated: boolean;
      valid: null | boolean;
    }) {
      return validated ? valid : null;
    },
    async onSetupAutoPayLater() {
      this.isSubmitting = true;
      this.setIsLoading(true);

      try {
        await setupAutoPayLater();
        this.isSubmitting = false;
        this.setIsLoading(false);
        await this.$router.push("/document-upload");
      } catch (error) {
        this.isSubmitting = false;
        this.setIsLoading(false);

        let errorMessage = "";
        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
    editCard() {
      this.apply = false;
      this.isSubmitting = false;
    },
    async saveEFTAAgreement() {
      const [expMonth, expYear] = this.expirationDate?.split("/") ?? "";
      const cardNumber = this.cardNumber?.split(" ").join("");
      const requestBody = {
        userId: "",
        applicationReference: this.applicationReference || "",
        cardCode: this.cardCode || "",
        cardHolder: this.cardHolder || "",
        cardIssuer: this.cardIssuer || "",
        cardNumber: cardNumber || "",
        city: this.city || "",
        expirationMonth: expMonth || "",
        expirationYear: expYear || "",
        firstName: this.userFirstName || "",
        lastName: this.userLastName || "",
        phoneNumber: (this.phones && this.phones[0].phone) || "",
        selectedOffer: this.selectedOffer || {},
        selectedState: this.selectedState || "",
        signature: this.ricSignature || "",
        street: this.street || "",
        zipCode: this.zipCode || "",
      };

      // await saveEFTA(requestBody);
    },
    async saveCard() {
      this.isSubmitting = true;
      const [expMonth, expYear] = this.expirationDate?.split("/") ?? "";
      const cardNumber = this.cardNumber?.split(" ").join("");
      this.setIsLoading(true);

      const requestBody = {
        billingAddress1: this.street || "",
        billingCity: this.city || "",
        billingFirstName: this.firstName || "",
        billingLastName: this.lastName || "",
        billingState: this.selectedState || "",
        billingZip: this.zipCode || "",
        cardCode: this.cardCode || "",
        cardNumber: cardNumber || "",
        expMonth,
        expYear,
      };

      const { data: validationResponse } = await validateCard(requestBody);
      if (!validationResponse.CardValid) {
        this.isSubmitting = false;
        this.setIsLoading(false);

        showErrorToast(this, "error", "Invalid card");
        this.apply = false;
      } else {
        const { data } = await addCard(requestBody);
        this.paymentMethodToken = data.paymentMethodToken;
      }
    },
    async submitReview() {
      this.isSubmitting = true;
      this.setIsLoading(true);

      try {
        await Promise.all([this.saveEFTAAgreement(), this.saveCard()]);

        if (this.selectedOffer && this.selectedOffer.downPayment > 0) {
          const downPaymentRequestBody = {
            amount: this.selectedOffer.downPayment,
            paymentMethodToken: this.paymentMethodToken || "",
          };
          await makeDownPayment(downPaymentRequestBody);

          this.review = true;
          this.isSubmitting = false;
          this.setIsLoading(false);
        } else {
          this.isSubmitting = false;
          this.setIsLoading(false);
          await this.$router.push({ name: "document-upload" });
        }
      } catch (error) {
        this.isSubmitting = false;
        this.setIsLoading(false);

        let errorMessage = "";
        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
    async finishReview() {
      await this.$router.push({ name: "document-upload" });
    },
    async onSubmit() {
      this.apply = true;
    },
  },

  async created() {
    try {
      this.setStep(5);
      const { data } = await getApplicationData();
      const {
        applicationReference,
        firstName,
        isCompleted,
        lastName,
        lastStep,
        phones,
        address,
        city,
        state,
        zip,
        ricSignature,
        selectedOffer,
      } = data;
      if (isCompleted) {
        await this.$router.push({ name: "userDashboard" });
      } else if (lastStep !== "repayment") {
        await this.$router.push({ name: lastStep });
      } else {
        this.applicationReference = applicationReference;
        this.phones = phones;
        this.ricSignature = ricSignature;
        this.selectedOffer = selectedOffer;
        this.userFirstName = firstName;
        this.userLastName = lastName;
        this.userStreet = address;
        this.userState = state;
        this.userCity = city;
        this.userzipCode = zip;
        const { data } = await getIp();
        this.ip = data.ip;
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await this.$router.push({ name: "userLogin" });
      } else {
        showErrorToast(this, "error", error.message);
      }
    }
  },
});
