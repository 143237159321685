
import Vue from "vue";
import { mapMutations } from "vuex";

import SignaturePad from "signature_pad";
import CA from "../components/CA.vue";
import Layout from "@/user-application/layout/pages/Layout.vue";
import { getApplicationData } from "@/user-application/application/api";
import showErrorToast from "@/helpers/errorToast";
import { getOfferData, saveSignature, finalizeApplication } from "../api";

export default Vue.extend({
  components: {
    CA,
    Layout,
  },

  data() {
    return {
      screenTracking: null as null | Record<string, unknown>,
      user: null as null | Record<string, unknown>,
      selectedOffer: null as null | Record<string, unknown>,
      eftaAgreement: false,
      errors: {} as Record<string, unknown>,
      isSubmitting: false,
      renderComponent: false,
      ricSignature: false,
      showEfta: false,
      signaturePad: null as any,
    };
  },

  methods: {
    ...mapMutations([
      "setStep",
      "setProvider",
      "setPaymentScheduleInfo",
      "setSelectedOffer",
      "setUserData",
      "setDate",
      "setScreenTracking",
      "setSignature",
      "setIsLoading",
    ]),
    clearSignature() {
      this.errors.ricSignature = "";
      if (this.signaturePad) {
        this.signaturePad.clear();
      }
    },
    displayEfta() {
      this.showEfta = true;
      document.body.style.overflow = "hidden";
    },
    closeEfta() {
      this.showEfta = false;
      document.body.style.overflow = "auto";
    },
    async finalize() {
      try {
        this.isSubmitting = true;
        this.setIsLoading(true);
        await finalizeApplication();
        this.setIsLoading(false);

        await this.$router.push({
          name: "repayment",
        });
      } catch (error) {
        this.isSubmitting = false;
        this.setIsLoading(false);
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
    getCanvas(id: string) {
      const wrapper = document.getElementById(id);
      if (!wrapper) {
        this.errors.ricSignature = "Could not create signature pad";
        return;
      }
      return wrapper.querySelector("canvas");
    },
    handleResize() {
      if (!this.ricSignature) {
        const canvas = this.getCanvas("ric-signature-pad");
        if (!canvas || !this.signaturePad) {
          this.errors.ricSignature =
            "Could not handle resize for signature pad.";
          return;
        }

        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d")?.scale(ratio, ratio);

        this.clearSignature();
      }
    },
    initSignaturePad() {
      if (!this.ricSignature) {
        const canvas = this.getCanvas("ric-signature-pad");
        if (!canvas) {
          this.errors.ricSignature = "Could not create signature pad.";
          return;
        }
        this.signaturePad = new SignaturePad(canvas, {});
      }
    },
    print() {
      (this.$refs.CA as any).printSummary();
    },
    async saveSignature() {
      this.isSubmitting = true;

      const signature: string = this.signaturePad.toDataURL("image/png");
      if (this.signaturePad.isEmpty() || !signature) {
        this.errors.ricSignature =
          "You must sign the Retail Installment Contract to continue your application.";
        this.isSubmitting = false;

        return;
      }

      try {
        this.setIsLoading(true);
        const imgBase64 = signature.split("base64,")[1];
        await saveSignature(imgBase64);

        this.setSignature(signature);
        this.ricSignature = true;
        this.isSubmitting = false;
        this.setIsLoading(false);
      } catch (error) {
        this.isSubmitting = false;
        this.setIsLoading(false);
        let errorMessage = "";

        if (error.response?.data?.error) {
          errorMessage = error.response.data.error;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(this, "error", errorMessage);
      }
    },
  },
  async created() {
    try {
      const { data } = await getApplicationData();
      const { isCompleted, lastStep, selectedOffer, ricSignature } = data;

      if (isCompleted) {
        await this.$router.push({ name: "userDashboard" });
      } else if (
        !(
          (lastStep === "offers" && selectedOffer) ||
          lastStep === "sign-contract"
        )
      ) {
        await this.$router.push({ name: lastStep });
      } else {
        this.setStep(4);
        const { data } = await getOfferData();
        const {
          date,
          paymentScheduleInfo,
          provider,
          screenTracking,
          userData,
          selectedOffer: selectedOfferForContract,
        } = data;
        this.user = userData;
        this.screenTracking = screenTracking;
        this.selectedOffer = selectedOffer;

        this.setProvider(provider);
        this.setPaymentScheduleInfo(paymentScheduleInfo);
        this.setSelectedOffer(selectedOfferForContract);
        this.setUserData(userData);
        this.setScreenTracking(screenTracking);
        this.setDate(date);
        if (ricSignature) {
          this.setSignature(ricSignature);
          this.ricSignature = true;
        }

        this.$nextTick(() => {
          // Add the component back in
          this.renderComponent = true;
          this.initSignaturePad();
          this.handleResize();
          window.onresize = () => {
            this.handleResize();
          };
        });
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await this.$router.push({ name: "userLogin" });
      } else {
        showErrorToast(this, "error", error.message);
      }
    }
  },
});
